import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  updateFormField,
  loginPageUpdateAudioState,
  triggerRequest,
  loginPageToggleModal,
  updateSelectStoryPreferenceState,
  resetSelectStoryPreferenceState,
  loginPageUpdateRequestState,
  resetLoginPage,
} from '../../actions/loginPage';

import {
  toggleBodyScroll,
  recordSignupFunnelEventGA,
  initializeDashboardGA,
  pageViewGA,
  getPrefilledEmailFromQueryString,
} from '../../utils'

import SignupPage from '../../components/dashboard/SignupPage';
import {
  GA_LABEL_LOADED_SIGNUP_PAGE,
} from '../../constants/gaEventLabels'

import {
  REQ_DASHBOARD_FETCH_APP_CONFIG,
} from '../../constants/requestTypes'

import {
  LOGIN_PAGE_MODAL_AUTHORIZE_BETA_TESTING,
  MODAL_SELECT_STORY_PREFERENCES,
} from '../../constants/modalTypes'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_APP_PLATFORM,
  COMMON_CURRENT_APP_VERSION,
} from '../../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
  FORM_FIELD_EMAIL_ADDRESS,
} from '../../constants/formFields'

class SignupPageContainer extends Component {
  constructor(props){
    super(props)
    const prefilledEmailAddress = decodeURIComponent(getPrefilledEmailFromQueryString())
    this.props.dispatch(updateFormField(FORM_FIELD_EMAIL_ADDRESS, prefilledEmailAddress))
    this.state = {
      hasPrefilledEmail: (!!prefilledEmailAddress)
    }
  }
  async componentWillMount() {
    await initializeDashboardGA(true);
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_LOADED_SIGNUP_PAGE)
  }

  componentWillUnmount() {
    this.props.dispatch(resetLoginPage());
  }

  render() {
    return (
      <SignupPage
        // State
        hasPrefilledEmail={this.state.hasPrefilledEmail}
        formFields={this.props.formFields}
        requestResults={this.props.requestResults}
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        countryCode={this.props.countryCode}
        isIOSNative={this.props.isIOSNative}
        isAndroidNative={this.props.isAndroidNative}
        currentAppVersion={this.props.currentAppVersion}
        // Actions
        onUpdateFormField={(key, value) => this.props.dispatch(updateFormField(key, value))}
        onUpdateRequestState={(req_key, req_status) => this.props.dispatch(loginPageUpdateRequestState(req_key, req_status))}
        onTriggerRequest={requestType => this.props.dispatch(triggerRequest(requestType))}
      />
    );
  }
}

SignupPageContainer.propTypes = {
  formFields: PropTypes.object.isRequired,
  requestResults: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isNative: PropTypes.bool.isRequired,
  isIOSNative: PropTypes.bool.isRequired,
  isAndroidNative: PropTypes.bool.isRequired,
  currentAppVersion: PropTypes.string.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.loginPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isNative: state.common[COMMON_IS_NATIVE],
  isIOSNative: state.common[COMMON_APP_PLATFORM] === "ios",
  isAndroidNative: state.common[COMMON_APP_PLATFORM] === "android",
  currentAppVersion: state.common[COMMON_CURRENT_APP_VERSION],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
  countryCode: state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.country_code !== "-" ? state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.country_code : "US",
});

export default connect(mapStateToProps)(SignupPageContainer);
