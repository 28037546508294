export const getTextColorForDysregulationQuizScore = (score) => {
  if (score < 50) {
    return "#FF7888"
  } else if (score < 67) {
    return "#FDBE50"
  } else if (score < 80) {
    return "#8AC98C"
  } else {
    return "#32CD32"
  }
}

export const getMetaForRecoveryScore = (score, timeOfDay, firstName) => {
  if ((!(score)) || (score < 0)) {
    return {name: "Creating Recovery Baseline...", color: "#FFFFFF", description: `NEUROFIT is creating ${firstName}'s nervous system baseline. They can measure their HRV again tomorrow for personalized insights.`}
  } else if (score < 50) {
    return {name: "Depleted", color: "#FFC8E3", description: `${firstName}'s nervous system appears to be fatigued at the moment. Rest ${timeOfDay === "evening" ? "tonight" : "today"} is highly recommended.`}
  } else if (score < 67) {
    return {name: "Recovering", color: "#FAE4C7", description: `${firstName}'s nervous system appears to be recovering at the moment. Rest this ${timeOfDay} is recommended.`}
  } else if (score < 80) {
    return {name: "Balanced", color: "#CFF2D2", description: `${firstName}'s nervous system seems refreshed this ${timeOfDay}.`}
  } else if (score <= 100) {
    return {name: "Restored", color: "#BBEDBF", description: `${firstName}'s nervous system seems highly refreshed this ${timeOfDay}.`}
  }
}

export const getMetaForRecoveryScoreHomePage = (score, dataTimeOfDay, timeOfDay, firstName) => {
  if ((!(score)) || (score < 0)) {
    return {name: "Creating Recovery Baseline...", color: "#FFFFFF", description: `NEUROFIT is creating ${firstName}'s nervous system baseline. They can measure their HRV again tomorrow for personalized insights.`}
  } else if (score < 50) {
    return {name: "Depleted", color: "#FFC8E3", description: `${firstName}'s nervous system appears to be fatigued as of this ${dataTimeOfDay}. Rest ${timeOfDay === "evening" ? "tonight" : "today"} is highly recommended.`}
  } else if (score < 67) {
    return {name: "Recovering", color: "#FAE4C7", description: `${firstName}'s nervous system appears to be recovering as of this ${dataTimeOfDay}. Rest ${timeOfDay === "evening" ? "tonight" : "today"} is recommended.`}
  } else if (score < 80) {
    return {name: "Balanced", color: "#CFF2D2", description: `${firstName}'s nervous system seems refreshed as of this ${dataTimeOfDay}.`}
  } else if (score <= 100) {
    return {name: "Restored", color: "#BBEDBF", description: `${firstName}'s nervous system seems highly refreshed as of this ${dataTimeOfDay}.`}
  }
}

export const getInsightForRecoveryScore = (score, timeOfDay, firstName) => {
  if (!(score) || score < 0) {
    return `NEUROFIT is creating ${firstName}'s nervous system baseline. They can measure their HRV again tomorrow for personalized insights.`
  }
  if (score < 50) {
    return `${firstName}'s nervous system appears to be fatigued at the moment. Rest ${timeOfDay === "evening" ? "tonight" : "today"} is highly recommended.`
  } else if (score < 70) {
    return `${firstName}'s nervous system appears to be recovering at the moment. Rest this ${timeOfDay} is recommended.`
  } else if (score < 85) {
    return `${firstName}'s nervous system seems refreshed this ${timeOfDay}.`
  } else if (score <= 100) {
    return `${firstName}'s nervous system seems highly refreshed this ${timeOfDay}.`
  }
}

export const getMetaForHeartRateVariability = (value, isBaselineAvailable, valueBaseline, valueBaselineStdDev) => {
  if (!isBaselineAvailable) {
    return {name: "Balanced", color: "#000000", description: "Creating HRV baseline - insights will be available after tomorrow's measurement."}
  } else {
    const baselineDeviation = (value - valueBaseline)
    if (Math.abs(baselineDeviation) < (1.5 * valueBaselineStdDev)) {
      return {name: "Balanced", color: "#000000", description: "HRV appears balanced - this is a marker of nervous system readiness."}
    } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
      return {name: "Increased", color: "#93E29A", description: "HRV is currently above the baseline - this is a sign of nervous system resilience."}
    } else {
      return {name: "Reduced", color: "#FF7CBC", description: "HRV is lower than normal. This can be a sign of nervous system fatigue."}
    }
  }
}

export const getMetaForHeartRate = (value, isBaselineAvailable, valueBaseline, valueBaselineStdDev) => {
  if (!isBaselineAvailable) {
    if (value < 40) {
      return {name: "Reduced", color: "#FF7CBC", description: "Heart rate appears to be reduced - this can be a sign of nervous system fatigue."}
    } else if (value < 75) {
      return {name: "Balanced", color: "#000000", description: "Heart rate appears balanced - this is a marker of nervous system readiness."}
    } else if (value < 85) {
      return {name: "Slightly Elevated", color: "#000000", description: "Heart rate appears to be slightly elevated - this can be an early sign of nervous system fatigue."}
    } else {
      return {name: "Elevated", color: "#FF7CBC", description: "Heart rate appears to be elevated - this is often a marker of nervous system fatigue."}
    }
  } else {
    const baselineDeviation = (value - valueBaseline)
    if (baselineDeviation > (2.5 * valueBaselineStdDev)) {
      return {name: "Elevated", color: "#FF7CBC", description: "Heart rate appears to be elevated - this is often a marker of nervous system fatigue."}
    } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
      return {name: "Slightly Elevated", color: "#000000", description: "Heart rate appears to be slightly elevated - this can be an early sign of nervous system fatigue."}
    } else if (baselineDeviation < (-2.5 * valueBaselineStdDev)) {
      return {name: "Reduced", color: "#FF7CBC", description: "Heart rate appears to be reduced - this can be a sign of nervous system fatigue."}
    } else {
      return {name: "Balanced", color: "#000000", description: "Heart rate appears balanced - this is a marker of nervous system readiness."}
    }
  }
}

export const getMetaForRespirationRate = (value, isBaselineAvailable, valueBaseline, valueBaselineStdDev) => {
  if (!isBaselineAvailable) {
    if (value < 9) {
      return {name: "Reduced", color: "#FF7CBC", description: "Estimated respiration rate appears to be reduced - this can be a sign of nervous system fatigue."}
    } else if (value < 18) {
      return {name: "Balanced", color: "#FFFFFF", description: "Estimated respiration rate appears balanced - this is a marker of nervous system readiness."}
    } else if (value < 20) {
      return {name: "Slightly Elevated", color: "#000000", description: "Estimated respiration rate appears to be slightly elevated - this can be an early sign of nervous system fatigue."}
    } else {
      return {name: "Elevated", color: "#FF7CBC", description: "Estimated respiration rate appears to be elevated - this is often a marker of nervous system fatigue."}
    }
  } else {
    const baselineDeviation = (value - valueBaseline)
    if (baselineDeviation > (2.5 * valueBaselineStdDev)) {
      return {name: "Elevated", color: "#FF7CBC", description: "Estimated respiration rate appears to be elevated - this is often a marker of nervous system fatigue."}
    } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
      return {name: "Slightly Elevated", color: "#000000", description: "Estimated respiration rate appears to be slightly elevated - this can be an early sign of nervous system fatigue."}
    } else if (baselineDeviation < (-2.5 * valueBaselineStdDev)) {
      return {name: "Reduced", color: "#FF7CBC", description: "Estimated respiration rate appears to be reduced - this can be a sign of nervous system fatigue."}
    } else {
      return {name: "Balanced", color: "#000000", description: "Estimated respiration rate appears balanced - this is a marker of nervous system readiness."}
    }
  }
}

export const getInsightForOverallAverageStatePercentage = (data, firstName) => {
  const positivePercentages = (data.parasympathetic || 0.0) + (data.stillness || 0.0) + (data.play || 0.0)
  const stillPercentages = (data.stillness || 0.0) + (data.dorsal_vagal || 0.0)
  const energizedPercentages = (data.play || 0.0) + (data.sympathetic || 0.0)
  let result = ""
  const descriptor = `${firstName}'s nervous system`
  if (positivePercentages >= 0.875) {
    return `${descriptor} looks highly balanced as of late!`
  } else if (positivePercentages >= 0.75) {
    result += `${descriptor} looks balanced as of late.`
  } else if (positivePercentages >= 0.625) {
    result += `${descriptor} looks fairly balanced as of late.`
    if (stillPercentages >= 0.25) {
      result += " For greater balance, ensure exercise and movement are a part of their daily routine."
    } else if (energizedPercentages >= 0.25) {
      result += " For greater balance, ensure stillness and reflection are a part of their daily routine."
    }
  } else if (positivePercentages >= 0.5) {
    result += `${descriptor} could use some balancing.`
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " Make sure they add more exercise and stillness to their routine."
    } else if (energizedPercentages >= 0.25) {
      result += " Make sure they add more stillness and reflection to their routine."
    } else if (stillPercentages >= 0.25) {
      result += " Make sure they add more exercise and movement to their routine."
    }
  } else {
    result += `${descriptor} is in need of balancing.`
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += ` Ensure they add more stillness and activity to their routine.`
    } else if (energizedPercentages >= 0.25) {
      result += ` Ensure they add more stillness and reflection to their routine.`
    } else if (stillPercentages >= 0.25) {
      result += ` Ensure they add more activity and movement to their routine.`
    }
  }

  return result
}

export const getInsightForLifeAspectAverageStatePercentage = (data, firstName) => {
  const positivePercentages = (data.parasympathetic || 0.0) + (data.stillness || 0.0) + (data.play || 0.0)
  const stillPercentages = (data.stillness || 0.0) + (data.dorsal_vagal || 0.0)
  const energizedPercentages = (data.play || 0.0) + (data.sympathetic || 0.0)
  let result = ""
  const descriptor = "This life aspect"
  if (positivePercentages >= 0.875) {
    return `${descriptor} looks highly balanced as of late!`
  } else if (positivePercentages >= 0.75) {
    result += `${descriptor} looks balanced as of late.`
  } else if (positivePercentages >= 0.625) {
    result += `${descriptor} looks fairly balanced as of late.`
    if (stillPercentages >= 0.25) {
      result += " To shift it towards greater balance, they can focus on this during CLEAR sessions and BALANCE Training."
    } else if (energizedPercentages >= 0.25) {
      result += " To shift it towards greater balance, they can focus on this during CLEAR sessions and BALANCE Training."
    }
  } else if (positivePercentages >= 0.5) {
    result += `${descriptor} could use some balancing for ${firstName}.`
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " They should take some time to focus on this during CLEAR sessions and BALANCE Training."
    } else if (energizedPercentages >= 0.25) {
      result += " They should take some time to focus on this during CLEAR sessions and BALANCE Training."
    } else if (stillPercentages >= 0.25) {
      result += " They should take some time to focus on this during CLEAR sessions and BALANCE Training."
    }
  } else {
    result += `${descriptor} is in need of balancing for ${firstName}.`
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += ` They should take some time to focus on this during CLEAR sessions and BALANCE Training.`
    } else if (energizedPercentages >= 0.25) {
      result += ` They should take some time to focus on this during CLEAR sessions and BALANCE Training.`
    } else if (stillPercentages >= 0.25) {
      result += ` They should take some time to focus on this during CLEAR sessions and BALANCE Training.`
    }
  }

  return result
}

export const MIN_HABIT_SOLID_THRESHOLD = 0.8
export const MIN_HABIT_SATISFACTORY_THRESHOLD = 0.65
export const MIN_HABIT_ACCEPTABLE_THRESHOLD = 0.5

export const MIN_SELF_CARE_HABIT_SOLID_THRESHOLD = 8
export const MIN_SELF_CARE_HABIT_SATISFACTORY_THRESHOLD = 6.5
export const MIN_SELF_CARE_HABIT_ACCEPTABLE_THRESHOLD = 5

export const getInsightForCheckInInsight = (firstName, userEnergyLevel) => {
  const energyDescription = userEnergyLevel === "low" ? "leaned towards lower energy states" : (userEnergyLevel === "high" ? "leaned towards higher energy states" : "been balanced between low and high energy states")
  const energyInsight = userEnergyLevel === "low" ? "will benefit from daily Exercise and Social Play." : (userEnergyLevel === "high" ? "will benefit from a daily Stillness practice." : "will benefit from a daily Embodiment practice to anchor into Ventral Vagal.")
  return `${firstName}'s check-ins so far have ${energyDescription}. Their nervous system ${energyInsight}`
}

export const getInsightForEmbodiment = (data, firstName, memberHabitStatistics) => {
  const solidAreas = data.filter(d => (d.value >= MIN_HABIT_SOLID_THRESHOLD)).length
  const satisfactoryAreas = data.filter(d => (d.value >= MIN_HABIT_SATISFACTORY_THRESHOLD)).length
  const acceptableAreas = data.filter(d => (d.value >= MIN_HABIT_ACCEPTABLE_THRESHOLD)).length
  if (solidAreas === data.length) {
    return `${firstName}'s embodiment habits look great!`
  } else if (satisfactoryAreas === data.length) {
    return `${firstName}'s embodiment habits look good. Keep an eye on their ${getMinHabitBelowThreshold(data, MIN_HABIT_SOLID_THRESHOLD, "", false, memberHabitStatistics)}.`
  } else if (acceptableAreas === data.length) {
    return `${firstName}'s embodiment habits need some attention. Ensure they are prioritizing their ${getMinHabitBelowThreshold(data, MIN_HABIT_SATISFACTORY_THRESHOLD, "", false, memberHabitStatistics)}.`
  } else {
    return `It's time for ${firstName} to focus on embodiment habits. Make sure they prioritize their ${getMinHabitBelowThreshold(data, MIN_HABIT_ACCEPTABLE_THRESHOLD, "", false, memberHabitStatistics)}.`
  }
}
export const getInsightForAvoidance = (data, firstName, memberHabitStatistics) => {
  const solidAreas = data.filter(d => (d.value >= MIN_HABIT_SOLID_THRESHOLD)).length
  const satisfactoryAreas = data.filter(d => (d.value >= MIN_HABIT_SATISFACTORY_THRESHOLD)).length
  const acceptableAreas = data.filter(d => (d.value >= MIN_HABIT_ACCEPTABLE_THRESHOLD)).length
  if (solidAreas === data.length) {
    return `${firstName} has been avoiding habits that impact the nervous system!`
  } else if (satisfactoryAreas === data.length) {
    return `${firstName}'s prevention habits look good. It will help balance their nervous system to avoid ${getMinHabitBelowThreshold(data, MIN_HABIT_SOLID_THRESHOLD, "avoid ", false, memberHabitStatistics)}.`
  } else if (acceptableAreas === data.length) {
    return `To optimize ${firstName}'s NEUROFIT results, ensure they avoid ${getMinHabitBelowThreshold(data, MIN_HABIT_SATISFACTORY_THRESHOLD, "avoid ", false, memberHabitStatistics)}.`
  } else {
    return `To optimize ${firstName}'s NEUROFIT results, ensure they avoid ${getMinHabitBelowThreshold(data, MIN_HABIT_ACCEPTABLE_THRESHOLD, "avoid ", false, memberHabitStatistics)}.`
  }
}
export const getInsightForSelfCare = (data, firstName, memberHabitStatistics) => {
  const solidAreas = data.filter(d => (d.value >= MIN_SELF_CARE_HABIT_SOLID_THRESHOLD)).length
  const satisfactoryAreas = data.filter(d => (d.value >= MIN_SELF_CARE_HABIT_SATISFACTORY_THRESHOLD)).length
  const acceptableAreas = data.filter(d => (d.value >= MIN_SELF_CARE_HABIT_ACCEPTABLE_THRESHOLD)).length
  if (solidAreas === data.length) {
    return `${firstName}'s self-care habits look great!`
  } else if (satisfactoryAreas === data.length) {
    return `${firstName}'s self-care habits look good. Keep an eye on their ${getMinHabitBelowThreshold(data, MIN_SELF_CARE_HABIT_SOLID_THRESHOLD, "", false, memberHabitStatistics)}.`
  } else if (acceptableAreas === data.length) {
    return `${firstName}'s self-care could use some attention. Ensure they prioritize their ${getMinHabitBelowThreshold(data, MIN_SELF_CARE_HABIT_SATISFACTORY_THRESHOLD, "", false, memberHabitStatistics)}.`
  } else {
    return `To optimize ${firstName}'s NEUROFIT results, ensure they prioritize their ${getMinHabitBelowThreshold(data, MIN_SELF_CARE_HABIT_ACCEPTABLE_THRESHOLD, "", false, memberHabitStatistics)}.`
  }
}

export const getInsightForWeeklyMomentum = (data, firstName) => {
  return " "
}

const joinHabitsBelowThreshold = (data, threshold, trimPrefix="", upperCase=false) => {
  const habitNames = data.filter(d => (d.value < threshold)).map(d => (upperCase ? d.name.toUpperCase() : d.name.toLowerCase()).replace(trimPrefix,""))
  if (habitNames.length === 0) {
    return ""
  } else if (habitNames.length === 1) {
    return habitNames[0]
  } else if (habitNames.length === 2) {
    return `${habitNames[0]} and ${habitNames[1]}`
  } else {
    return `${habitNames.slice(0, habitNames.length - 1).join(", ")}, and ${habitNames[habitNames.length - 1]}`
  }
}

const getMinHabitBelowThreshold = (data, threshold, trimPrefix="", upperCase=false, memberHabitStatistics=undefined) => {
  const habits = data.sort((a, b) => a.value - b.value).filter(d => (d.value < threshold))
  if (habits.length === 0) {
    return ""
  } else {
    const selectedHabit = habits[0]
    const habitName = selectedHabit.name
    const formattedHabitName = (upperCase ? selectedHabit.name.toUpperCase() : selectedHabit.name.toLowerCase()).replace(trimPrefix, "")
    if (!!(memberHabitStatistics)) {
      try {
        const selectedHabitKey = selectedHabit.key
        let statisticStrings = []
        if (memberHabitStatistics.check_in[selectedHabitKey] > 0) {
          statisticStrings.push(`${(memberHabitStatistics.check_in[selectedHabitKey] * 100).toFixed(0)}% more balanced check-ins`)
        }
        if (memberHabitStatistics.hrv[selectedHabitKey] > 0) {
          statisticStrings.push(`${(memberHabitStatistics.hrv[selectedHabitKey] * 100).toFixed(0)}% higher HRV`)
        }
        let habitMemberStatisticsString = ""
        if (statisticStrings.length > 0) {
          habitMemberStatisticsString = ` - members who do report ${joinWithAnd(statisticStrings)}`
        }

        return formattedHabitName + habitMemberStatisticsString
      } catch (e) {
        return formattedHabitName
      }
    } else {
      return formattedHabitName
    }
  }
}


export const getMetaForDysregulationQuizScore = (score, dataTimeOfDay, timeOfDay, firstName) => {
  if (score < 50) {
    return {name: "Depleted", color: "#FFC8E3", description: `${firstName}'s nervous system appears to be noticeably dysregulated based on their results. See the detailed breakdown below.`}
  } else if (score < 67) {
    return {name: "Recovering", color: "#FAE4C7", description: `${firstName}'s nervous system appears to be moderately dysregulated based on their results. See the detailed breakdown below.`}
  } else if (score < 80) {
    return {name: "Balanced", color: "#CFF2D2", description: `Based on their results, ${firstName}'s nervous system appears to be moderately balanced.`}
  } else if (score <= 100) {
    return {name: "Restored", color: "#BBEDBF", description: `${firstName}'s nervous system seems to be highly balanced based on their results.`}
  }
}

export const getMetaForQuizSymptomResultsData = (data, dataTimeOfDay, firstName) => {
  const score = data.filter(d => d.name === "overall")[0].value
  const dysregulationSymptoms = data.filter(d => ((d.name !== "overall") && (d.value <= 0.5))).map(s => s.name)
  const dysregulationSymptomCount = dysregulationSymptoms.length

  const explanationText = (dysregulationSymptomCount > 0) ? ` Nervous system dyregulation often leads to ${joinWithAnd(dysregulationSymptoms)} imbalances.` : ""
  if (score < 0.5) {
    return `${firstName}'s results ${(dysregulationSymptomCount > 1) ? `contain several markers of` : `point to noticeable`} dysregulation.${explanationText}`
  } else if (score < 0.67) {
    return `${firstName}'s symptom results ${(dysregulationSymptomCount > 1) ? `contain several markers of` : `point to moderate`} nervous system dysregulation.${explanationText}`
  } else if (score < 0.80) {
    return `${firstName}'s symptom results point to moderate nervous system balance.${explanationText}`
  } else if (score <= 1.0) {
    return  `${firstName}'s symptom results suggest that their nervous system is highly balanced.${explanationText}`
  }
}

export const getMetaForQuizHabitResultsData = (data, dataTimeOfDay, firstName) => {
  const score = data.filter(d => d.name === "overall")[0].value

  const dysregulationHabits = data.filter(d => ((d.name !== "overall") && (d.value <= 0.5))).map(s => s.name.toUpperCase())
  const dysregulationHabitsCount = dysregulationHabits.length

  const explanationText = (dysregulationHabitsCount > 0) ? ` Nervous system dyregulation often stems from inconsistency with ${joinWithAnd(dysregulationHabits)} habits.` : ""

  if (score < 0.5) {
    return `${firstName}'s habit results often lead to noticeable dysregulation.${explanationText}`
  } else if (score < 0.67) {
    return `${firstName}'s habit results often lead to moderate dysregulation.${explanationText}`
  } else if (score < 0.8) {
    return `${firstName}'s habit results support moderate nervous system balance.${explanationText}`
  } else if (score <= 1.0) {
    return  `${firstName}'s habit results support a highly balanced nervous system.${explanationText}`
  }
}

export const getMetaForQuizSelfCheckInResultsData = (data, firstName) => {
  const positivePercentages = (data.parasympathetic || 0.0) + (data.stillness || 0.0) + (data.play || 0.0)
  const stillPercentages = (data.stillness || 0.0) + (data.dorsal_vagal || 0.0)
  const energizedPercentages = (data.play || 0.0) + (data.sympathetic || 0.0)
  let result = ""
  const descriptor = `Based on their check-in results, ${firstName}'s nervous system`
  if (positivePercentages >= 0.875) {
    return `${descriptor} looks highly balanced.`
  } else if (positivePercentages >= 0.75) {
    result += `${descriptor} looks balanced.`
  } else if (positivePercentages >= 0.625) {
    result += `${descriptor} looks fairly balanced.`
    if (stillPercentages >= 0.25) {
      result += " For greater balance, ensure exercise and movement are a part of their daily routine."
    } else if (energizedPercentages >= 0.25) {
      result += " For greater balance, ensure stillness and reflection are a part of their daily routine."
    }
  } else if (positivePercentages >= 0.5) {
    result += `${descriptor} could use some balancing.`
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " Encourage them to add more exercise and stillness to their routine."
    } else if (energizedPercentages >= 0.25) {
      result += " Encourage them to add more stillness and reflection to their routine."
    } else if (stillPercentages >= 0.25) {
      result += " Encourage them to add more exercise and movement to their routine."
    }
  } else {
    result += `${descriptor} is in need of balancing.`
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += ` Encourage them to add more stillness and activity to their routine.`
    } else if (energizedPercentages >= 0.25) {
      result += ` Encourage them to add more stillness and reflection to their routine.`
    } else if (stillPercentages >= 0.25) {
      result += ` Encourage them to add more activity and movement to their routine.`
    }
  }

  return result
}



export const joinWithAnd = values => {
  if (values.length === 1) {
    return values[0]
  } else if (values.length === 2) {
    return `${values[0]} and ${values[1]}`
  } else {
    return `${values.slice(0, values.length - 1).join(", ")}, and ${values[values.length - 1]}`
  }
}
