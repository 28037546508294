import React from 'react'

import i18next from 'i18next'

import TextareaAutosize from 'react-textarea-autosize';

import {
  ClearSvg,
  OvalSpinnerSvg,
} from '../../constants/svgs'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../constants/requestStates'

const AutogenerateTextInput = ({
  title,
  placeholderText,
  value,
  autogenerateTextRequestState,
  onChange,
  showEditableFields,
  formFieldsDisabled,
  maxCharacterLimit,
  onTriggerWriteWithAIRequest
}) => {
  return (
    <div style={{marginTop: "2vh", width: "100%"}}>
      {(!!title) && (
        <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
          {title}
        </div>
      )}
      <TextareaAutosize
        style={{backgroundColor: "#FFFFFF", padding: "min(3vw, 10px)", width: "calc(100% - 4px - min(6vw, 20px))", resize: "none", fontFamily: "Futura", border: "none", borderRadius: 0, boxShadow: "", caretColor: "#000000", fontSize: "min(5.5vw, 22px)", height: 250, padding: 10, width: "calc(100% - 20px)", boxShadow: "inset 0px 0.5px 1.5px 1px #dddddd", color: "#000000", cursor: formFieldsDisabled ? "not-allowed" : "auto"}}
        minRows={6}
        value={value}
        disabled={formFieldsDisabled}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholderText}
      />
      {showEditableFields && (
        <div style={{display: "flex", width: "100%", marginTop: 10, justifyContent: "space-between", alignItems: "top"}}>
          <button
            type="button"
            disabled={formFieldsDisabled}
            style={{display: "inline-block", width: "200px", height: 40, border: "none", borderRadius: 2, backgroundColor: "#000000", overflow: "hidden", color: "#FFFFFF", fontFamily: "Futura Medium", textTransform: "uppercase", padding: 0}}
            onClick={() => {
              onTriggerWriteWithAIRequest()
            }}
          >
            <div style={{display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center"}}>
              <div style={{display: "inline-block", fontSize: "min(4.5vw, 18px)", marginInlineEnd: "0.4em"}}>{(autogenerateTextRequestState === REQUEST_SUCCESS) ? "REGENERATE" : ((autogenerateTextRequestState === REQUEST_FETCHING) ? "GENERATING..." : "WRITE WITH AI")}</div>
              <div style={{width: "min(4.5vw, 18px)", height: "min(4.5vw, 18px)"}}>
                {(autogenerateTextRequestState === REQUEST_UNSTARTED || autogenerateTextRequestState === REQUEST_ERROR || autogenerateTextRequestState === REQUEST_SUCCESS) && (
                  <ClearSvg
                    size={"min(4.5vw, 18px)"}
                    color={"#FFFFFF"}
                  />
                )}
                {(autogenerateTextRequestState === REQUEST_FETCHING) && (
                  <OvalSpinnerSvg
                    size={"min(4.5vw, 18px)"}
                    color={"#FFFFFF"}
                  />
                )}
              </div>
            </div>
          </button>
          <div style={{fontFamily: "Futura", fontSize: "min(4vw, 18px)", color: (value.length > maxCharacterLimit) ? "#ff9999" : "#b2b6c7", transition: "color 250ms linear"}}>
            {`${maxCharacterLimit - value.length}`}
          </div>
        </div>
      )}
    </div>
  );
}

export default AutogenerateTextInput;