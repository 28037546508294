import React from 'react'

import {
  formatReadableUnixTimestamp,
  getTimeOfDay,
} from '../../../utils'

import {
  getMetaForDysregulationQuizScore,
  getMetaForQuizSymptomResultsData,
  getMetaForQuizHabitResultsData,
  getMetaForQuizSelfCheckInResultsData,
} from '../../../dashboardUtils'

import HrvResultsPanel from '../HrvResultsPanel'
import PieChartInsightSection from '../PieChartInsightSection'
import HabitBarChartInsightSection from '../HabitBarChartInsightSection'


const DiscoveryQuizResults = ({
  hideTitle,
  hideTotalQuizScore,
  quizResultObject,
  firstName,
  timeOfDay,
  isCompleted,
  isDesktopWidth
}) => {
  const overallQuizResultScore = isCompleted && quizResultObject.total_quiz_score * 100

  const quizSymptomResultsData = isCompleted && [
    {
      name: "overall",
      value: quizResultObject.symptom_total_score
    },
    {
      name: "physical",
      value: quizResultObject.symptom_physical_score
    },
    {
      name: "mental",
      value: quizResultObject.symptom_mental_score
    },
    {
      name: "emotional",
      value: quizResultObject.symptom_emotional_score
    },
  ]

  const quizHabitResultsData = isCompleted && [
    {
      name: "overall",
      value: quizResultObject.habit_total_score
    },
    {
      name: "embody",
      value: quizResultObject.habit_embody_score
    },
    {
      name: "prevent",
      value: quizResultObject.habit_prevent_score
    },
    {
      name: "nourish",
      value: quizResultObject.habit_nourish_score
    },
  ]


  const quizCheckInResultsData = isCompleted && {
    parasympathetic: quizResultObject.life_aspects_parasympathetic,
    play: quizResultObject.life_aspects_play,
    stillness: quizResultObject.life_aspects_stillness,
    sympathetic: quizResultObject.life_aspects_sympathetic,
    dorsal_vagal: quizResultObject.life_aspects_dorsal_vagal,
    overwhelm: quizResultObject.life_aspects_overwhelm
  }
  const resultsCreatedAtUnix = isCompleted && quizResultObject.created_at_unix 

  const dyregulationQuizScoreMetaObject = getMetaForDysregulationQuizScore(overallQuizResultScore, getTimeOfDay(resultsCreatedAtUnix), timeOfDay, firstName)

  return (
    ((isCompleted) ? (
      <div>
        {!hideTitle && (
          <div>
          	<div style={{fontSize: "min(7.5vw, 32px)", marginTop: "min(16vh, 80px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura"}}>
              {"DISCOVERY QUIZ RESULTS"}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", textTransform: "uppercase", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura", margin: "2vh 0vh"}}>
              {formatReadableUnixTimestamp(resultsCreatedAtUnix)}
            </div>
          </div>
        )}
        {!hideTotalQuizScore && (
          <div style={{maxWidth: 500}}>
            <HrvResultsPanel
              title={"OVERALL NERVOUS SYSTEM REGULATION SCORE"}
              marginTop={"min(8vh, 40px)"}
              value={overallQuizResultScore}
              dataIsAvailable
              isPercentage
              valueDecimalPoints={0}
              isIncreasingStatistic
              valueMetaObject={dyregulationQuizScoreMetaObject}
              baselineAvailable={false}
              backgroundColor={dyregulationQuizScoreMetaObject.color}
              valueBaseline={undefined}
              valueBaselineStdDev={undefined}
              valueDescriptorNameColor={"#000000"}
              hideInfoButton
            />
          </div>
        )}
        <div style={isDesktopWidth ? {display: "flex", marginTop: "min(8vh, 40px)", alignItems: "top", justifyContent: "space-between"} : {}}>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            <HabitBarChartInsightSection
              title={`SYMPTOM BALANCE`}
              dataIsAvailable
              data={quizSymptomResultsData}
              marginTop={"max(2vh, 8px)"}
              insightText={getMetaForQuizSymptomResultsData(quizSymptomResultsData, getTimeOfDay(resultsCreatedAtUnix), firstName)}
              firstName={firstName}
            />
          </div>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            <HabitBarChartInsightSection
              title={`HABIT CONSISTENCY`}
              marginTop={"max(2vh, 8px)"}
              dataIsAvailable
              data={quizHabitResultsData}
              insightText={getMetaForQuizHabitResultsData(quizHabitResultsData, getTimeOfDay(resultsCreatedAtUnix), firstName)}
              firstName={firstName}
            />
          </div>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            <PieChartInsightSection
              title="SELF CHECK-IN"
              marginTop={"max(2vh, 8px)"}
              disableDropDown
              dataIsAvailable
              data={quizCheckInResultsData}
              insightText={getMetaForQuizSelfCheckInResultsData(quizCheckInResultsData, firstName)}
            />
          </div>
        </div>
      </div>
    ) : (
      <div style={{backgroundColor: "#FFFFFF", marginTop: "min(16vh, 80px)", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
        <div style={{padding: "min(5vw, 22px)"}}>
          <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", marginBottom: "2vh"}}>
            {"DISCOVERY QUIZ RESULTS: PENDING"}
          </div>
          <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
            {`When ${firstName} completes the Discovery Quiz inside the NEUROFIT App, their results will show up here.`}
          </div>
          <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
            {`They can complete the in-app Discovery Quiz by navigating to:`}
          </div>
          <div style={{fontFamily: "Futura", marginTop: "2vh", color: "#000000", fontSize: "min(5vw, 22px)"}}>{`Settings > Data > Link Trainer Account`}</div>
        </div>
      </div>
    ))
  )
}

export default DiscoveryQuizResults;