import React from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import SendRequestButton from '../common/SendRequestButton'
import LoadingLogo from '../common/LoadingLogo'
import TopErrorMessage from '../common/TopErrorMessage'
import TextFeedbackPrompt from '../common/TextFeedbackPrompt'
import ConfirmSection from '../common/ConfirmSection'

import {
  REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL,
  REQ_ACTION__FLASH_TOP_ERROR_MESSAGE,
  REQ__ACTION_REDIRECT_NATIVE_APP_AUTH,
} from '../../constants/requestTypes'

import {
  FORM_FIELD_CLIENT_TESTIMONIAL_TEXT,
  FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME,
  FORM_FIELD_SHOW_TOP_ERROR_MESSAGE,
  FORM_FIELD_TOP_ERROR_MESSAGE_VALUE,
  FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE,
  FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE,
  FORM_FIELD_AUTH_REDIRECT_PATH,
} from '../../constants/formFields'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR
} from '../../constants/requestStates';

import i18next from 'i18next'

import {
  MinPasswordLength,
} from '../../appUtils'

import {
  getReadableDurationSinceTimestamp,
} from '../../utils'

import {
  NeuroFitNamedLogoSvg,
} from '../../constants/svgs'

const MinClientTestimonialLength = 50
const MaxClientTestimonialLength = 1000

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const WriteClientTestimonialPage = ({

  safeAreaInsets,
  innerHeight,
  formFields,
  requestResults,
  countryCode,
  clientTestimonialTokenIsValid,
  clientTestimonialResultDetails,

  onUpdateFormField,
  onUpdateRequestState,
  onTriggerRequest

}) => {
  const testimonialText = formFields[FORM_FIELD_CLIENT_TESTIMONIAL_TEXT] 
  const clientTestimonialIncludeClientName = formFields[FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME]
  const topMessageValue = formFields[FORM_FIELD_TOP_ERROR_MESSAGE_VALUE]
  const showTopMessage = formFields[FORM_FIELD_SHOW_TOP_ERROR_MESSAGE]

  const topSuccessMessageValue = formFields[FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE]
  const showTopSuccessMessage = formFields[FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE]

  const saveClientTestimonialResult = requestResults[REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL]

  const formFieldsDisabled = (saveClientTestimonialResult.state === REQUEST_FETCHING) || (saveClientTestimonialResult.state === REQUEST_SUCCESS)
  const saveClientTestimonialButtonDisabled = ((saveClientTestimonialResult.state == REQUEST_FETCHING) || (saveClientTestimonialResult.state == REQUEST_SUCCESS))


  const minTestimonialLengthMet = MinClientTestimonialLength <= testimonialText.length
  const maxTestimonialLengthExceeded = MaxClientTestimonialLength < testimonialText.length

  const clientFirstName = clientTestimonialResultDetails.client_relationship_data.client_first_name
  const coachFirstName = clientTestimonialResultDetails.client_relationship_data.trainer_first_name
  const coachLastName = clientTestimonialResultDetails.client_relationship_data.trainer_last_name
  const readableCoachingDuration = getReadableDurationSinceTimestamp(clientTestimonialResultDetails.client_relationship_data.linked_account_at_unix)
  const readableTokenExpirationDuration = getReadableDurationSinceTimestamp(clientTestimonialResultDetails.token_expires_at_unix)

  return (
    <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
      <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
      <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
        <NeuroFitNamedLogoSvg
          logoSize={"min(4.25vw, 18px)"}
          color={"#000000"}
        />
      </div>
      <div 
        className={"disable-scrollbars"}
        style={containerStyle}
      >
        <div style={{textAlign: "start", width: "100vw"}}>
          <div style={{backgroundColor: "#EDEEF2", padding: "10vh 0px 10vh 0px", borderTop: "solid black 2px"}}>
            <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 600, color: "#000"}}>
                <div style={{backgroundColor: "#EDEEF2", padding: "min(24px, max(3vw, 16px))", marginTop: "max(6vw, 32px)"}}>
                  <div style={{display: "block", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginBottom: "min(24px, max(3vw, 16px))", lineHeight: "min(5vw, 22px)"}}>
                    {"Write A Testimonial"}
                  </div>
                  <div style={{marginInlineEnd: "0.2em", marginBottom: "2vh", fontSize: "min(5vw, 20px)", fontFamily: "Futura Book"}}>
                    {`${clientFirstName}, you started working with your NEUROFIT Coach ${coachFirstName} ${coachLastName} ${readableCoachingDuration}.`}
                  </div>
                  <div style={{marginInlineEnd: "0.2em", marginBottom: "2vh", fontSize: "min(5vw, 20px)", fontFamily: "Futura Book"}}>
                    <span>{`Write your testimonial below`}</span>
                    <span style={{fontStyle: "italic"}}>{` (note: this link expires ${readableTokenExpirationDuration} for security reasons)`}</span>
                    <span>{`:`}</span>
                  </div>
                  <div style={{marginTop: 16}}>
                    <TextFeedbackPrompt
                      disabled={formFieldsDisabled}
                      color="#000000"
                      inputBackgroundColor="#ffffff"
                      marginTop={"1vh"}
                      marginBottom={"2vh"}
                      placeholderText={`Describe how your overall well-being has changed since working with ${coachFirstName}...`}
                      value={testimonialText}
                      onChange={val => {
                        onUpdateFormField(FORM_FIELD_CLIENT_TESTIMONIAL_TEXT, val)
                      }}
                    />
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingInlineEnd: 10, fontFamily: "Futura", fontSize: "min(4vw, 18px)", color: (maxTestimonialLengthExceeded) ? "#ff9999" : "#c1c4d2", transition: "color 250ms linear"}}>
                      {!minTestimonialLengthMet ? `Add ${MinClientTestimonialLength - testimonialText.length} More Characters` : `${MaxClientTestimonialLength - testimonialText.length} Characters Remaining`}
                    </div>
                  </div>
                  <ConfirmSection
                    label={"Include your first name"}
                    isConfirmed={clientTestimonialIncludeClientName}
                    onToggleConfirm={(val) => {
                      onUpdateFormField(FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME, val)
                    }}
                    isDisabled={formFieldsDisabled}
                    marginTop={"4vh"}
                    color={"#000000"}
                  />
                  <div style={{height: "2vh"}} />
                  <SendRequestButton
                    defaultContent={"Send Testimonial"}
                    loadingText={"Saving..."}
                    successText={"Testimonial Sent"}
                    requestState={saveClientTestimonialResult.state}
                    isDisabled={saveClientTestimonialButtonDisabled}
                    onClick={() => {
                      onTriggerRequest(REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL)
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <TopErrorMessage
        show={showTopMessage}
        message={topMessageValue}
        safeAreaInsets={safeAreaInsets}
      />
    </div>
  )
}

export default WriteClientTestimonialPage;