import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import { history } from './history'
import { store } from './store-dashboard';

import { Router, Route, Switch } from 'react-router-dom';

import App from './entrypoints/DashboardApp'

require('intersection-observer');

const safeAreaInsets = require('safe-area-insets')

require('./style.css');

ReactDOM.render((
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/" render={props => (
          <App {...props} />
        )} />
      </Switch>
    </Router>
  </Provider>

), document.getElementById('root'));
