import React from 'react'

const MobileTaskBadgeCount = ({count, isSelected}) => (
  <div style={{height: 15, width: 15, borderRadius: "50%", position: "absolute", top: 0, right: -3, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: `rgba(${229 * (isSelected ? 1.0 : 0.7)}, 0, 0)`}}>
    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium", fontSize: "10px", lineHeight: "10px"}}>
      {count}
    </span>
  </div>
)


const DashboardTopMenuButton = ({
	title,
	logo,
	hideTitle,
  isSelected,
  badgeCount,
	onClick
}) => (
  <div
  >
    <button
      className={"dashboard-top-menu-button"}
      style={{marginInlineEnd: 10, border: "none", dropShadow: "none", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
      onClick={() => onClick()}
    >
      <span style={{width: 25, height: 25, display: "flex", justifyContent: "center", position: "relative"}}>
        {logo}
        {!!badgeCount && (
          <MobileTaskBadgeCount
            count={badgeCount}
            isSelected={isSelected}
          />
        )}
      </span>
      <span style={{fontSize: "min(4vw, 18px)", whiteSpace: "nowrap", color: isSelected ? "#FFFFFF" : "#707070", transition: "color 200ms linear", textTransform: "uppercase", marginTop: "0.3em", fontFamily: "Futura", fontSize: "min(3vw, 12px)"}}>
        {title}
      </span>
    </button>
  </div>
)

export default DashboardTopMenuButton