const semverGte = require('semver/functions/gte')
const semverValid = require('semver/functions/valid')
const semverCoerce = require('semver/functions/coerce')

export const MinIOSVersionSignupWithApple = "1.7.0"
export const MinIOSVersionAutoFill = "1.10.0"
export const MinVersionCapacitorKeyboard = "2.1.0"
export const MinVersionClearJournaling = "2.1.0"
export const MinVersionOfflineHandling = "2.2.0"
export const MinVersionSplashScreen = "2.3.0"
export const MinVersionExperimentFlags = "2.3.0"
export const MinVersionAppIconCustomization = "2.4.0"
export const MinIOSVersionAppleSearchAdsAttribution = "2.4.3"
export const MinIOSVersionDetectMacOSRuntime = "2.4.4"
export const MinVersionLanguageSettingsAvailable = "3.0.0"
export const MinVersionV2AppIconsAvailable = "3.1.0"
export const MinVersionUpdatedAppReviewModuleAvailable = "4.0.0"
export const MinAndroidVersionSignupWithGoogle = "4.0.0"
export const MinVersionUpdatedInAppPurchaseModule = "4.0.0"
export const MinVersionSecureStorage = "4.0.0"
export const MinVersionInAppPurchaseTokenEndpoint = "4.0.1"
export const MinVersionAndroidRetryBillingConnection = "4.0.2"
export const MinVersionV3AppIconsAvailable = "4.0.3"

const isFeatureEnabled = (currentAppVersion, minEnabledAppVersion) => {
  try {
    const validatedVersionId = semverValid(semverCoerce(currentAppVersion))
    return semverGte(validatedVersionId, minEnabledAppVersion)
  } catch (e) {
    return false
  }
}

export const isAppleAuthEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinIOSVersionSignupWithApple)
}

export const isGoogleAuthEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinAndroidVersionSignupWithGoogle)
}

export const isIOSAutoFillEnabled = currentAppVersion => {
	return isFeatureEnabled(currentAppVersion, MinIOSVersionAutoFill)
}

export const isCapacitorKeyboardEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionCapacitorKeyboard)
}

export const isClearJournalingAvailable = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionClearJournaling)
}

export const isOfflineHandlingEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionOfflineHandling)
}

export const isSplashScreenEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionSplashScreen)
}

export const isExperimentFlagsEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionExperimentFlags)
}

export const isAppIconCustomizationEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionAppIconCustomization)
}

export const isIOSAppleSearchAdsAttributionEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinIOSVersionAppleSearchAdsAttribution)
}

export const isIOSDetectMacOSRuntimeEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinIOSVersionDetectMacOSRuntime)
}

export const isLanguageSettingsAvailable = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionLanguageSettingsAvailable)
}

export const isV2AppIconSetAvailable = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionV2AppIconsAvailable)
}

export const isV3AppIconSetAvailable = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionV3AppIconsAvailable)
}

export const isUpdatedAppReviewPackageEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionUpdatedAppReviewModuleAvailable)
}

export const isUpdatedInAppPurchaseModuleEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionUpdatedInAppPurchaseModule)
}

export const isSecureStorageEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionSecureStorage)
}

export const isInAppPurchaseTokenEndpointEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionInAppPurchaseTokenEndpoint)
}

export const isAndroidRetryBillingConnectionEnabled = currentAppVersion => {
  return isFeatureEnabled(currentAppVersion, MinVersionAndroidRetryBillingConnection)
}
// export const isNativeHttpEnabled = currentAppVersion => {
//   return isFeatureEnabled(currentAppVersion, MinVersionNativeHttp)
// }

// export const iOSLocalPlaybackEnabled = currentAppVersion => {
// 	return isFeatureEnabled(currentAppVersion, MinIOSVersionLocalPlayback)
// }