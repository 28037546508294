import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

// REDUCERS
import loginPage from './reducers/loginPage';
import loggedInPage from './reducers/loggedInPage';
import quizPage from './reducers/quizPage'
import common from './reducers/common';
import dashboardLoggedInPage from './reducers/dashboardLoggedInPage';

import { history } from './history'
import rootSaga from './sagas-dashboard';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: history,
  //other options if needed 
});

// Build the middleware for intercepting and dispatching navigation actions
const sagaMiddleware = createSagaMiddleware()

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(routerMiddleware, sagaMiddleware);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(routerMiddleware, sagaMiddleware, createLogger())
  }
};

const store = createStore(
	combineReducers({
	  common,
	  loginPage,
	  loggedInPage,
	  quizPage,
	  dashboardLoggedInPage,
	  router: routerReducer
	}),
	composeWithDevTools(getMiddleware())
);

sagaMiddleware.run(rootSaga)

export { store }
