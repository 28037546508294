export const APP_ICON_CLASSIC = "classic"
export const APP_ICON_SPORT = "sport"
export const APP_ICON_ELEVATE = "elevate"
export const APP_ICON_ECLIPSE = "eclipse"
export const APP_ICON_ZEN = "zen"


export const AppIcons = [
  {
    key: "classic",
    filename: APP_ICON_CLASSIC,
    img_src: "/images/app-icons/classic.svg?v=3",
    img_src_v2: "/images/app-icons/v2/classic.svg?v=1"
  },
  {
    key: "zen",
    filename: APP_ICON_ZEN,
    img_src: "/images/app-icons/zen.svg?v=3",
    img_src_v2: "/images/app-icons/v2/zen.svg?v=1"
  },
  {
    key: "elevate",
    filename: APP_ICON_ELEVATE,
    img_src: "/images/app-icons/elevate.svg?v=3",
    img_src_v2: "/images/app-icons/v2/elevate.svg?v=1"
  },
  {
    key: "sport",
    filename: APP_ICON_SPORT,
    img_src: "/images/app-icons/sport.svg?v=3",
    img_src_v2: "/images/app-icons/v2/sport.svg?v=1"
  }
]

export const AppIconsV3 = [
  {
    key: "classic",
    filename: APP_ICON_CLASSIC,
    img_src_v3: "/images/app-icons/v3/classic.webp?v=2"
  },
  {
    key: "zen",
    filename: APP_ICON_ZEN,
    img_src_v3: "/images/app-icons/v3/zen.webp?v=1"
  },
  {
    key: "elevate",
    filename: APP_ICON_ELEVATE,
    img_src_v3: "/images/app-icons/v3/elevate.webp?v=1"
  },
  {
    key: "sport",
    filename: APP_ICON_SPORT,
    img_src_v3: "/images/app-icons/v3/sport.webp?v=1"
  },
  {
    key: "eclipse",
    filename: APP_ICON_ECLIPSE,
    img_src_v3: "/images/app-icons/v3/eclipse.webp?v=3"
  }
]

export const getAndroidDisabledIconList = (enabledIconName, v3AppIconsAvailable) => {
  const selectedAppIcons = v3AppIconsAvailable ? AppIconsV3 : AppIcons

  const iconsFileNames = selectedAppIcons.map(icon => icon.filename)


  let disabledIconSet = new Set(iconsFileNames)
  disabledIconSet.delete(enabledIconName)
  // Remove default icon from this list: the AppIcon plugin handles disabling it on change() calls.
  disabledIconSet.delete(APP_ICON_CLASSIC)
  return [...disabledIconSet]
}