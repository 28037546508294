import React from 'react';
import { loadStripe } from '@stripe/stripe-js'
import {PaymentElement, CardElement, PaymentRequestButtonElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import ProgramResultsPanel from './ProgramResultsPanel'
import ConfirmSection from './ConfirmSection'
import LoadingLogo from './LoadingLogo'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import {
  StripePublishableKey,
  NeuroFitUniversalAppStoreLink,
  getFormattedDateDaysFromNow,
  getCsrfTokenFromCookies,
  INVITE_CODE_LOCAL_STORAGE_KEY,
  subdomainUrls,
  recordFunnelEventResultGA,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  sanitizeEmailAddress,
} from '../../utils'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import {
  LockSvg,
  StripeSvg,
  ErrorSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_APP_ACCESS_PURCHASE_TRIGGERED,
  GA_LABEL_APP_ACCESS_PURCHASE_COMPLETED,
} from '../../constants/gaEventLabels'

import agent from '../../agent'

const NEUROFIT_FONTS = [
  {
    src: 'url(https://neurofit.app/fonts/jost-variable.woff2?v=1)',
    family: 'Futura',
    style: 'normal',
    weight: '420'
  }
]

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isComplete: false,
      isProcessingPayment: false,
      nameOnCard: "",
      paymentStatus: REQUEST_UNSTARTED,
      paymentFailureMessage: "",
      paymentRequest: undefined,
      paymentRequestLoaded: false,
    };
  }

  async setUpPaymentRequestButtonElement() {
    const {stripe, productName, priceUsdCents} = this.props
    const {paymentRequest} = this.state

    if (!stripe) {
       return
    }

    // Validate whether we can show PaymentRequestButtonElement.
    if (!!(paymentRequest)) {
      paymentRequest.update({
        currency: 'usd',
        total: {
          label: productName,
          amount: priceUsdCents,
        }
      })
    } else {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: productName,
          amount: priceUsdCents,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      const result = await pr.canMakePayment()
      this.setState({paymentRequestLoaded: true})
      if (result) {
        pr.on("paymentmethod", this.handlePaymentRequestButtonCheckout)
        this.setState({paymentRequest: pr})
      }
    }
  }

  handlePaymentRequestButtonCheckout = async (ev) => {
    const {
      stripe,
      elements,
      pixelProductId,
      productName,
      priceUsdCents,
      clientSecret
    } = this.props;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    recordFunnelEventResultGA(GA_LABEL_APP_ACCESS_PURCHASE_TRIGGERED, {product_name: productName, gross_revenue_usd: priceUsdCents / 100.0})
    recordPixelEventIfAvailable("AddPaymentInfo", pixelProductId)

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const {paymentIntent, error} = await stripe.confirmCardPayment(
      clientSecret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    );

    if (error) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete('fail');
      console.log(error.message);
      this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})

    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log(error.message);
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          console.log(`Purchase Succeeded!`);
          recordFunnelEventResultGA(GA_LABEL_APP_ACCESS_PURCHASE_COMPLETED, {product_name: productName, gross_revenue_usd: priceUsdCents / 100.0})
          recordPixelEventIfAvailable("Purchase", pixelProductId)
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
        }
      } else {
        // The payment has succeeded.
        console.log(`Purchase Succeeded!`);
        recordFunnelEventResultGA(GA_LABEL_APP_ACCESS_PURCHASE_COMPLETED, {product_name: productName, gross_revenue_usd: priceUsdCents / 100.0})
        recordPixelEventIfAvailable("Purchase", pixelProductId)
        this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
      }
    }
  }
  
  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {
      stripe,
      elements,
      pixelProductId,
      productName,
      priceUsdCents,
      clientSecret,
      trainerEmailAddress
    } = this.props;

    const {
      nameOnCard,
    } = this.state

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({isProcessingPayment: true, paymentStatus: REQUEST_FETCHING})

    recordFunnelEventResultGA(GA_LABEL_APP_ACCESS_PURCHASE_TRIGGERED, {product_name: productName, gross_revenue_usd: priceUsdCents / 100.0})
    recordPixelEventIfAvailable("AddPaymentInfo", pixelProductId)

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        type: 'card',
        card: cardElement,
        billing_details: {
          name: nameOnCard,
          email: trainerEmailAddress,
        }
      },
      receipt_email: trainerEmailAddress
    });

    if (error) {
      console.log(error.message);
      this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
      return;
    } else {
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log(error.message);
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          console.log(`Purchase Succeeded!`);
          recordFunnelEventResultGA(GA_LABEL_APP_ACCESS_PURCHASE_COMPLETED, {product_name: productName, gross_revenue_usd: priceUsdCents / 100.0})
          recordPixelEventIfAvailable("Purchase", pixelProductId)
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
        }
      } else {
        // The payment has succeeded.
        console.log(`Purchase Succeeded!`);
        recordFunnelEventResultGA(GA_LABEL_APP_ACCESS_PURCHASE_COMPLETED, {product_name: productName, gross_revenue_usd: priceUsdCents / 100.0})
        recordPixelEventIfAvailable("Purchase", pixelProductId)
        this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
      }
    }
  }

  handleUpdateNameOnCard = (nameOnCard) => {
    this.setState({nameOnCard})
  }

  handleOnReady = async () => {
    this.setState({ready: true})

    await this.setUpPaymentRequestButtonElement()
  }

  handleUpdatedCompletionStatus = isComplete => {
    this.setState({isComplete})
  }

  render() {
    const {
      stripe,
      clientSecret,
      pixelProductId,
      productName,
      priceUsdCents,
    } = this.props;
    const {ready, isComplete, isProcessingPayment, paymentRequest, paymentRequestLoaded, paymentStatus, paymentFailureMessage, nameOnCard} = this.state;

    const readyForCheckout = isComplete && !!(nameOnCard)

    const checkoutButtonText = isProcessingPayment ? "PURCHASING..." :
          ((paymentStatus === REQUEST_SUCCESS) ? "PURCHASE COMPLETED" : "BUY NOW")

    const successMessage = `You've purchased NEUROFIT App access successfully. In a few moments, your receipient will receive an email confirmation with instructions to redeem their app access.`

    const fullCheckoutFormLoaded = ready && !!(clientSecret) && paymentRequestLoaded

    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{maxWidth: 500, color: "#000"}}>
          <div
            hidden={fullCheckoutFormLoaded}
            className={"loading-pulse-animation"}
            style={{opacity: (fullCheckoutFormLoaded) ? 0.0: 1.0, fontFamily: "Futura", textAlign: "center", marginTop: "5vh", fontSize: "min(4vh, 30px)", color: "#000000"}}
          >
            <LoadingLogo
              show={true}
              hideAnimation={true}
              responsiveSize={"min(7vw, 35px)"}
              marginTop={0}
              color={"black"}
              fontFamily={"Futura"}
              text={"Loading Checkout..."}
              showProgressionBar={false}
            />
          </div>
          <div style={{opacity: (fullCheckoutFormLoaded) ? 1.0 : 0.0, backgroundColor: "transparent", transition: "opacity 300ms linear"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"fade-in"}
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  key={`${priceUsdCents}`}
                >
                  <div style={{marginBottom: "min(24px, max(3vw, 16px))"}}>
                    <div style={{fontFamily: "Futura", fontSize: "min(6vw, 25px)", color: "#777"}}>
                      {productName}
                    </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 25px)", color: "#777"}}>
                    {`$${(priceUsdCents / 100.0).toFixed(2)} USD`}
                  </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div>
            <div style={{marginInlineEnd: "0.2em", marginBottom: "min(24px, max(3vw, 16px))", fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Light"}}>
              <span>
                {"After checkout, your recipient will receive a confirmation email explaining how to redeem their app access."}
              </span>
            </div>
            <form onSubmit={this.handleSubmit}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"fade-in"}
                  unmountOnExit
                  addEndListener={async (node, done) => {
                    node.addEventListener("transitionend", done, false);
                    await this.setUpPaymentRequestButtonElement()
                  }}
                  key={`${clientSecret}`}
                >
                  <div>
                    {!!(paymentRequest) && (
                      <div style={{marginTop: "10px"}}>
                        <PaymentRequestButtonElement options={{paymentRequest}} />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)", margin: "15px 0px", lineHeight: "min(6vw, 26px)", color: "#a5a8b0"}}>
                          <div style={{height: "1px", width: "50%", flex: "2 1 auto", backgroundColor: "#a5a8b0"}}></div>
                          <span style={{flex: "0 0 auto", padding: "0px 0.4em"}}>{"OR"}</span>
                          <div style={{height: "1px", width: "50%", flex: "2 1 auto", backgroundColor: "#a5a8b0"}}></div>
                        </div>
                      </div>
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
              <div style={{margin: "10px 0px"}}>
                <input
                  type="text"
                  className={"application-input application-input-checkout"}
                  placeholder={"Name on card"}
                  autoCapitalize="words"
                  autoCorrect="false"
                  autoComplete="cc-name"
                  spellCheck={false}
                  value={nameOnCard}
                  disabled={isProcessingPayment}
                  onChange={(e) => this.handleUpdateNameOnCard(e.target.value)}
                  onPaste={(e) => this.handleUpdateNameOnCard(e.target.value)}
                />
              </div>
              <div className={"application-input"} style={{height: 22, padding: "12px 8px", textRendering: "geometricPrecision"}}>
                <CardElement
                  onReady={() => this.handleOnReady()}
                  onChange={e => {
                    this.handleUpdatedCompletionStatus(e.complete)
                  }}
                  options={{
                    style: {
                      base: {
                        fontSize: "18px",
                        fontFamily: 'Futura, sans-serif',
                        fontSmoothing: "antialiased",
                        color: '#000000',
                        '::placeholder': {
                          color: '#BABDC6',
                        },
                        backgroundColor: "#FFFFFF"
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    }
                  }}
                />
              </div>
              <button
                type="submit"
                style={{display: "block", width: "100%", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(5.65vw, 25px)", marginTop: "2vh", color: (isProcessingPayment || (paymentStatus === REQUEST_SUCCESS)) ? "rgba(255, 255, 255, 0.4)" : "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "15px 18px", border: "none"}}
                disabled={!stripe || !readyForCheckout || (paymentStatus === REQUEST_SUCCESS)}
              >
                {checkoutButtonText}
              </button>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"fade-in"}
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  key={paymentStatus}
                >
                  <div>
                    {(paymentStatus === REQUEST_ERROR) && (
                      <ProgramResultsPanel
                        backgroundColor={"#ffb4b4"}
                        boxShadow={"0px 0px 1px 1px red"}
                        marginTop={"2vh"}
                        title={"PAYMENT ERROR"}
                        description={paymentFailureMessage}
                      />
                    )}
                    {(paymentStatus === REQUEST_SUCCESS) && (
                      <div style={{marginTop: "2vh", padding: "max(3vw, 16px)", backgroundColor: "#ffffff", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                          <div style={{display: "inline-block", fontFamily: "Futura", fontSize: "min(5vw, 22px)"}}>
                            {"PURCHASE SUCCESSFUL"}
                          </div>
                        </div>
                        <div style={{paddingTop: "max(2vw, 10px)"}}>
                          <span style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)"}}>
                            {successMessage}
                          </span>
                        </div>
                      </div>
                    )}
                    {(paymentStatus === REQUEST_FETCHING) && (
                      <ProgramResultsPanel
                        backgroundColor={"#FFFFFF"}
                        marginTop={"2vh"}
                        title={"PROCESSING PAYMENT"}
                        description={"Your payment is being processed securely through Stripe..."}
                      />
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const StripePromise = loadStripe(StripePublishableKey)


class StripeCheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    // this.state = {
    //   StripePromise: loadStripe(StripePublishableKey)
    // }
    
  }

  render() {
    // const {StripePromise} = this.state

    const {clientSecret, priceUsdCents, productName, pixelProductId, trainerEmailAddress} = this.props
    return (
      <Elements options={{fonts: NEUROFIT_FONTS}} stripe={StripePromise}>
        <ElementsConsumer>
          {({elements, stripe}) => (
            <CheckoutForm
              elements={elements}
              stripe={stripe}
              clientSecret={clientSecret}
              priceUsdCents={priceUsdCents}
              productName={productName}
              pixelProductId={pixelProductId}
              trainerEmailAddress={trainerEmailAddress}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
};

export default StripeCheckoutForm;