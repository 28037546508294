import { call, put, all, select, takeLatest, takeLeading, take, debounce, cancel } from 'redux-saga/effects'
import agent from './agent'

import {
  isProduction,
  recordPurchaseFunnelEventGA,
  recordFunnelEventGA,
  recordFunnelEventResultGA,
  currentTime,
  time,
  getCsrfTokenFromCookies,
  getAppleNonceTokenFromCookies,
  getTimeZoneOffset,
  getIntSecondsSinceTime,
  delay,
  getRandomInt,
  isTodayUnix,
  shouldAutoRefreshApp,
  resetAnalyticsUser,
  reloadApp,
  getDaysSinceTimestamp,
  ProductionBaseUrl,
  AppName,
  AppDescription,
  ProductionSupportBaseUrl,
  ProductionCertificationUrl,
  ProductionTrainingRegistrationUrl,
  baseLegalUrl,
  setAnalyticsUserProperty,
  getLocalDaysSinceTimestamp,
  currentTimeUnix,
  captureSentryException,
} from './utils';

import {
  scoreCheckInState,
  joinWithAnd,
} from './appUtils'

import {history} from './history';

import range from 'lodash/range'
import mean from 'lodash/mean'

import {
  COMMON_TRIGGER_REQUEST,
  LOGIN_PAGE_TRIGGER_REQUEST,
  DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST,
} from './constants/actionTypes';

import {
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ_DASHBOARD_FETCH_APP_CONFIG,
  REQ_DASHBOARD_CREATE_ACCOUNT,
  REQ_DASHBOARD_LOGIN_USER,
  REQ_DASHBOARD_SEND_RESET_PASSWORD_LINK,
  REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_DASHBOARD_SAVE_NEW_PASSWORD,
  REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE,
  REQ_DASHBOARD_UPDATE_AFFILIATE_CODE,
  REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL,
  REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL,
  REQ_DASHBOARD_FETCH_SESSION_LIST,
  REQ_DASHBOARD_FETCH_SESSION_DETAILS,
  REQ_DASHBOARD_UPDATE_SESSION_DETAILS,
  REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN,
  REQ_DASHBOARD_FETCH_LEAD_LIST,
  REQ_DASHBOARD_FETCH_LEAD_DETAILS,
  REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT,
  REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY,
  REQ_DASHBOARD_REMOVE_CLIENT_LEAD,
  REQ_DASHBOARD_FETCH_CLIENT_LIST,
  REQ_DASHBOARD_FETCH_CLIENT_DETAILS,
  REQ_DASHBOARD_REMOVE_CLIENT,
  REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL,
  REQ_DASHBOARD_SEND_CLIENT_PUSH_NOTIFICATION,
  REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT,
  REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY,
  REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS,
  REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN,
  REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL,
  REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES,
  REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE,
  REQ_DASHBOARD_GET_SIGNED_UPLOAD_PROFILE_IMAGE_URL,
  REQ_DASHBOARD_UPLOAD_USER_PROFILE_IMAGE,
  REQ_DASHBOARD_LOGOUT_USER,
} from './constants/requestTypes'

import {
  FORM_FIELD_FIRST_NAME,
  FORM_FIELD_LAST_NAME,
  FORM_FIELD_OCCUPATION,
  FORM_FIELD_PROFESSIONAL_URL,
  FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY,
  FORM_FIELD_SHOW_TOP_ERROR_MESSAGE,
  FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE,
  FORM_FIELD_TOP_ERROR_MESSAGE_VALUE,
  FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE,
  FORM_FIELD_EMAIL_ADDRESS,
  FORM_FIELD_PASSWORD,
  FORM_FIELD_AFFILIATE_CODE,
  FORM_FIELD_USER_PROFILE_IMAGE_FILE,
  FORM_FIELD_CLIENT_TESTIMONIAL_TEXT,
  FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME,
} from './constants/formFields'

import {
  DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID,
  DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID,
  DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID,
  DASHBOARD_PAGE_STATE_TOP_HABIT,
  DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT,
  DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT,
  DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT,
} from './constants/pageState'

import {
  commonTriggerRequest,
  commonUpdateRequestState,
  commonUpdateInAppPurchaseProducts,
  commonUpdateInAppPurchaseProductsLoaded,
  commonUpdateCameraPermissions,
  commonUpdateDeepLinkedArticleKey,
  commonUpdateNativeCompletionSoundPreloaded,
  commonUpdateAppActionItemType,
  commonUpdateIsDeeplinkedPaywallAction,
  commonUpdateIsDeeplinkedDiscoveryQuizAction,
  commonUpdateSessionDeploymentConfig,
} from './actions/common'

import {
  quizPageUpdateRequestState,
} from './actions/quizPage'

import {
  certificationVideoPageUpdateRequestState,
} from './actions/certificationVideoPage'

import {
  trainingRegistrationPageUpdateRequestState,
} from './actions/trainingRegistrationPage'

import {
  loginPageUpdateRequestState,
  updateFormField,
  loginPageToggleModal,
} from './actions/loginPage'

import {
  dashboardLoggedInPageUpdateRequestState,
  dashboardLoggedInPageUpdateFormField,
  dashboardLoggedInPageUpdatePageState,
  dashboardLoggedInPageTriggerRequest,
} from './actions/dashboardLoggedInPage'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from './constants/requestStates'

import {
  HRV_CAPTURE_STATE_DATA,
  HRV_CAPTURE_STATE_FRAME_DROP_COMPLIANCE_ERROR_COUNT,
  HRV_CAPTURE_STATE_TASK_PROGRESS,
  HRV_CAPTURE_STATE_TASK_MAX_PROGRESS,
} from './constants/hrvCaptureState'

import {
  APP_ACTION_TYPE_LEARN,
  APP_ACTION_TYPE_MEASURE,
  APP_ACTION_TYPE_BALANCE,
  APP_ACTION_TYPE_CHECKIN,
  APP_ACTION_TYPE_RATE_APP,
} from './constants/appActionTypes'

import {
  COMMON_LOGIN_TOKEN,
  COMMON_RESET_PASSWORD_TOKEN,
  COMMON_CLIENT_TESTIMONIAL_TOKEN,
  COMMON_APP_PLATFORM,
  COMMON_APP_LOADED_AT,
  COMMON_DEPLOYMENT_CONFIG,
  COMMON_CURRENT_APP_VERSION,
} from './constants/commonState'

import {
  GA_LABEL_TRIGGERED_CREATE_ACCOUNT,
  GA_LABEL_CREATED_ACCOUNT,
  GA_LABEL_CHECKOUT_OPENED_SUBSCRIPTION_MODAL_DEEPLINK,
  GA_LABEL_TRIGGERED_CREATE_SUBSCRIPTION,
  GA_LABEL_CREATED_SUBSCRIPTION,
  GA_LABEL_CHECKOUT_SELECTED_PRODUCT,
  GA_LABEL_CHECKOUT_APPROVED_PRODUCT,
  GA_LABEL_CHECKOUT_CONSUMED_PRODUCT,
  GA_LABEL_CANCELED_SUBSCRIPTION,
  GA_LABEL_USER_VALIDATED_LOGIN_EMAIL,
  GA_LABEL_USER_LOGGED_IN,
  GA_LABEL_USER_LOGGED_OUT,
  GA_LABEL_ENABLED_FREE_PREMIUM_ACCESS,
  GA_LABEL_REGISTERED_NOTIFICATIONS_TRUE,
  GA_LABEL_REGISTERED_NOTIFICATIONS_FALSE,
  GA_LABEL_COMPLETED_ONBOARDING,
  GA_LABEL_PERSONALIZE_PREFERENCES_COMPLETED,
  GA_LABEL_COMPLETED_SAVE_SELF_CHECK_IN_RESULT,
  GA_LABEL_COMPLETED_SAVE_EXERCISE_RESULT,
  GA_LABEL_REQUESTED_APP_REVIEW,

  GA_LABEL_OPENED_SELF_CHECK_IN_MODAL_APP_ACTION,
  GA_LABEL_OPENED_MEASURE_HRV_MODAL_APP_ACTION,
  GA_LABEL_OPENED_BALANCE_TRAINING_MODAL_APP_ACTION,
  GA_LABEL_OPENED_LEARN_ARTICLE_DIRECT_LINK,

  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_TRIGGERED,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_CREATED_UPLOAD_URL,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_UPLOADED_HRV_DATA,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_CREATED_ANALYSIS_TASK,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_COMPLETED,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_UPLOAD_URL,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_UPLOAD_READING_DATA,
  GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_CREATE_ANALYSIS_TASK,


  GA_LABEL_BALANCE_TRAINING_MODAL_LOADED_RECOMMENDATIONS,
  GA_LABEL_BALANCE_TRAINING_MODAL_SAVED_RESULTS,

  GA_LABEL_LEARN_MODAL_LOADED_ARTICLE_RECOMMENDATIONS,
  GA_LABEL_LEARN_MODAL_COMPLETED_ARTICLE_SEARCH,
  GA_LABEL_LEARN_MODAL_LOADED_ARTICLE_METADATA,
  GA_LABEL_LEARN_MODAL_COMPLETED_START_ARTICLE,
  GA_LABEL_LEARN_MODAL_COMPLETED_READ_ARTICLE,
  GA_LABEL_LEARN_MODAL_COMPLETED_RATE_ARTICLE,
  GA_LABEL_LEARN_MODAL_COMPLETED_SHARE_ARTICLE,
  GA_LABEL_LEARN_MODAL_COMPLETED_ONBOARDING_ARTICLE,

  GA_LABEL_OPENED_DISCOVERY_QUIZ_MODAL_DEEPLINK,

  GA_LABEL_QUIZ_COMPLETED_SAVE_RESULTS,
  GA_LABEL_QUIZ_RESULTS,

  GA_LABEL_INSIGHTS_KEY_TAKEAWAYS_MEASUREMENT,
  GA_LABEL_PERSONALIZED_ONBOARDING_PREFERENCES,

  GA_LABEL_REGISTER_LIVE_TRAINING_OPT_IN_COMPLETED,
} from './constants/gaEventLabels'

import { Device } from '@capacitor/device';

const semverValid = require('semver/functions/valid')
const semverGt = require('semver/functions/gt')
const semverCoerce = require('semver/functions/coerce')

const DELAY_FIVE_SECONDS_MS = 5000;
const DELAY_MS = 3000;
const TINY_DELAY_MS = 500;
const DELAY_250_MS = 250;
export const HEARTBEAT_DELAY_MS = 250;
const QUITE_SHORT_DELAY_MS = 1000
const SHORT_DELAY_MS = 1500;
const FLASH_ERROR_MESSAGE_DURATION = 3000
const FLASH_SUCCESS_MESSAGE_DURATION = 2500
const POLLING_DELAY_MS = 3000
const LOGIN_CODE_DELAY_MS = 15000
const HRV_ANALYSIS_TIMEOUT_SECONDS = 30
export const DEPLOYMENT_CONFIG_ENDPOINT = "/config/deployment.json"
export const DEPLOYMENT_UPDATE_LOCAL_STORAGE_KEY = "neurofit_deployment_update_config"
export const DAILY_CHECK_IN_NOTIFICATION_TIME_LOCAL_STORAGE_KEY = "neurofit_check_in_notification_time"
const MIN_CLIENT_TESTIMONIAL_LENGTH = 50


let I18N = {}
const getLocalizedErrorMessageFromRequestResult = (req) => {
  return !!(req.error) ? ((!!I18N && !!(I18N[req.error.error_code])) ? I18N[req.error.error_code] :(`Error: ${req.error.title}`)) : ""
}

///////////////
// Selectors //
///////////////

// Login Page
const getFirstNameFromLoginState = state => state.loginPage.formFields[FORM_FIELD_FIRST_NAME]
const getLastNameFromLoginState = state => state.loginPage.formFields[FORM_FIELD_LAST_NAME]
const getOccupationFromLoginState = state => state.loginPage.formFields[FORM_FIELD_OCCUPATION]
const getProfessionalUrlFromLoginState = state => state.loginPage.formFields[FORM_FIELD_PROFESSIONAL_URL]
const getPayoutsDestinationCountryCodeFromLoginState = state => state.loginPage.formFields[FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY]
const getEmailAddressFromLoginState = state => state.loginPage.formFields[FORM_FIELD_EMAIL_ADDRESS]
const getPasswordFromLoginState = state => state.loginPage.formFields[FORM_FIELD_PASSWORD]
const getTestimonialTextFromLoginState = state => state.loginPage.formFields[FORM_FIELD_CLIENT_TESTIMONIAL_TEXT]
const getTestimonialIncludeClientNameFromLoginState = state => state.loginPage.formFields[FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME]

const getDashboardCreateAccountResultFromLoginState = state => state.loginPage.requestResults[REQ_DASHBOARD_CREATE_ACCOUNT]
const getDashboardLoginUserResultFromLoginState = state => state.loginPage.requestResults[REQ_DASHBOARD_LOGIN_USER]
const getDashboardSendResetPasswordLinkResultFromLoginState = state => state.loginPage.requestResults[REQ_DASHBOARD_SEND_RESET_PASSWORD_LINK]
const getDashboardSaveNewPasswordResultFromLoginState = state => state.loginPage.requestResults[REQ_DASHBOARD_SAVE_NEW_PASSWORD]
const getDashboardSaveClientTestimonialResultFromLoginState = state => state.loginPage.requestResults[REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL]
const getDashboardLogoutUserResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_LOGOUT_USER]

// Dashboard Logged In Page
const getDashboardCreatePayoutsOnboardingUrlRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL]
const getDashboardCreatePayoutsLoginUrlRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL]
const getDashboardFetchAffiliateProfileRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE]
const getDashboardSaveUpdatedAffiliateCodeRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_UPDATE_AFFILIATE_CODE]
const getDashboardFetchSessionListRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_SESSION_LIST]
const getDashboardFetchClientListRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_CLIENT_LIST]
const getDashboardFetchClientDetailsRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_CLIENT_DETAILS]
const getDashboardRemoveClientRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_REMOVE_CLIENT]

const getDashboardFetchLeadListRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_LEAD_LIST]
const getDashboardFetchLeadDetailsRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_LEAD_DETAILS]
const getDashboardGenerateClientLeadResponseDraftRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT]
const getDashboardSendClientLeadReplyRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY]
const getDashboardRemoveClientLeadRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_REMOVE_CLIENT_LEAD]

const getDashboardRequestClientTestimonialRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL]
const getDashboardSaveProfileLinkUpdatesRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES]
const getDashboardFetchClientSessionPlanDetailsRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_SESSION_DETAILS]
const getDashboardUpdateClientSessionPlanDetailsRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_UPDATE_SESSION_DETAILS]
const getDashboardRefundClientSessionPlanRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN]

const getProfileImageFileFromLoggedInState = state => state.dashboardLoggedInPage.formFields[FORM_FIELD_USER_PROFILE_IMAGE_FILE]
const getUserSignedUploadProfileImageUrlResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_GET_SIGNED_UPLOAD_PROFILE_IMAGE_URL]
const getUpdateUserProfileImageResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_UPLOAD_USER_PROFILE_IMAGE]

const getDashboardCreateAppPrePurchasePaymentIntentRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT]
const getDashboardFetchGiftAppHistoryRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY]
const getDashboardUpdateGiftAppDetailsRequestResultFromLoggedInState = state => state.dashboardLoggedInPage.requestResults[REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS]


const getAffiliateCodeFromDashboardLoggedInState = state => state.dashboardLoggedInPage.formFields[FORM_FIELD_AFFILIATE_CODE]
const getDashboardClientUserIdFromLoggedInPageState = state => state.dashboardLoggedInPage.pageState[DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID]
const getDashboardClientSessionPlanIdFromLoggedInPageState = state => state.dashboardLoggedInPage.pageState[DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID]
const getDashboardClientLeadIdFromLoggedInPageState = state => state.dashboardLoggedInPage.pageState[DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID]

// Common
const getResetPasswordTokenFromCommonState = state => state.common[COMMON_RESET_PASSWORD_TOKEN]
const getClientTestimonialTokenFromCommonState = state => state.common[COMMON_CLIENT_TESTIMONIAL_TOKEN]
const getSessionDeploymentConfigFromCommonState = state => state.common[COMMON_DEPLOYMENT_CONFIG]
const getDashboardAppConfigResultFromCommonState = state => state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result
const getDashboardUserDataFromCommonState = state => state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result && state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.user_data

//////////
// Util //
//////////
function* baseRequestWorker(action, reqFunc, reqParams, gaSuccessEventLabel, updateRequestState, gaTriggeredEventLabel, fetchDelayMs) {
  const reqType = action.payload.key;
  try {
    if (gaTriggeredEventLabel) {
      recordFunnelEventGA(gaTriggeredEventLabel)
    }
    yield put(updateRequestState(reqType, REQUEST_FETCHING))
    if (fetchDelayMs > 0) {
      yield delay(fetchDelayMs)
    }
    const result = yield call(reqFunc, reqParams)
    yield put(updateRequestState(reqType, REQUEST_SUCCESS, result))
    if (gaSuccessEventLabel) {
      recordFunnelEventGA(gaSuccessEventLabel)
    }
  } catch (err) {
    yield put(updateRequestState(reqType, REQUEST_ERROR, err))
  }
}

function* flashTopErrorMessage(updateStateFunction, errorMessage) {
  yield put(updateStateFunction(FORM_FIELD_TOP_ERROR_MESSAGE_VALUE, errorMessage))
  yield put(updateStateFunction(FORM_FIELD_SHOW_TOP_ERROR_MESSAGE, true))
  yield delay(FLASH_ERROR_MESSAGE_DURATION)
  yield put(updateStateFunction(FORM_FIELD_SHOW_TOP_ERROR_MESSAGE, false))
}

function* flashBottomSuccessMessage(updateStateFunction, message) {
  yield put(updateStateFunction(FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE, message))
  yield put(updateStateFunction(FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE, true))
  yield delay(FLASH_SUCCESS_MESSAGE_DURATION)
  yield put(updateStateFunction(FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE, false))
}

async function fetchDeploymentConfig() {
  const result = await fetch(DEPLOYMENT_CONFIG_ENDPOINT, {method: "GET"})
  return await result.json()
}

////////////////////////////////
// Fetch Dashboard App Config //
////////////////////////////////
function* fetchDashboardAppConfigWorker(action) {
  try {
    const tz = getTimeZoneOffset()

    yield baseRequestWorker(
      action,
      agent.NeuroFitDashboardBackend.dashboardFetchAppConfig,
      {tz},
      undefined,
      commonUpdateRequestState,
    )

    const appConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    I18N = appConfigResult.localization
    const userData = yield select(getDashboardUserDataFromCommonState)
    if (!!(userData)) {
      yield fetchDeploymentConfigWorker()

      yield put(dashboardLoggedInPageUpdateFormField(FORM_FIELD_AFFILIATE_CODE, userData.affiliate_code))
    }
  } catch (e) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, e)
  }
}
function* fetchDashboardAppConfigSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_APP_CONFIG, fetchDashboardAppConfigWorker);
}

/////////////////////////////
// Fetch Deployment Config //
/////////////////////////////
function* fetchDeploymentConfigWorker() {
  try {
    yield put(commonUpdateRequestState(REQ_FETCH_DEPLOYMENT_CONFIG, REQUEST_FETCHING))
    const updatedDeploymentConfig = yield fetchDeploymentConfig()
    yield put(commonUpdateRequestState(REQ_FETCH_DEPLOYMENT_CONFIG, REQUEST_SUCCESS, updatedDeploymentConfig))

    const userData = yield select(getDashboardUserDataFromCommonState)

    // Check Local Storage for existing deployment config.
    // If not exists, save it.
    // If exists, compare to new deployment config. If new deployment timestamp > existing, show update prompt.
    const currentSessionDeploymentConfig = yield select(getSessionDeploymentConfigFromCommonState)
    if (!currentSessionDeploymentConfig) {
      yield put(commonUpdateSessionDeploymentConfig(updatedDeploymentConfig))
    } else {
      const newTimestamp = new Date(updatedDeploymentConfig.timestamp).getTime()
      const oldTimestamp = new Date(currentSessionDeploymentConfig.timestamp).getTime()
      if ((newTimestamp > oldTimestamp) && (!!(userData)) && !!(updatedDeploymentConfig.force_upgrade)) {
        reloadApp()
        // yield put(loggedInPageToggleModal(MODAL_FORCE_REFRESH_APP, true))
      }
    }

  } catch (err) {
    console.log(`${err}`)
    yield put(commonUpdateRequestState(REQ_FETCH_DEPLOYMENT_CONFIG, REQUEST_ERROR, err))
  }
}
function* fetchDeploymentConfigSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_FETCH_DEPLOYMENT_CONFIG, fetchDeploymentConfigWorker)
}

//////////////////////////////
// Create Dashboard Account //
//////////////////////////////

function* createDashboardAccountWorker(action) {
  const first_name = yield select(getFirstNameFromLoginState)
  const last_name = yield select(getLastNameFromLoginState)
  const occupation = yield select(getOccupationFromLoginState)
  const professional_url = yield select(getProfessionalUrlFromLoginState)
  const payouts_destination_country_code = yield select(getPayoutsDestinationCountryCodeFromLoginState)
  const email_address = yield select(getEmailAddressFromLoginState)
  const password = yield select(getPasswordFromLoginState)
  const device_uuid = (yield Device.getId()).identifier



  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardCreateAccount,
    {first_name, last_name, occupation, professional_url, payouts_destination_country_code, email_address, password, device_uuid},
    undefined,
    loginPageUpdateRequestState,
    undefined
  )

  const requestResult = yield select(getDashboardCreateAccountResultFromLoginState)
  if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    window.location = "/app/affiliate"
  } else if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* createDashboardAccountSaga() {
  yield takeLatest(LOGIN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_CREATE_ACCOUNT, createDashboardAccountWorker);
}


//////////////////////////
// Login Dashboard User //
//////////////////////////
function* loginDashboardUserWorker(action) {
  const email_address = yield select(getEmailAddressFromLoginState)
  const password = yield select(getPasswordFromLoginState)
  const device_uuid = (yield Device.getId()).identifier
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardLoginUser,
    {email_address, password, device_uuid},
    undefined,
    loginPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardLoginUserResultFromLoginState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    window.location = "/app/affiliate"
  }
}
function* loginDashboardUserSaga() {
  yield takeLatest(LOGIN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_LOGIN_USER, loginDashboardUserWorker);
}


////////////////////////////////////////
// Dashboard Send Reset Password Link //
////////////////////////////////////////
function* dashboardSendResetPasswordLinkWorker(action) {
  const email_address = yield select(getEmailAddressFromLoginState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardSendResetPasswordLink,
    {email_address},
    undefined,
    loginPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardSendResetPasswordLinkResultFromLoginState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield flashBottomSuccessMessage(updateFormField, "Sent reset password link")
  }
}
function* dashboardSendResetPasswordLinkSaga() {
  yield takeLatest(LOGIN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_SEND_RESET_PASSWORD_LINK, dashboardSendResetPasswordLinkWorker);
}

/////////////////////////////////////////////
// Dashboard Validate Reset Password Token //
/////////////////////////////////////////////
function* dashboardValidateResetPasswordTokenWorker(action) {
  const reset_password_token = yield select(getResetPasswordTokenFromCommonState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardValidateResetPasswordToken,
    {reset_password_token},
    undefined,
    commonUpdateRequestState,
  )
}
function* dashboardValidateResetPasswordTokenSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN, dashboardValidateResetPasswordTokenWorker);
}

/////////////////////////////////
// Dashboard Save New Password //
/////////////////////////////////
function* dashboardSaveNewPasswordWorker(action) {
  const reset_password_token = yield select(getResetPasswordTokenFromCommonState)
  const new_password = yield select(getPasswordFromLoginState)
  const device_uuid = (yield Device.getId()).identifier

  if (new_password.length < 8) {
    yield flashTopErrorMessage(updateFormField, "Error: Password must be at least 8 characters long")
    return
  }

  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardSaveNewPassword,
    {reset_password_token, new_password, device_uuid},
    undefined,
    loginPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardSaveNewPasswordResultFromLoginState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    window.location = "/app/affiliate"
  }
}
function* dashboardSaveNewPasswordSaga() {
  yield takeLatest(LOGIN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_SAVE_NEW_PASSWORD, dashboardSaveNewPasswordWorker);
}

///////////////////////////////////////
// Dashboard Fetch Affiliate Profile //
///////////////////////////////////////
function* dashboardFetchAffiliateProfileWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchAffiliateProfile,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchAffiliateProfileRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_SUCCESS && (!!(requestResult.result.profile_data.completed_stripe_connect_onboarding))) {
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    dashboardAppConfigResult.user_data.completed_stripe_connect_onboarding = true
    yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))
  }
}
function* dashboardFetchAffiliateProfileSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE, dashboardFetchAffiliateProfileWorker);
}

///////////////////////////////////////////
// Dashboard Save Updated Affiliate Code //
///////////////////////////////////////////
function* dashboardSaveUpdatedAffiliateCodeWorker(action) {
  const updated_affiliate_code = yield select(getAffiliateCodeFromDashboardLoggedInState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardUpdateAffiliateCode,
    {updated_affiliate_code},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardSaveUpdatedAffiliateCodeRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_SUCCESS) {
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    dashboardAppConfigResult.user_data.affiliate_code = updated_affiliate_code
    yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))
  }
}
function* dashboardSaveUpdatedAffiliateCodeSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_UPDATE_AFFILIATE_CODE, dashboardSaveUpdatedAffiliateCodeWorker);
}

/////////////////////////////////////////////
// Dashboard Create Payouts Onboarding URL //
/////////////////////////////////////////////
function* dashboardCreatePayoutsOnboardingUrlWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardCreatePayoutsOnboardingUrl,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardCreatePayoutsOnboardingUrlRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    window.location = requestResult.result.payouts_onboarding_link
  }
}
function* dashboardCreatePayoutsOnboardingUrlSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL, dashboardCreatePayoutsOnboardingUrlWorker);
}

////////////////////////////////////////
// Dashboard Create Payouts Login URL //
////////////////////////////////////////
function* dashboardCreatePayoutsLoginUrlWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardCreatePayoutsLoginUrl,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardCreatePayoutsLoginUrlRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    window.location = requestResult.result.payouts_login_link
  }
}
function* dashboardCreatePayoutsLoginUrlSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL, dashboardCreatePayoutsLoginUrlWorker);
}

/////////////////////////////////
// Dashboard Fetch Client List //
/////////////////////////////////
function* dashboardFetchClientListWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchClientList,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchClientListRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    // If Quiz Onboarding Not Completed + Lead List length > 0, mark Quiz Onboarding Completed.
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    if (dashboardAppConfigResult.user_data.onboarded_client_count === 0) {
      dashboardAppConfigResult.user_data.onboarded_client_count = Math.max(dashboardAppConfigResult.user_data.onboarded_client_count, requestResult.result.client_previews.length)
      yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))
    }
  }
}
function* dashboardFetchClientListSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_CLIENT_LIST, dashboardFetchClientListWorker);
}

////////////////////////////////////
// Dashboard Fetch Client Details //
////////////////////////////////////
function* dashboardFetchClientDetailsWorker(action) {
  const client_user_id = yield select(getDashboardClientUserIdFromLoggedInPageState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchClientDetails,
    {client_user_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchClientDetailsRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield computeKeyInsightsForSelectedClientIfAvailable()
  }
}
function* dashboardFetchClientDetailsSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_CLIENT_DETAILS, dashboardFetchClientDetailsWorker);
}

/////////////////////////////
// Dashboard Remove Client //
/////////////////////////////
function* dashboardRemoveClientWorker(action) {
  const client_user_id = yield select(getDashboardClientUserIdFromLoggedInPageState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardRemoveClient,
    {client_user_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardRemoveClientRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(TINY_DELAY_MS)
    yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID, undefined))
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_FETCH_CLIENT_DETAILS, REQUEST_UNSTARTED, {}))
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_REMOVE_CLIENT, REQUEST_UNSTARTED, {}))
    history.push("/app/clients")
    yield put(dashboardLoggedInPageTriggerRequest(REQ_DASHBOARD_FETCH_CLIENT_LIST))
  }
}
function* dashboardRemoveClientSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_REMOVE_CLIENT, dashboardRemoveClientWorker);
}


//////////////////////////////////
// Dashboard Fetch Session List //
//////////////////////////////////
function* dashboardFetchSessionListWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchSessionList,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchSessionListRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {

  }
}
function* dashboardFetchSessionListSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_SESSION_LIST, dashboardFetchSessionListWorker);
}

/////////////////////////////////////////////////
// Dashboard Fetch Client Session Plan Details //
/////////////////////////////////////////////////
function* dashboardFetchClientSessionPlanDetailsWorker(action) {
  const client_session_plan_id = yield select(getDashboardClientSessionPlanIdFromLoggedInPageState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchClientSessionPlanDetails,
    {client_session_plan_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchClientSessionPlanDetailsRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* dashboardFetchClientSessionPlanDetailsSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_SESSION_DETAILS, dashboardFetchClientSessionPlanDetailsWorker);
}

//////////////////////////////////////////////////
// Dashboard Update Client Session Plan Details //
//////////////////////////////////////////////////
function* dashboardUpdateClientSessionPlanDetailsWorker(action) {
  const requestBody = (yield select(getDashboardUpdateClientSessionPlanDetailsRequestResultFromLoggedInState)).result
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardUpdateClientSessionPlanDetails,
    requestBody,
    undefined,
    dashboardLoggedInPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getDashboardUpdateClientSessionPlanDetailsRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    yield put(dashboardLoggedInPageTriggerRequest(REQ_DASHBOARD_FETCH_SESSION_DETAILS))
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_UPDATE_SESSION_DETAILS, REQUEST_UNSTARTED, {}))
  }
}
function* dashboardUpdateClientSessionPlanDetailsSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_UPDATE_SESSION_DETAILS, dashboardUpdateClientSessionPlanDetailsWorker);
}

//////////////////////////////////////////
// Dashboard Refund Client Session Plan //
//////////////////////////////////////////
function* dashboardRefundClientSessionPlanWorker(action) {

  const client_session_plan_id = yield select(getDashboardClientSessionPlanIdFromLoggedInPageState)
  
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardRefundClientSessionPlan,
    {client_session_plan_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getDashboardRefundClientSessionPlanRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    yield put(dashboardLoggedInPageTriggerRequest(REQ_DASHBOARD_FETCH_SESSION_DETAILS))
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN, REQUEST_UNSTARTED, {}))
  }
}
function* dashboardRefundClientSessionPlanSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN, dashboardRefundClientSessionPlanWorker);
}



//////////////////////////////////////
// Dashboard Fetch Client Lead List //
//////////////////////////////////////
function* dashboardFetchClientLeadListWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchClientLeadList,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchLeadListRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    // If Quiz Onboarding Not Completed + Lead List length > 0, mark Quiz Onboarding Completed.
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    if (dashboardAppConfigResult.user_data.client_leads_count === 0) {
      dashboardAppConfigResult.user_data.client_leads_count = Math.max(dashboardAppConfigResult.user_data.client_leads_count, requestResult.result.current_lead_previews.length)
      dashboardAppConfigResult.user_data.outstanding_client_leads_count = requestResult.result.current_lead_previews.length
      yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))
    }
  }
}
function* dashboardFetchClientLeadListSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_LEAD_LIST, dashboardFetchClientLeadListWorker);
}

/////////////////////////////////////////
// Dashboard Fetch Client Lead Details //
/////////////////////////////////////////
function* dashboardFetchClientLeadDetailsWorker(action) {
  const client_lead_id = yield select(getDashboardClientLeadIdFromLoggedInPageState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchClientLeadDetails,
    {client_lead_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchLeadDetailsRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* dashboardFetchClientLeadDetailsSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_LEAD_DETAILS, dashboardFetchClientLeadDetailsWorker);
}

///////////////////////////////////////////////////
// Dashboard Generate Client Lead Response Draft //
///////////////////////////////////////////////////
function* dashboardGenerateClientLeadResponseDraftWorker(action) {
  const client_lead_id = yield select(getDashboardClientLeadIdFromLoggedInPageState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardGenerateClientLeadResponseDraft,
    {client_lead_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardGenerateClientLeadResponseDraftRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* dashboardGenerateClientLeadResponseDraftSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT, dashboardGenerateClientLeadResponseDraftWorker);
}

//////////////////////////////////////
// Dashboard Send Client Lead Reply //
//////////////////////////////////////
function* dashboardSendClientLeadReplyWorker(action) {
  const requestBody = (yield select(getDashboardSendClientLeadReplyRequestResultFromLoggedInState)).result
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardSendClientLeadReply,
    requestBody,
    undefined,
    dashboardLoggedInPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getDashboardSendClientLeadReplyRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    // Decrement Outstanding Leads Count + Increment Replied Leads Count.
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    dashboardAppConfigResult.user_data.outstanding_client_leads_count = Math.max(dashboardAppConfigResult.user_data.outstanding_client_leads_count - 1, 0)
    dashboardAppConfigResult.user_data.replied_client_leads_count = Math.min(dashboardAppConfigResult.user_data.replied_client_leads_count + 1, dashboardAppConfigResult.user_data.client_leads_count)
    yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))

    yield delay(QUITE_SHORT_DELAY_MS)
    let leadDetailsResultObject = (yield select(getDashboardFetchLeadDetailsRequestResultFromLoggedInState)).result
    leadDetailsResultObject.client_lead_details.trainer_response_text = requestBody.trainer_response_text
    leadDetailsResultObject.client_lead_details.trainer_response_sent = true
    leadDetailsResultObject.client_lead_details.trainer_response_sent_at_unix = currentTimeUnix()
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_FETCH_LEAD_DETAILS, REQUEST_SUCCESS, leadDetailsResultObject))
  }
}
function* dashboardSendClientLeadReplySaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY, dashboardSendClientLeadReplyWorker);
}

//////////////////////////////////
// Dashboard Remove Client Lead //
//////////////////////////////////
function* dashboardRemoveClientLeadWorker(action) {

  const client_lead_id = yield select(getDashboardClientLeadIdFromLoggedInPageState)
  
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardRemoveClientLead,
    {client_lead_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getDashboardRemoveClientLeadRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    // Decrement # Outstanding Leads Count.
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    dashboardAppConfigResult.user_data.outstanding_client_leads_count = Math.max(dashboardAppConfigResult.user_data.outstanding_client_leads_count - 1, 0)
    yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))

    yield delay(TINY_DELAY_MS)
    yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID, undefined))
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_REMOVE_CLIENT_LEAD, REQUEST_UNSTARTED, {}))
    yield put(dashboardLoggedInPageTriggerRequest(REQ_DASHBOARD_FETCH_LEAD_LIST))
    history.push("/app/leads")
  }
}
function* dashboardRemoveClientLeadSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_REMOVE_CLIENT_LEAD, dashboardRemoveClientLeadWorker);
}




/////////////////////////////////////////
// Dashboard Save Profile Link Updates //
/////////////////////////////////////////
function* dashboardSaveProfileLinkUpdatesWorker(action) {
  const requestBody = (yield select(getDashboardSaveProfileLinkUpdatesRequestResultFromLoggedInState)).result
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardSaveProfileLinkUpdates,
    requestBody,
    undefined,
    dashboardLoggedInPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getDashboardSaveProfileLinkUpdatesRequestResultFromLoggedInState)

  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    yield delay(QUITE_SHORT_DELAY_MS)
    yield put(commonTriggerRequest(REQ_DASHBOARD_FETCH_APP_CONFIG))
  }
}
function* dashboardSaveProfileLinkUpdatesSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES, dashboardSaveProfileLinkUpdatesWorker);
}

/////////////////////////////////////////
// Dashboard Save Profile Image Update //
/////////////////////////////////////////
function* dashboardSaveUpdatedProfileImageWorker(action) {

  yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE, REQUEST_FETCHING, {}))

  yield put(dashboardLoggedInPageTriggerRequest(REQ_DASHBOARD_GET_SIGNED_UPLOAD_PROFILE_IMAGE_URL))
}

function* dashboardSaveUpdatedProfileImageSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE, dashboardSaveUpdatedProfileImageWorker);
}


///////////////////////////////////////////////////
// Dashboard Get Signed Upload Profile Image Url //
///////////////////////////////////////////////////
function* dashboardGetSignedUploadProfileImageUrlWorker(action) {

  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardGetSignedUploadUserProfileImageUrl,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getUserSignedUploadProfileImageUrlResultFromLoggedInState)

  if (requestResult.state === REQUEST_ERROR) {
    // Dispatch action to show Error Modal.
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE, REQUEST_ERROR, {}))
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, "Updating profile image failed. Please try again.")
  } else {
    yield put(dashboardLoggedInPageTriggerRequest(REQ_DASHBOARD_UPLOAD_USER_PROFILE_IMAGE))
  }
}

function* dashboardGetSignedUploadProfileImageUrlSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_GET_SIGNED_UPLOAD_PROFILE_IMAGE_URL, dashboardGetSignedUploadProfileImageUrlWorker);
}


/////////////////////////////////////////
// Dashboard Save Profile Image Update //
/////////////////////////////////////////
function* dashboardUploadNewProfileImageWorker(action) {
  const requestResult = yield select(getUserSignedUploadProfileImageUrlResultFromLoggedInState)
  const profileImageFile = yield select(getProfileImageFileFromLoggedInState)
  const profileImageUrl = requestResult.result.profile_image_url
  const profileImageUploadSignedUrl = requestResult.result.profile_image_upload_signed_url

  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardUpdateUserProfileImage,
    {signed_url: profileImageUploadSignedUrl, profile_image_file: profileImageFile},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const uploadImageRequestResult = yield select(getUpdateUserProfileImageResultFromLoggedInState)

  if (uploadImageRequestResult.state === REQUEST_ERROR) {
    // Dispatch action to show Error Modal.
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE, REQUEST_ERROR, {}))
    yield flashTopErrorMessage(updateFormField, "Uploading profile image failed. Please try again.")
  } else {
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE, REQUEST_SUCCESS, uploadImageRequestResult.result))
    let dashboardAppConfigResult = yield select(getDashboardAppConfigResultFromCommonState)
    dashboardAppConfigResult.user_data.profile_image_version = requestResult.result.profile_image_version
    yield put(commonUpdateRequestState(REQ_DASHBOARD_FETCH_APP_CONFIG, REQUEST_SUCCESS, dashboardAppConfigResult))
  }
}

function* dashboardUploadNewProfileImageSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_UPLOAD_USER_PROFILE_IMAGE, dashboardUploadNewProfileImageWorker);
}



//////////////////////////////////////////
// Dashboard Request Client Testimonial //
//////////////////////////////////////////
function* dashboardRequestClientTestimonialWorker(action) {
  const client_user_id = yield select(getDashboardClientUserIdFromLoggedInPageState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardRequestClientTestimonial,
    {client_user_id},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardRequestClientTestimonialRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* dashboardRequestClientTestimonialSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL, dashboardRequestClientTestimonialWorker);
}

/////////////////////////////////////////////////
// Dashboard Validate Client Testimonial Token //
/////////////////////////////////////////////////
function* dashboardValidateClientTestimonialTokenWorker(action) {
  const client_testimonial_token = yield select(getClientTestimonialTokenFromCommonState)
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardValidateClientTestimonialToken,
    {client_testimonial_token},
    undefined,
    commonUpdateRequestState,
  )
}
function* dashboardValidateClientTestimonialTokenSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN, dashboardValidateClientTestimonialTokenWorker);
}

///////////////////////////////////////
// Dashboard Save Client Testimonial //
///////////////////////////////////////
function* dashboardSaveClientTestimonialWorker(action) {
  const client_testimonial_token = yield select(getClientTestimonialTokenFromCommonState)
  const testimonial_text = yield select(getTestimonialTextFromLoginState)
  const include_client_name = yield select(getTestimonialIncludeClientNameFromLoginState)

  if (testimonial_text.length < MIN_CLIENT_TESTIMONIAL_LENGTH) {
    yield flashTopErrorMessage(updateFormField, "Error: Testimonial must be at least 50 characters long")
    return
  }

  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardSaveClientTestimonial,
    {client_testimonial_token, testimonial_text, include_client_name},
    undefined,
    loginPageUpdateRequestState,
    undefined,
    QUITE_SHORT_DELAY_MS,
  )

  const requestResult = yield select(getDashboardSaveClientTestimonialResultFromLoginState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* dashboardSaveClientTestimonialSaga() {
  yield takeLatest(LOGIN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL, dashboardSaveClientTestimonialWorker);
}



/////////////////////////////////////////
// Dashboard Create App Payment Intent //
/////////////////////////////////////////
function* dashboardCreateAppPrePurchasePaymentIntentWorker(action) {
  const {client_first_name, client_email_address, months_app_access} = (yield select(getDashboardCreateAppPrePurchasePaymentIntentRequestResultFromLoggedInState)).result

  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardCreateAppPrePurchasePaymentIntent,
    {client_first_name, client_email_address, months_app_access},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardCreateAppPrePurchasePaymentIntentRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {

  }
}
function* dashboardCreateAppPrePurchasePaymentIntentSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT, dashboardCreateAppPrePurchasePaymentIntentWorker);
}

//////////////////////////////////////
// Dashboard Fetch App Gift History //
//////////////////////////////////////
function* dashboardFetchGiftAppHistoryWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardFetchAppGiftHistory,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardFetchGiftAppHistoryRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  }
}
function* dashboardFetchGiftAppHistorySaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY, dashboardFetchGiftAppHistoryWorker);
}

//////////////////////////////////////
// Dashboard Fetch App Gift History //
//////////////////////////////////////
function* dashboardUpdateGiftAppDetailsWorker(action) {
  const requestBody = (yield select(getDashboardUpdateGiftAppDetailsRequestResultFromLoggedInState)).result
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardUpdateAppGiftDetails,
    requestBody,
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardUpdateGiftAppDetailsRequestResultFromLoggedInState)
  if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(updateFormField, getLocalizedErrorMessageFromRequestResult(requestResult))
  } else if (requestResult.state === REQUEST_SUCCESS) {
    const appGiftHistoryRequestResultBody = (yield select(getDashboardFetchGiftAppHistoryRequestResultFromLoggedInState)).result
    appGiftHistoryRequestResultBody.app_gifts = requestResult.result.updated_app_gifts
    appGiftHistoryRequestResultBody.created_at_unix = requestResult.result.created_at_unix
    yield put(dashboardLoggedInPageUpdateRequestState(REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY, REQUEST_SUCCESS, appGiftHistoryRequestResultBody))
  }
}
function* dashboardUpdateGiftAppDetailsSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS, dashboardUpdateGiftAppDetailsWorker);
}

///////////////////////////
// Dashboard Logout User //
///////////////////////////
function* dashboardLogoutUserWorker(action) {
  yield baseRequestWorker(
    action,
    agent.NeuroFitDashboardBackend.dashboardLogoutUser,
    {},
    undefined,
    dashboardLoggedInPageUpdateRequestState,
  )

  const requestResult = yield select(getDashboardLogoutUserResultFromLoggedInState)
  if (requestResult.state === REQUEST_SUCCESS) {
    resetAnalyticsUser()
    yield delay(SHORT_DELAY_MS)
    window.location = "/"
  } else if (requestResult.state === REQUEST_ERROR) {
    yield flashTopErrorMessage(dashboardLoggedInPageUpdateFormField, "Logging out failed. Please try again.")
  }
}
function* dashboardLogoutUserSaga() {
  yield takeLatest(DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + REQ_DASHBOARD_LOGOUT_USER, dashboardLogoutUserWorker);
}


function* computeKeyInsightsForSelectedClientIfAvailable() {
  try {
    const requestResult = yield select(getDashboardFetchClientDetailsRequestResultFromLoggedInState)

    const firstName = requestResult.result.client_profile.first_name
    const data = requestResult.result.client_profile_time_series_user_insights
    const fallbackKeyTakeaways = data.key_takeaways
    const fallbackLifeAspect = fallbackKeyTakeaways.life_aspect
    const fallbackHabit = fallbackKeyTakeaways.habit

    const keyTakewaysInputData = data.key_takeaways_input

    const avgCheckInScores = data.check_in.map(d => {
      const avgScore = mean([
        d.current_mood,
        d.love_and_relationship,
        d.money_and_finance,
        d.family_and_friends,
        d.career_and_business,
        d.health_and_well_being,
        d.purpose_and_mission,
        d.environment
      ].map(checkInState => scoreCheckInState(checkInState)))
      return {local_date: d.local_date, avg_score: avgScore}
    })

    let topLifeAspect = null
    let topLifeAspectGain = 0.0

    keyTakewaysInputData.life_aspects.map(lifeAspect => {

      const lifeAspectDatesBelowMean = new Set(data.check_in.filter(d => scoreCheckInState(d[lifeAspect.key]) < Math.max(lifeAspect.avg_score, 0.5)).map(d => d.local_date))
      const lifeAspectDatesAboveMean = new Set(data.check_in.filter(d => scoreCheckInState(d[lifeAspect.key]) >= Math.max(lifeAspect.avg_score, 0.5)).map(d => d.local_date))

      let belowMeanCheckInScore = 0
      let aboveMeanCheckInScore = 0
      avgCheckInScores.map(d => {
        if (lifeAspectDatesBelowMean.has(d.local_date)) {
          belowMeanCheckInScore += d.avg_score
        } else if (lifeAspectDatesAboveMean.has(d.local_date)) {
          aboveMeanCheckInScore += d.avg_score
        }
      })
      belowMeanCheckInScore /= (lifeAspectDatesBelowMean.size * 1.0)
      aboveMeanCheckInScore /= (lifeAspectDatesAboveMean.size * 1.0)
      const pctCheckInGain = (aboveMeanCheckInScore - belowMeanCheckInScore) / belowMeanCheckInScore

      let belowMeanHrv = 0
      let belowMeanBalanceScore = 0
      let belowMeanHrvCount = 0
      let belowMeanBalanceScoreCount = 0

      let aboveMeanHrv = 0
      let aboveMeanBalanceScore = 0
      let aboveMeanHrvCount = 0
      let aboveMeanBalanceScoreCount = 0
      data.hrv.map(d => {
        if (lifeAspectDatesBelowMean.has(d.local_date)) {
          belowMeanHrv += d.avg_hrv_rmssd_ms
          belowMeanHrvCount += 1
          if (d.avg_balance_score >= 0) {
            belowMeanBalanceScore += d.avg_balance_score
            belowMeanBalanceScoreCount += 1.0
          }
        } else if (lifeAspectDatesAboveMean.has(d.local_date)) {
          aboveMeanHrv += d.avg_hrv_rmssd_ms
          aboveMeanHrvCount += 1
          if (d.avg_balance_score >= 0) {
            aboveMeanBalanceScore += d.avg_balance_score
            aboveMeanBalanceScoreCount += 1.0
          }
        }
      })

      let hrvAvailable = false
      let pctHrvGain = 0
      let hrvRatio = belowMeanHrvCount / aboveMeanHrvCount
      if (belowMeanHrvCount > 0 && aboveMeanHrvCount > 0 && hrvRatio >= 0.125 && hrvRatio <= 8) {
        belowMeanHrv /= belowMeanHrvCount
        aboveMeanHrv /= aboveMeanHrvCount
        hrvAvailable = true
        pctHrvGain = (aboveMeanHrv - belowMeanHrv) / belowMeanHrv
      }

      let balanceScoreAvailable = false
      let pctBalanceScoreGain = 0
      let balanceScoreRatio = belowMeanBalanceScoreCount / aboveMeanBalanceScoreCount
      if (belowMeanBalanceScoreCount > 0 && aboveMeanBalanceScoreCount > 0 && balanceScoreRatio >= 0.125 && balanceScoreRatio <= 8) {
        balanceScoreAvailable = true
        belowMeanBalanceScore /= belowMeanBalanceScoreCount
        aboveMeanBalanceScore /= aboveMeanBalanceScoreCount
        pctBalanceScoreGain = (aboveMeanBalanceScore - belowMeanBalanceScore) / belowMeanBalanceScore
      }

      let insightCount = 0
      let resultInsights = []
      if (pctCheckInGain > 0.8) {
        insightCount += 1
        resultInsights.push(`their check-ins are ${(pctCheckInGain * 100).toFixed(0)}% more balanced`)
      }
      if (pctHrvGain >= 0.05) {
        insightCount += 1
        resultInsights.push(`their HRV is ${(pctHrvGain * 100).toFixed(0)}% higher`)
      }
      if (pctBalanceScoreGain >= 0.05) {
        insightCount += 1
        resultInsights.push(`their NeuroFit Balance™ is ${(pctBalanceScoreGain * 100).toFixed(0)}% higher`)
      }

      const avgLifeAspectGain = (Math.max(pctCheckInGain, 0.0) + Math.max(pctHrvGain, 0.0) + Math.max(pctBalanceScoreGain, 0.0)) / 3.0
      if (((avgLifeAspectGain > topLifeAspectGain) && (insightCount > 0)) || ((topLifeAspect === null) && (lifeAspect.key === fallbackLifeAspect.key))) {
        topLifeAspect = {name: lifeAspect.name, lifeAspectObject: lifeAspect, insightCount, resultInsights, pctCheckInGain, pctHrvGain, pctBalanceScoreGain}
        topLifeAspectGain = avgLifeAspectGain
      }
    })

    if (topLifeAspect !== null) {
      const resultInsightText = `When ${topLifeAspect.name} is balanced for ${firstName}, ${joinWithAnd(topLifeAspect.resultInsights)}. Have them focus on this life aspect during their CLEAR sessions and BALANCE training.`
      if (topLifeAspect.insightCount > 0) {
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT, resultInsightText))
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT, topLifeAspect.lifeAspectObject))
      } else {
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT, undefined))
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT, fallbackLifeAspect))
      }
    }

    let topHabit = null
    let topHabitGain = 0.0

    keyTakewaysInputData.habits.map(habit => {

      // TODO: Fix scoring check here.
      const habitDatesBelowMean = new Set(data.check_in.filter(d => ((habit.is_self_care ? d[habit.key] : (!!(d[habit.key]) ? 1.0 : 0.0)) < habit.value)).map(d => d.local_date))
      const habitDatesAboveMean = new Set(data.check_in.filter(d => ((habit.is_self_care ? d[habit.key] : (!!(d[habit.key]) ? 1.0 : 0.0)) >= habit.value)).map(d => d.local_date))

      let habitBelowMeanCheckInScore = 0
      let habitAboveMeanCheckInScore = 0
      avgCheckInScores.map(d => {
        if (habitDatesBelowMean.has(d.local_date)) {
          habitBelowMeanCheckInScore += d.avg_score
        } else if (habitDatesAboveMean.has(d.local_date)) {
          habitAboveMeanCheckInScore += d.avg_score
        }
      })
      habitBelowMeanCheckInScore /= (habitDatesBelowMean.size * 1.0)
      habitAboveMeanCheckInScore /= (habitDatesAboveMean.size * 1.0)
      const habitPctCheckInGain = (habitAboveMeanCheckInScore - habitBelowMeanCheckInScore) / habitBelowMeanCheckInScore

      let habitBelowMeanHrv = 0
      let habitBelowMeanBalanceScore = 0
      let habitBelowMeanHrvCount = 0
      let habitBelowMeanBalanceScoreCount = 0

      let habitAboveMeanHrv = 0
      let habitAboveMeanBalanceScore = 0
      let habitAboveMeanHrvCount = 0
      let habitAboveMeanBalanceScoreCount = 0
      data.hrv.map(d => {
        if (habitDatesBelowMean.has(d.local_date)) {
          habitBelowMeanHrv += d.avg_hrv_rmssd_ms
          habitBelowMeanHrvCount += 1.0
          if (d.avg_balance_score >= 0) {
            habitBelowMeanBalanceScore += d.avg_balance_score
            habitBelowMeanBalanceScoreCount += 1.0
          }
        } else if (habitDatesAboveMean.has(d.local_date)) {
          habitAboveMeanHrv += d.avg_hrv_rmssd_ms
          habitAboveMeanHrvCount += 1.0
          if (d.avg_balance_score >= 0) {
            habitAboveMeanBalanceScore += d.avg_balance_score
            habitAboveMeanBalanceScoreCount += 1.0
          }
        }
      })

      let habitHrvAvailable = false
      let habitPctHrvGain = 0
      let habitHrvRatio = habitBelowMeanHrvCount / habitAboveMeanHrvCount
      if (habitBelowMeanHrvCount > 0 && habitAboveMeanHrvCount > 0 && habitHrvRatio >= 0.125 && habitHrvRatio <= 8) {
        habitBelowMeanHrv /= habitBelowMeanHrvCount
        habitAboveMeanHrv /= habitAboveMeanHrvCount
        habitHrvAvailable = true
        habitPctHrvGain = (habitAboveMeanHrv - habitBelowMeanHrv) / habitBelowMeanHrv
      }

      let habitBalanceScoreAvailable = false
      let habitPctBalanceScoreGain = 0
      let habitBalanceScoreRatio = habitBelowMeanBalanceScoreCount / habitAboveMeanBalanceScoreCount
      if (habitBelowMeanBalanceScoreCount > 0 && habitAboveMeanBalanceScoreCount > 0 && habitBalanceScoreRatio >= 0.125 && habitBalanceScoreRatio <= 8) {
        habitBelowMeanBalanceScore /= habitBelowMeanBalanceScoreCount
        habitAboveMeanBalanceScore /= habitAboveMeanBalanceScoreCount
        habitBalanceScoreAvailable = true
        habitPctBalanceScoreGain = (habitAboveMeanBalanceScore - habitBelowMeanBalanceScore) / habitBelowMeanBalanceScore
      }

      let habitInsightCount = 0
      let habitResultInsights = []
      if (habitPctCheckInGain > 0.08) {
        habitInsightCount += 1
        habitResultInsights.push(`their check-ins are ${(habitPctCheckInGain * 100).toFixed(0)}% more balanced`)
      }
      if (habitPctHrvGain > 0.05) {
        habitInsightCount += 1
        habitResultInsights.push(`their HRV is ${(habitPctHrvGain * 100).toFixed(0)}% higher`)
      }
      if (habitPctBalanceScoreGain > 0.05) {
        habitInsightCount += 1
        habitResultInsights.push(`their NeuroFit Balance™ is ${(habitPctBalanceScoreGain * 100).toFixed(0)}% higher`)
      }

      const avgHabitGain = (Math.max(habitPctCheckInGain, 0.0) + Math.max(habitPctHrvGain, 0.0) + Math.max(habitPctBalanceScoreGain, 0.0)) / 3.0
      if (((avgHabitGain > topHabitGain) && (habitInsightCount > 0)) || ((topHabit === null) && (habit.key === fallbackHabit.key))) {
        topHabit = {name: habit.name, habitObject: habit, is_avoidance: habit.is_avoidance, habitResultInsights, habitPctCheckInGain, habitPctHrvGain, habitPctBalanceScoreGain, habitInsightCount}
        topHabitGain = avgHabitGain
      }
    })

    if (topHabit !== null) {
      const habitResultInsightText = `When ${firstName} ${topHabit.is_avoidance ? topHabit.name.toLowerCase() : `prioritizes their ${topHabit.name.toLowerCase()}`}, ${joinWithAnd(topHabit.habitResultInsights)}. Have them prioritze this habit to optimize their NEUROFIT results.`
      if (topHabit.habitInsightCount > 0) {
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT, habitResultInsightText))
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_HABIT, topHabit.habitObject))
      } else {
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT, undefined))
        yield put(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_TOP_HABIT, fallbackHabit))
      }
    }
  } catch (err) {
    console.error(`${err}`)
  }
}


export default function* rootSaga() {
  yield all([

    // Common
    fetchDashboardAppConfigSaga(),
    fetchDeploymentConfigSaga(),

    // Dashboard
    createDashboardAccountSaga(),
    loginDashboardUserSaga(),
    dashboardSendResetPasswordLinkSaga(),
    dashboardValidateResetPasswordTokenSaga(),
    dashboardSaveNewPasswordSaga(),
    dashboardFetchAffiliateProfileSaga(),
    dashboardSaveUpdatedAffiliateCodeSaga(),
    dashboardCreatePayoutsOnboardingUrlSaga(),
    dashboardCreatePayoutsLoginUrlSaga(),
    dashboardFetchSessionListSaga(),
    dashboardFetchClientSessionPlanDetailsSaga(),
    dashboardUpdateClientSessionPlanDetailsSaga(),
    dashboardRefundClientSessionPlanSaga(),
    dashboardFetchClientLeadListSaga(),
    dashboardFetchClientLeadDetailsSaga(),
    dashboardGenerateClientLeadResponseDraftSaga(),
    dashboardSendClientLeadReplySaga(),
    dashboardRemoveClientLeadSaga(),
    dashboardFetchClientListSaga(),
    dashboardFetchClientDetailsSaga(),
    dashboardRemoveClientSaga(),
    dashboardSaveUpdatedProfileImageSaga(),
    dashboardGetSignedUploadProfileImageUrlSaga(),
    dashboardUploadNewProfileImageSaga(),
    dashboardSaveProfileLinkUpdatesSaga(),
    dashboardRequestClientTestimonialSaga(),
    dashboardValidateClientTestimonialTokenSaga(),
    dashboardSaveClientTestimonialSaga(),
    dashboardCreateAppPrePurchasePaymentIntentSaga(),
    dashboardFetchGiftAppHistorySaga(),
    dashboardUpdateGiftAppDetailsSaga(),
    dashboardLogoutUserSaga(),
  ])
}
