import {
  COMMON_APP_NAME,
  COMMON_APP_PLATFORM,
  COMMON_LOGIN_TOKEN,
  COMMON_RESET_PASSWORD_TOKEN,
  COMMON_CLIENT_TESTIMONIAL_TOKEN,
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_VISIBLE_KEYBOARD_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_IOS_SAFARI,
  COMMON_IS_IOS_PWA,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
  COMMON_IOS_VERSION,
  COMMON_ANDROID_DEFERRED_PROMPT,
  COMMON_IS_NATIVE,
  COMMON_NATIVE_APP_OPEN_COUNT,
  COMMON_PUSH_NOTIFICATION_TOKEN,
  COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR,
  COMMON_IN_APP_PURCHASE_PRODUCTS,
  COMMON_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE,
  COMMON_CAMERA_ACCESS_PERMISSION_STATUS,
  COMMON_DEEPLINKED_ARTICLE_KEY,
  COMMON_DEEPLINKED_AFFILIATE_CODE,
  COMMON_DEEPLINKED_CERT_PAGE_SECTION_KEY,
  COMMON_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY,
  COMMON_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY,
  COMMON_APP_STATE_IS_ACTIVE,
  COMMON_CURRENT_APP_VERSION,
  COMMON_NATIVE_COMPLETION_SOUND_PRELOADED,
  COMMON_APP_ACTION_TYPE,
  COMMON_IS_DEEPLINKED_PAYWALL_ACTION,
  COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
  COMMON_APP_LOADED_AT,
  COMMON_DEPLOYMENT_CONFIG,
  COMMON_QUIZ_RESULT_KEY,
  COMMON_CERTIFICATION_AFFILIATE_CODE,
  COMMON_NATIVE_APP_IS_OFFLINE,
  COMMON_EXPERIMENT_FLAGS,
  COMMON_DEVICE_IDENTIFIER,
  COMMON_SELECTED_APP_ICON,
  COMMON_EXISTING_APP_OPEN_URL,
  COMMON_IS_MAC_OS_RUNTIME,
  COMMON_LANGUAGE_CODE,
} from '../constants/commonState'

import {
  REQ_FETCH_APP_CONFIG,
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT,
  REQ_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_FETCH_QUIZ_RESULTS,
  REQ_DASHBOARD_FETCH_APP_CONFIG,
  REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN,
  REQ_FETCH_CERTIFICATION_CODE_DETAILS,
} from '../constants/requestTypes'

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS,
} from '../constants/requestStates'

import {
  COMMON_UPDATE_RESET_PASSWORD_TOKEN,
  COMMON_UPDATE_CLIENT_TESTIMONIAL_TOKEN,
  COMMON_UPDATE_REQUEST_STATE,
  COMMON_UPDATE_INNER_HEIGHT,
  COMMON_UPDATE_INNER_WIDTH,
  COMMON_UPDATE_VISIBLE_KEYBOARD_HEIGHT,
  COMMON_UPDATE_SAFE_AREA_INSETS,
  COMMON_UPDATE_ANDROID_DEFERRED_PROMPT,
  COMMON_UPDATE_IS_IOS_PWA,
  COMMON_UPDATE_NATIVE_APP_OPEN_COUNT,
  COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR,
  COMMON_UPDATE_CAMERA_PERMISSIONS,
  COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY,
  COMMON_UPDATE_DEEPLINKED_AFFILIATE_CODE,
  COMMON_UPDATE_DEEPLINKED_CERT_PAGE_SECTION_KEY,
  COMMON_UPDATE_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY,
  COMMON_UPDATE_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY,
  COMMON_UPDATE_APP_STATE_IS_ACTIVE,
  COMMON_UPDATE_CURRENT_APP_VERSION,
  COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED,
  COMMON_UPDATE_APP_ACTION_TYPE,
  COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION,
  COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
  COMMON_UPDATE_QUIZ_RESULT_KEY,
  COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE,
  COMMON_UPDATE_DEPLOYMENT_CONFIG,
  COMMON_UPDATE_NATIVE_APP_IS_OFFLINE,
  COMMON_UPDATE_EXPERIMENT_FLAGS,
  COMMON_UPDATE_DEVICE_IDENTIFIER,
  COMMON_UPDATE_SELECTED_APP_ICON,
  COMMON_UPDATE_EXISTING_APP_OPEN_URL,
  COMMON_UPDATE_IS_MAC_OS_RUNTIME,
  COMMON_UPDATE_LANGUAGE_CODE,
  COMMON_RESET_STATE,
} from '../constants/actionTypes'

import {
  APP_ICON_CLASSIC,
} from '../constants/appIcons'

import {
  isIOSSafari,
  isIOSStandalone,
  isIOSDevice,
  isAndroidDevice,
  resizeInnerHeight,
  resizeInnerWidth,
  currentTime,
  FreeTrialInAppPurchaseProducts,
} from '../utils'

import {
  LanguageCodeEnglish,
} from '../languageUtils'

import {
  Capacitor,
} from '@capacitor/core'

const currentIOSVersion = require('ios-version/current')

const safeAreaInsets = require('safe-area-insets')

const defaultCommonState = {
  [COMMON_APP_NAME]: 'NEUROFIT',
  [COMMON_RESET_PASSWORD_TOKEN]: "",
  [COMMON_APP_STATE_IS_ACTIVE]: true,
  [COMMON_CURRENT_APP_VERSION]: "",
  [COMMON_INNER_HEIGHT]: resizeInnerHeight(),
  [COMMON_INNER_WIDTH]: resizeInnerWidth(),
  [COMMON_VISIBLE_KEYBOARD_HEIGHT]: 0,
  [COMMON_SAFE_AREA_INSETS]: safeAreaInsets,
  [COMMON_ANDROID_DEFERRED_PROMPT]: undefined,
  [COMMON_IS_IOS_SAFARI]: isIOSSafari(),
  [COMMON_IS_IOS_DEVICE]: isIOSDevice(),
  [COMMON_IS_ANDROID_DEVICE]: isAndroidDevice(),
  [COMMON_IS_IOS_PWA]: isIOSStandalone(),
  [COMMON_IOS_VERSION]: isIOSDevice() ? currentIOSVersion : null,
  [COMMON_IS_NATIVE]: Capacitor.isNativePlatform(),
  [COMMON_APP_PLATFORM]: Capacitor.getPlatform(),
  [COMMON_NATIVE_APP_OPEN_COUNT]: 0,
  [COMMON_PUSH_NOTIFICATION_TOKEN]: "",
  [COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED]: false,
  [COMMON_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR]: false,
  [COMMON_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE]: true,
  [COMMON_IN_APP_PURCHASE_PRODUCTS]: FreeTrialInAppPurchaseProducts,
  [COMMON_CAMERA_ACCESS_PERMISSION_STATUS]: "",
  [COMMON_APP_LOADED_AT]: currentTime(),
  [COMMON_DEEPLINKED_ARTICLE_KEY]: "",
  [COMMON_DEEPLINKED_AFFILIATE_CODE]: "",
  [COMMON_DEEPLINKED_CERT_PAGE_SECTION_KEY]: "",
  [COMMON_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY]: "",
  [COMMON_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY]: "",
  [COMMON_APP_ACTION_TYPE]: "",
  [COMMON_IS_DEEPLINKED_PAYWALL_ACTION]: false,
  [COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION]: false,
  [COMMON_QUIZ_RESULT_KEY]: "",
  [COMMON_DEPLOYMENT_CONFIG]: undefined,
  [COMMON_NATIVE_APP_IS_OFFLINE]: false,
  [COMMON_EXPERIMENT_FLAGS]: {},
  [COMMON_DEVICE_IDENTIFIER]: "",
  [COMMON_SELECTED_APP_ICON]: APP_ICON_CLASSIC,
  [COMMON_IS_MAC_OS_RUNTIME]: false,
  [COMMON_LANGUAGE_CODE]: LanguageCodeEnglish,
  [REQ_FETCH_APP_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_CERTIFICATION_CODE_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_DEPLOYMENT_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_VALIDATE_RESET_PASSWORD_TOKEN]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_QUIZ_RESULTS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_APP_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN]: {state: REQUEST_UNSTARTED}
};

export default (state = defaultCommonState, action) => {
  switch (action.type) {
    case COMMON_UPDATE_RESET_PASSWORD_TOKEN:
      return {...state, [COMMON_RESET_PASSWORD_TOKEN]: action.payload}
    case COMMON_UPDATE_CLIENT_TESTIMONIAL_TOKEN:
      return {...state, [COMMON_CLIENT_TESTIMONIAL_TOKEN]: action.payload}
    case COMMON_UPDATE_INNER_HEIGHT:
      return {...state, [COMMON_INNER_HEIGHT]: action.payload}
    case COMMON_UPDATE_INNER_WIDTH:
      return {...state, [COMMON_INNER_WIDTH]: action.payload}
    case COMMON_UPDATE_VISIBLE_KEYBOARD_HEIGHT:
      return {...state, [COMMON_VISIBLE_KEYBOARD_HEIGHT]: action.payload}
    case COMMON_UPDATE_SAFE_AREA_INSETS:
      return {...state, [COMMON_SAFE_AREA_INSETS]: action.payload}
    case COMMON_UPDATE_ANDROID_DEFERRED_PROMPT:
      return {...state, [COMMON_ANDROID_DEFERRED_PROMPT]: action.payload}
    case COMMON_UPDATE_IS_IOS_PWA:
      return {...state, [COMMON_IS_IOS_PWA]: action.payload}
    case COMMON_UPDATE_NATIVE_APP_OPEN_COUNT:
      return {...state, [COMMON_NATIVE_APP_OPEN_COUNT]: action.payload}
    case COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN:
      return {...state, [COMMON_PUSH_NOTIFICATION_TOKEN]: action.payload}
    case COMMON_UPDATE_REQUEST_STATE:
      return {...state, [action.key]: action.payload}
    case COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS:
      return {...state, [COMMON_IN_APP_PURCHASE_PRODUCTS]: action.payload}
    case COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE:
      return {...state, [COMMON_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE]: action.payload}
    case COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED:
      return {...state, [COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED]: action.payload}
    case COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR:
      return {...state, [COMMON_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR]: action.payload}
    case COMMON_UPDATE_CAMERA_PERMISSIONS:
      return {...state, [COMMON_CAMERA_ACCESS_PERMISSION_STATUS]: action.payload}
    case COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY:
      return {...state, [COMMON_DEEPLINKED_ARTICLE_KEY]: action.payload}
    case COMMON_UPDATE_DEEPLINKED_AFFILIATE_CODE:
      return {...state, [COMMON_DEEPLINKED_AFFILIATE_CODE]: action.payload}
    case  COMMON_UPDATE_DEEPLINKED_CERT_PAGE_SECTION_KEY:
      return {...state, [COMMON_DEEPLINKED_CERT_PAGE_SECTION_KEY]: action.payload}
    case COMMON_UPDATE_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY:
      return {...state, [COMMON_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY]: action.payload}
    case COMMON_UPDATE_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY:
      return {...state, [COMMON_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY]: action.payload}
    case COMMON_UPDATE_APP_STATE_IS_ACTIVE:
      return {...state, [COMMON_APP_STATE_IS_ACTIVE]: action.payload}
    case COMMON_UPDATE_CURRENT_APP_VERSION:
      return {...state, [COMMON_CURRENT_APP_VERSION]: action.payload}
    case COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED:
      return {...state, [COMMON_NATIVE_COMPLETION_SOUND_PRELOADED]: action.payload}
    case COMMON_UPDATE_APP_ACTION_TYPE:
      return {...state, [COMMON_APP_ACTION_TYPE]: action.payload}
    case COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION:
      return {...state, [COMMON_IS_DEEPLINKED_PAYWALL_ACTION]: action.payload}
    case COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION:
      return {...state, [COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION]: action.payload}
    case COMMON_UPDATE_QUIZ_RESULT_KEY:
      return {...state, [COMMON_QUIZ_RESULT_KEY]: action.payload}
    case COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE:
      return {...state, [COMMON_CERTIFICATION_AFFILIATE_CODE]: action.payload}
    case COMMON_UPDATE_DEPLOYMENT_CONFIG:
      return {...state, [COMMON_DEPLOYMENT_CONFIG]: action.payload}
    case COMMON_UPDATE_NATIVE_APP_IS_OFFLINE:
      return {...state, [COMMON_NATIVE_APP_IS_OFFLINE]: action.payload}
    case COMMON_UPDATE_EXPERIMENT_FLAGS:
      return {...state, [COMMON_EXPERIMENT_FLAGS]: action.payload}
    case COMMON_UPDATE_DEVICE_IDENTIFIER:
      return {...state, [COMMON_DEVICE_IDENTIFIER]: action.payload}
    case COMMON_UPDATE_SELECTED_APP_ICON:
      return {...state, [COMMON_SELECTED_APP_ICON]: action.payload}
    case COMMON_UPDATE_EXISTING_APP_OPEN_URL:
      return {...state, [COMMON_EXISTING_APP_OPEN_URL]: action.payload}
    case COMMON_UPDATE_IS_MAC_OS_RUNTIME:
      return {...state, [COMMON_IS_MAC_OS_RUNTIME]: action.payload}
    case COMMON_UPDATE_LANGUAGE_CODE:
      return {...state, [COMMON_LANGUAGE_CODE]: action.payload}
    case COMMON_RESET_STATE:
      return defaultCommonState
    default:
      return state;
  }
};
