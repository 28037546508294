import React from 'react'

import LoadingLogo from '../LoadingLogo'

import {
  ScheduleSvg,
  InboxSvg,
  EditProfileSvg,
  AffiliateSvg,
  GiftAppAccessSvg,
  SettingsSvg,
  LogOutSvg,
  SeoSvg,
  FaqSvg,
  FeatureRequestSvg,
  ContactSupportSvg,
  LegalSvg,
  AirplaneSvg,
  TargetPracticeSvg,
  ArtToolsSvg,
  LinkSvg
} from '../../../constants/dashboardSvgs'

import {
  NeuroFitCutoutSvg,
  BalanceSvg,
  NeuroFitNamedLogoSvg,
  VerifiedSvg,
  LockSvg,
  ClearSvg,
} from '../../../constants/svgs'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../../constants/requestStates'

import {
  DASHBOARD_SECTION_ONBOARDING,
  DASHBOARD_SECTION_CERTIFICATION,
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_BOOKED_SESSIONS,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_BALANCE_TOOL,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_OFFER_COPILOT,
  DASHBOARD_SECTION_BUSINESS_SUITE,
  DASHBOARD_SECTION_COACH_COPILOT,
  DASHBOARD_SECTION_COACH_TRAINING,
  DASHBOARD_SECTION_CONTENT_COPILOT,
  DASHBOARD_SECTION_AFFILIATE,
  DASHBOARD_SECTION_GIFT_APP_ACCESS,
  DASHBOARD_SECTION_SETTINGS,
  DASHBOARD_SECTION_LOGOUT,
  DASHBOARD_SECTION_FAQ,
  DASHBOARD_SECTION_FEATURE_REQUESTS,
  DASHBOARD_SECTION_SUPPORT,
  DASHBOARD_SECTION_LEGAL_AND_DATA,
  DASHBOARD_SECTION_ADMIN_SEO,
} from '../../../constants/dashboardSections'

import CompletionCheckIcon from '../CompletionCheckIcon'

const SideBarWidth = 240


const OnboardingCompletionLinkButton = ({incompleteTitle, completeTitle, completeEmoji, incompleteEmoji, isCompleted, onClick, marginTop}) => (
  <div className={isCompleted ? "dashboard-onboarding-link-button-completed" : "dashboard-onboarding-link-button"} style={{marginTop: marginTop || "0.7em", fontSize: "min(3.75vw, 16px)"}}>
    <CompletionCheckIcon
      isComplete={isCompleted}
      checkboxBaseClassName={"checkbox-dash-onboarding-button"}
      checkColorComplete={"#000000"}
      checkColorIncomplete={"#1D1D1D"}
    />
    <div
      style={{marginInlineStart: "0.3em", cursor: isCompleted ? "not-allowed" : "pointer", fontFamily: "Futura", textDecoration: isCompleted ? "none" : "underline", fontStyle: isCompleted ? "italic" : "auto", textDecorationThickness: "0.05em", textUnderlineOffset: "0.2em"}}
      onClick={() => !isCompleted && onClick()}
    >
      {isCompleted ? completeTitle : incompleteTitle}
    </div>
    <div style={{marginInlineStart: "0.3em", fontFamily: "Futura"}}>
      {isCompleted ? completeEmoji : incompleteEmoji}
    </div>
  </div>
)

const TaskBadgeCount = ({count, isSelected}) => (
  <div style={{height: 18, width: 18, borderRadius: "50%", marginInlineStart: "0.3em", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: `rgba(${229 * (isSelected ? 1.0 : 0.7)}, 0, 0)`}}>
    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium", fontSize: "12px", lineHeight: "12px"}}>
      {count}
    </span>
  </div>
)

const DashboardSideMenuButton = ({
  title,
  logo,
  hideTitle,
  isSelected,
  badgeCount,
  onClick
}) => (
  <div
  >
    <button
      className={isSelected ? "dashboard-side-menu-button-selected" : "dashboard-side-menu-button"}
      style={{marginInlineEnd: "6%", padding: "7px 6% 7px 12%", width: "88%", fontFamily: "Futura", fontSize: "min(4vw, 18px)", border: "none", dropShadow: "none", display: "flex", alignItems: "center", justifyContent: "start"}}
      onClick={() => onClick()}
    >
      <div style={{width: 25}}>
        {logo}
      </div>
      {!(hideTitle) && (
        <span style={{fontSize: "min(4vw, 18px)", marginInlineStart: "0.3em", lineHeight: "min(4vw, 18px)"}}>
          {title}
        </span>
      )}
      {(!!badgeCount) && (
        <TaskBadgeCount
          count={badgeCount}
          isSelected={isSelected}
        />
      )}
    </button>
  </div>
)

const DashboardSideMenu = ({
  isActiveCoach,
  isCertifiedTrainer,
  isSeoAdmin,
  isBookingFeatureSetEnabled,
  isOnboardingComplete,
  isAffiliateOnboardingComplete,
  isProfileOnboardingComplete,
  isQuizOnboardingComplete,
  isQuizResponseOnboardingComplete,
  isClientOnboardingComplete,
  remainingOnboardingItemsCount,
  outstandingClientLeadsCount,
  selectedMenuSection,
  trainerFirstName,
  trainerLastName,
  trainerEmailAddress,
  onUpdateSelectedMenuSection,
  logoutRequestState,
  onLogoutUser
}) => {
  console.log(`outstandingClientLeadsCount: ${outstandingClientLeadsCount}`)
  const logoutRequestButtonText = (logoutRequestState === REQUEST_FETCHING) ? "Logging Out..." : ((logoutRequestState === REQUEST_SUCCESS) ? "Logged Out." : "Log Out")
  
  const onboardingMenuItems = [
    {
      text: "Your Checklist",
      title: "Checklist",
      key: DASHBOARD_SECTION_ONBOARDING,
      is_onboarding_box: true
    },
  ]

  const dashboardMenuItems = [
    {
      is_spacing: true,
    },
    {
      is_title: true,
      text: "Coach"
    },
    {
      title: "Certification",
      key: DASHBOARD_SECTION_CERTIFICATION,
      logo: (
        isActiveCoach ? (
          <VerifiedSvg size={"21px"} margin={"0.3em 0px 0px 0px"} lineColor={(selectedMenuSection === DASHBOARD_SECTION_CERTIFICATION) ? "#FFFFFF" : "#000000"} color={(selectedMenuSection === DASHBOARD_SECTION_CERTIFICATION) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_CERTIFICATION) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    {
      title: "Clients",
      key: DASHBOARD_SECTION_CLIENTS,
      logo: (
        isActiveCoach ? (
          <NeuroFitCutoutSvg size={"20px"} margin={"0.3em 0px 0px 0px"} lineColor={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#FFFFFF" : "#000000"} color={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    // {
    //   title: "Sessions",
    //   key: DASHBOARD_SECTION_BOOKED_SESSIONS,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ScheduleSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_BOOKED_SESSIONS) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_BOOKED_SESSIONS) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Quiz Results",
      key: DASHBOARD_SECTION_QUIZ_LEADS,
      badge_count: outstandingClientLeadsCount,
      logo: (
        isActiveCoach ? (
          <InboxSvg size={"20px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_QUIZ_LEADS) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_QUIZ_LEADS) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    {
      is_spacing: true,
    },
    // {
    //   is_title: true,
    //   text: "Coach"
    // },
    // {
    //   title: "Coach Copilot™",
    //   key: DASHBOARD_SECTION_COACH_COPILOT,
    //   logo: (
    //     isActiveCoach ? (
    //       <AirplaneSvg size={"20px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_COPILOT) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_COPILOT) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "Coach Training",
    //   key: DASHBOARD_SECTION_COACH_TRAINING,
    //   logo: (
    //     isActiveCoach ? (
    //       <TargetPracticeSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_TRAINING) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_TRAINING) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   is_spacing: true,
    // },
    {
      is_title: true,
      text: "Business"
    },
    // {
    //   title: "Content Copilot",
    //   key: DASHBOARD_SECTION_CONTENT_COPILOT,
    //   logo: (
    //     isActiveCoach ? (
    //       <ArtToolsSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_CONTENT_COPILOT) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_CONTENT_COPILOT) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "BALANCE Tool",
    //   key: DASHBOARD_SECTION_BALANCE_TOOL,
    //   logo: (
    //     <BalanceSvg size={"20px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_BALANCE_TOOL) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   is_spacing: true,
    // },
    // {
    //   title: "Edit Profile",
    //   key: DASHBOARD_SECTION_EDIT_PROFILE,
    //   logo: (
    //     <EditProfileSvg size={"18px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Profile Link",
      key: DASHBOARD_SECTION_EDIT_PROFILE,
      logo: (
        isActiveCoach ? (
          <LinkSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    {
      title: "Affiliate",
      key: DASHBOARD_SECTION_AFFILIATE,
      logo: (
        <AffiliateSvg size={"22px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_AFFILIATE) ? "#000000" : "#999999"} />
      ),
      onClick: () => {},
    },
    {
      title: "Gift App Access",
      key: DASHBOARD_SECTION_GIFT_APP_ACCESS,
      logo: (
        <GiftAppAccessSvg size={"22px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_GIFT_APP_ACCESS) ? "#000000" : "#999999"} />
      ),
      onClick: () => {},
    },
    {
      title: "AI Offer Copilot",
      key: DASHBOARD_SECTION_OFFER_COPILOT,
      logo: (
        isActiveCoach ? (
          <ClearSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_OFFER_COPILOT) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_OFFER_COPILOT) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    {
      title: "Business Suite",
      key: DASHBOARD_SECTION_BUSINESS_SUITE,
      logo: (
        isCertifiedTrainer ? (
          <ArtToolsSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_BUSINESS_SUITE) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_BUSINESS_SUITE) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    // {
    //   title: "Settings",
    //   key: DASHBOARD_SECTION_SETTINGS,
    //   logo: (
    //     <SettingsSvg size={"20px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_SETTINGS) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   is_spacing: true,
    // },
    {
      is_spacing: true,
    },
    {
      is_title: true,
      text: "Settings"
    },
    {
      title: "FAQ",
      key: DASHBOARD_SECTION_FAQ,
      logo: (
        <FaqSvg size={"18px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_FAQ) ? "#000000" : "#999999"} />
      ),
      onClick: () => {},
    },
    // {
    //   title: "Contact Support",
    //   key: DASHBOARD_SECTION_SUPPORT,
    //   logo: (
    //     <ContactSupportSvg size={"18px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_SUPPORT) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "Legal & Data",
    //   key: DASHBOARD_SECTION_LEGAL_AND_DATA,
    //   logo: (
    //     <LegalSvg size={"20px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_LEGAL_AND_DATA) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    {
      title: logoutRequestButtonText,
      key: DASHBOARD_SECTION_LOGOUT,
      logo: (
        <LogOutSvg size={"15px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_LOGOUT) ? "#000000" : "#999999"} />
      ),
      onClick: () => onLogoutUser(),
    },
  ]

  const allMenuItems = (isActiveCoach && !isOnboardingComplete) ? [...onboardingMenuItems, ...dashboardMenuItems] : dashboardMenuItems

  return (
    <div style={{position: "fixed", overflowX: "hidden", overflowY: "scroll", top: 0, left: 0, bottom: 0, width: 240, backgroundColor: "#000000", zIndex: 10000}}>
      <div style={{padding: "8% 0px"}}>
        <div style={{display: "flex", justifyContent: "start", width: "100%"}}>
          <div style={{display: "inline-block", padding: "0px 12%", width: "76%"}}>
            <div>
              <NeuroFitNamedLogoSvg
                logoSize={"min(4.5vw, 20px)"}
                color={"#FFFFFF"}
                onClick={() => window.location.reload(true/*hardReload*/)}
              />
            </div>
            <div style={{display: "flex", justifyContent: "start"}}>
              <div style={{display: "inline-block"}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: 3}}>
                  <div style={{color: "#EDEEF2", textTransform: "uppercase", maxWidth: 180, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginInlineEnd: "0.2em", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}>
                    {`${trainerFirstName} ${trainerLastName}`}
                  </div>
                </div>
                {(isActiveCoach) && (
                  <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: 3}}>
                    <VerifiedSvg size={"min(3.25vw, 14px)"} color={"#EDEEF2"} />
                    <div style={{color: "#EDEEF2", textTransform: "uppercase", maxWidth: 180, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginInlineStart: "0.3em", marginTop: "0.1em", fontFamily: "Futura", fontSize: "min(3.25vw, 14px)", lineHeight: "min(3.25vw, 14px)"}}>
                      {isCertifiedTrainer ? `CERTIFIED COACH` : `COACH IN TRAINING`}
                    </div>
                  </div>
                )}
                <div style={{maxWidth: "100%", marginTop: 3, overflow: "hidden", maxWidth: 180, whiteSpace: "nowrap", textAlign: "left", textOverflow: "ellipsis", fontFamily: "Futura", color: "#999999", fontSize: "min(3.5vw, 15px)"}}>
                  {trainerEmailAddress}
                </div>
              </div>
            </div>
          </div>
        </div>
        {allMenuItems.map((item, idx) => {
          if (item.is_spacing) {
            return (
              <div key={idx} style={{height: "4vh"}} />
            )
          } else if (item.is_title) {
            return (
              <div key={item.text} style={{width: "100%", padding: "0px 0px 1vh 12%", textTransform: "uppercase", fontFamily: "Futura Medium", color: "#FFFFFF", fontSize: "min(3.5vw, 16px)"}}>
                <span>{item.text}</span>
              </div>
            )
          } else if (item.is_onboarding_box) {
            return (
              <div key={item.text}>
                <div
                  style={{width: "100%", cursor: "pointer", marginTop: "4vh", display: "flex", alignItems: "center", justifyContent: "inline-start", padding: "0px 0px 1vh 12%", textTransform: "uppercase", fontFamily: "Futura Medium", color: "#FFFFFF", fontSize: "min(3.5vw, 16px)"}}
                  onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_ONBOARDING)}
                >
                  <span>{item.text}</span>
                  <TaskBadgeCount
                    count={remainingOnboardingItemsCount}
                    isSelected
                  />
                </div>
                <div style={{backgroundColor: "#1D1D1D", margin: "0.5vh 5%", padding: "1vh", boxShadow: "0px 0px 1.5px 0.5px #DDDDDD"}}>
                  <OnboardingCompletionLinkButton
                    marginTop={"0px"}
                    incompleteTitle={"Set Up Payouts"}
                    completeTitle={"Activated Payouts!"}
                    incompleteEmoji={"💰"}
                    completeEmoji={"💎"}
                    isCompleted={isAffiliateOnboardingComplete}
                    onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_AFFILIATE)}
                  />
                  <OnboardingCompletionLinkButton
                    incompleteTitle={"Create Coach Profile"}
                    completeTitle={"Coach Profile Ready!"}
                    incompleteEmoji={"🎨"}
                    completeEmoji={"🚀"}
                    isCompleted={isProfileOnboardingComplete}
                    onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_EDIT_PROFILE)}
                  />
                  <OnboardingCompletionLinkButton
                    incompleteTitle={"Get A Client Lead"}
                    completeTitle={"Got A Client Lead!"}
                    incompleteEmoji={"📥"}
                    completeEmoji={"📨"}
                    isCompleted={isQuizOnboardingComplete}
                    onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_QUIZ_LEADS)}
                  />
                  <OnboardingCompletionLinkButton
                    incompleteTitle={"Reply To A Lead"}
                    completeTitle={"Replied To A Lead!"}
                    incompleteEmoji={"📤"}
                    completeEmoji={"✉️"}
                    isCompleted={isQuizResponseOnboardingComplete}
                    onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_QUIZ_LEADS)}
                  />

                  <OnboardingCompletionLinkButton
                    incompleteTitle={"Connect A Client"}
                    completeTitle={"Connected A Client!"}
                    incompleteEmoji={"📲"}
                    completeEmoji={"🤝"}
                    isCompleted={isClientOnboardingComplete}
                    onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_CLIENTS)}
                  />
                  <OnboardingCompletionLinkButton
                    incompleteTitle={"Get Certified"}
                    completeTitle={"You Got Certified!"}
                    incompleteEmoji={"🎓"}
                    completeEmoji={"🏆"}
                    isCompleted={isCertifiedTrainer}
                    onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_CERTIFICATION)}
                  />
                </div>
              </div>
            )
          } else {
            return (
              <DashboardSideMenuButton
                key={idx}
                isSelected={selectedMenuSection === item.key}
                badgeCount={item.badge_count}
                title={item.title}
                logo={item.logo}
                onClick={() => {
                  item.onClick()
                  onUpdateSelectedMenuSection(item.key)
                }}
              />
            )
          }
        })}
      </div>
    </div>
  )
}


export default DashboardSideMenu;