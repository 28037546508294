import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  updateFormField,
  triggerRequest,
  loginPageToggleModal,
  loginPageUpdateRequestState,
  resetLoginPage,
} from '../../actions/loginPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
} from '../../utils'

import ConfirmResetPasswordPage from '../../components/dashboard/ConfirmResetPasswordPage';

import {
    REQ_VALIDATE_RESET_PASSWORD_TOKEN,
} from '../../constants/requestTypes'

import {
    REQUEST_SUCCESS,
} from '../../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../../constants/commonState'

class ConfirmResetPasswordPageContainer extends Component {
  componentWillMount() {

  }

  componentDidMount() {
    hideLoadingScreen()
  }
  componentWillUnmount() {
    this.props.dispatch(resetLoginPage());
  }

  render() {
    return (
      <ConfirmResetPasswordPage
        // State
        formFields={this.props.formFields}
        requestResults={this.props.requestResults}
        passwordResetTokenIsValid={this.props.validateResetPasswordResult.state === REQUEST_SUCCESS}
        safeAreaInsets={this.props.safeAreaInsets}
        innerHeight={this.props.innerHeight}
        isNative={this.props.isNative}
        isIOS={this.props.isIOSDevice}
        isAndroid={this.props.isAndroidDevice}
        // Actions
        onUpdateFormField={(key, value) => this.props.dispatch(updateFormField(key, value))}
        onUpdateRequestState={(req_key, req_status) => this.props.dispatch(loginPageUpdateRequestState(req_key, req_status))}
        onTriggerRequest={requestType => this.props.dispatch(triggerRequest(requestType))}
        onToggleModal={(key, isOpen) => {
          toggleBodyScroll(!(isOpen))
          this.props.dispatch(loginPageToggleModal(key, isOpen))
        }}
      />
    );
  }
}

ConfirmResetPasswordPageContainer.propTypes = {
  formFields: PropTypes.object.isRequired,
  requestResults: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isNative: PropTypes.bool.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  validateResetPasswordResult: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.loginPage,
  validateResetPasswordResult: state.common[REQ_VALIDATE_RESET_PASSWORD_TOKEN],
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isNative: state.common[COMMON_IS_NATIVE],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
});

export default connect(mapStateToProps)(ConfirmResetPasswordPageContainer);