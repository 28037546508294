import {
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD,
  DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST,
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE,
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE,
  DASHBOARD_LOGGED_IN_PAGE_RESET_STATE,
} from '../constants/actionTypes';

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS,
  REQUEST_FETCHING,
  REQUEST_ERROR,
} from '../constants/requestStates';

export const resetDashboardLoggedInPage = () => {
  return {
    type: DASHBOARD_LOGGED_IN_PAGE_RESET_STATE
  }
};

export const dashboardLoggedInPageTriggerRequest = (key) => {
  return {
    type: DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST + key,
    payload: {
      key,
    }
  }
};

export const dashboardLoggedInPageUpdateRequestState = (key, state, result=undefined) => {
  let payload = {
    state,
  };
  if (!!(result)) {
    if (state === REQUEST_SUCCESS || state === REQUEST_FETCHING || state === REQUEST_UNSTARTED) {
      payload.result = result
    }
    if (state === REQUEST_ERROR) {
      payload.error = result;
    }
  }
  return {
    type: DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE,
    key,
    payload,
  }
}

export const dashboardLoggedInPageUpdateFormField = (key, value) => {
  return {
    type: DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD,
    payload: {[key]: value}
  }
};

export const dashboardLoggedInPageUpdatePageState = (key, value) => {
  return {
    type: DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE,
    payload: {[key]: value}
  }
}
