import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

import {
  InstagramEmbed,
  TikTokEmbed,
} from 'react-social-media-embed';

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  OvalSpinnerSvg,
} from '../../../constants/svgs'

import {
  AffiliateSvg,
} from '../../../constants/dashboardSvgs'

import {
  ProductionCertificationUrl,
  ProductionAppWebCheckoutUrl,
  isIOSDevice,
  AppleAppReviewLink,
  NeuroFitGooglePlayStoreLink,
  getAppAffiliateLink,
  getCertificationAffiliateLink,
  trimHttpPrefix
} from '../../../utils'

const AffiliateLinkViews = [
  {
    key: "app",
    value: "App"
  },
  {
    key: "certification",
    value: "Certification"
  },
  {
    key: "teams",
    value: "Teams"
  }
]

const ContentExamples = [
  {
    "label": "Exercises",
    "url": "https://www.tiktok.com/@kerrygreenofficial/video/7220952026245598507",
    "platform": "tiktok"
  },
  {
    "label": "App Demos",
    "url": "https://www.instagram.com/p/CslJF6LRsnl/",
    "platform": "instagram"
  },
  {
    "label": "Education",
    "url": "https://www.instagram.com/p/Cvp_igryBX5/",
    "platform": "instagram"
  },

]

class AffiliateProfileTab extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        appAffiliateLinkIsCopied: false,
        certificationAffiliateLinkIsCopied: false,
        selectedExamplePost:   {
          "label": "Exercises",
          "url": "https://www.tiktok.com/@kerrygreenofficial/video/7220952026245598507",
          "platform": "tiktok"
        },
     }
  }

  componentWillMount() {
    const {
      onLoadAffiliateProfile,
    } = this.props
    onLoadAffiliateProfile()
  }

  render() {

    const {
      existingAffiliateCode,
      currentInputAffiliateCode,
      timeOfDay,
      firstName,
      isDesktop,
      affiliateProfileRequestResult,
      updateAffiliateCodeRequestResult,
      startPayoutsOnboardingRequestState,
      loadPayoutsDashboardRequestState,
      onResetAffiliateCodeRequest,
      onLoadAffiliateProfile,
      onUpdateAffiliateCode,
      onSaveUpdatedAffiliateCode,
      onStartPayoutsOnboarding,
      onLoadPayoutsDashboard

    } = this.props

    const {
      appAffiliateLinkIsCopied,
      certificationAffiliateLinkIsCopied,
      selectedExamplePost
    } = this.state

    const isIOS = isIOSDevice()

    const appAffiliateLink = getAppAffiliateLink(existingAffiliateCode)
    const certificationAffiliateLink = getCertificationAffiliateLink(existingAffiliateCode)

    const affiliateCodeNeedsPropagation = (affiliateProfileRequestResult.state === REQUEST_SUCCESS) && (affiliateProfileRequestResult.result.profile_data.affiliate_code_needs_propagation_update === true)
    const displayUpdateAffiliateCodeError = (updateAffiliateCodeRequestResult.state === REQUEST_ERROR) || affiliateCodeNeedsPropagation
    const displayUpdateAffiliateCodeErrorText = displayUpdateAffiliateCodeError ? ((affiliateCodeNeedsPropagation || (updateAffiliateCodeRequestResult.error.error_code === "affiliate_code_save_error")) ? <span>{"Your links failed to save. "}<span style={{textDecoration: "underline", fontFamily: "Futura Medium"}} onClick={() => onSaveUpdatedAffiliateCode(existingAffiliateCode)}>{"Click to Retry"}</span><span>{"."}</span></span> : ((updateAffiliateCodeRequestResult.error.error_code === "affiliate_code_taken_error") ? "This link is already in use." : "")) : ""

    const redirectingToPayoutsOnboarding = (startPayoutsOnboardingRequestState === REQUEST_FETCHING) || (startPayoutsOnboardingRequestState === REQUEST_SUCCESS)
    const loadingPayoutsDashboard = (loadPayoutsDashboardRequestState === REQUEST_FETCHING) || (loadPayoutsDashboardRequestState === REQUEST_SUCCESS)
    
    const completedStripeOnboarding = (affiliateProfileRequestResult.state === REQUEST_SUCCESS) && affiliateProfileRequestResult.result.profile_data.completed_stripe_connect_onboarding
    
    const disableAppCopyButton = appAffiliateLinkIsCopied || (existingAffiliateCode !== currentInputAffiliateCode) || !completedStripeOnboarding
    const disableCertificationCopyButton = certificationAffiliateLinkIsCopied || (existingAffiliateCode !== currentInputAffiliateCode) || !completedStripeOnboarding
    return (
      <div>
        <div
          style={{marginTop: isDesktop ? 100 : 20, display: "flex", width: "100%", justifyContent: "center"}}
        >
          <div style={{width: "85%", maxWidth: 600}}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                timeout={500}
                classNames={"hide-fade-in"}
                unmountOnExit
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                key={`${affiliateProfileRequestResult.state === REQUEST_SUCCESS}`}
              >
                <div style={{backgroundColor: "#FFFFFF", padding: "min(5vw, 22px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                  <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginBottom: "2vh"}}>
                    {`YOUR AFFILIATE LINKS`}
                  </div>
                  <div>
                    <div style={{textAlign: "center"}}>
                      <div style={{fontSize: "min(6vw, 25px)", textTransform: "uppercase", width: "calc(80% + 36px)", display: "inline-block", fontFamily: "Futura Medium", marginTop: "5vh", marginBottom: "1vh"}}>
                        {`NEUROFIT App`}
                      </div>
                      <div
                        disabled={disableAppCopyButton}
                        style={{overflow: "hidden", display: "inline-block", width: "80%", userSelect: completedStripeOnboarding ? "auto" : "none", cursor: disableAppCopyButton ? "not-allowed" : "pointer", border: "none", WebkitUserSelect: completedStripeOnboarding ? "auto" : "none", textOverflow: "ellipsis", whiteSpace: "nowrap", lineHeight: "18px", backgroundColor: "#000000", color: disableAppCopyButton ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF", fontSize: "18px", lineHeight: "18px", fontFamily: "Futura", padding: "12px 18px"}}
                        onClick={() => {
                          if (completedStripeOnboarding) {
                            this.setState({appAffiliateLinkIsCopied: true})
                            navigator.clipboard.writeText(appAffiliateLink)
                            setTimeout(() => {
                              this.setState({appAffiliateLinkIsCopied: false})
                            }, 1000)
                          }
                        }}
                      >
                        {appAffiliateLinkIsCopied ? "COPIED LINK!" : (completedStripeOnboarding ? trimHttpPrefix(appAffiliateLink) : "SETUP NEEDED")}
                      </div>
                      {completedStripeOnboarding && (
                        <div
                          style={{fontFamily: "Futura Medium", marginTop: "1vh", fontSize: "15px", cursor: disableAppCopyButton ? "not-allowed" : "pointer", lineHeight: "18px", color: "#999999", border: "none"}}
                          disabled={disableAppCopyButton}
                          onClick={() => {
                            this.setState({appAffiliateLinkIsCopied: true})
                            navigator.clipboard.writeText(appAffiliateLink)
                            setTimeout(() => {
                              this.setState({appAffiliateLinkIsCopied: false})
                            }, 1000)
                          }}
                        >
                          {isDesktop ? "CLICK TO COPY" : "TAP TO COPY"}
                        </div>
                      )}
                    </div>
                    <div style={{textAlign: "center"}}>
                      <div style={{fontSize: "min(6vw, 25px)", textTransform: "uppercase", width: "calc(80% + 36px)", display: "inline-block", fontFamily: "Futura Medium", marginTop: "5vh", marginBottom: "1vh"}}>
                        {`Coach Certification`}
                      </div>
                      <div
                        disabled={disableCertificationCopyButton}
                        style={{overflow: "hidden", display: "inline-block", width: "80%", userSelect: completedStripeOnboarding ? "auto" : "none", cursor: disableCertificationCopyButton ? "not-allowed" : "pointer", border: "none", WebkitUserSelect: completedStripeOnboarding ? "auto" : "none", textOverflow: "ellipsis", whiteSpace: "nowrap", lineHeight: "18px", backgroundColor: "#000000", color: disableCertificationCopyButton ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF", fontSize: "18px", lineHeight: "18px", fontFamily: "Futura", padding: "12px 18px"}}
                        onClick={() => {
                          if (completedStripeOnboarding) {
                            this.setState({certificationAffiliateLinkIsCopied: true})
                            navigator.clipboard.writeText(certificationAffiliateLink)
                            setTimeout(() => {
                              this.setState({certificationAffiliateLinkIsCopied: false})
                            }, 1000)
                          }
                        }}
                      >
                        {certificationAffiliateLinkIsCopied ? "COPIED LINK!" : (completedStripeOnboarding ? certificationAffiliateLink.replace("https://", "") : "SETUP NEEDED")}
                      </div>
                      {completedStripeOnboarding && (
                        <div
                          style={{fontFamily: "Futura Medium", marginTop: "1vh", fontSize: "15px", cursor: disableCertificationCopyButton ? "not-allowed" : "pointer", lineHeight: "18px", color: "#999999", border: "none"}}
                          disabled={disableCertificationCopyButton}
                          onClick={() => {
                            this.setState({certificationAffiliateLinkIsCopied: true})
                            navigator.clipboard.writeText(certificationAffiliateLink)
                            setTimeout(() => {
                              this.setState({certificationAffiliateLinkIsCopied: false})
                            }, 1000)
                          }}
                        >
                          {isDesktop ? "CLICK TO COPY" : "TAP TO COPY"}
                        </div>
                      )}
                    </div>
                    <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "5vh"}}>
                      {`When someone makes a purchase through one of your affiliate links, they get 20% off, and you'll get 20% commission sent to your bank account.`}
                    </div>
                    <div style={{fontSize: "min(5.5vw, 22px)", color: ((affiliateProfileRequestResult.state !== REQUEST_SUCCESS) ? "#000000" : (completedStripeOnboarding ? "green" : "red")), transition: "color 150ms linear", textTransform: "uppercase", fontFamily: "Futura Medium", marginTop: "4vh", marginBottom: "2vh"}}>
                      {`AFFILIATE STATUS: ${(affiliateProfileRequestResult.state === REQUEST_FETCHING) ? "LOADING" : ((affiliateProfileRequestResult.state === REQUEST_ERROR) ? `There was an error loading your affiliate status. Please retry:` : (completedStripeOnboarding ? "ACTIVE" : "SETUP NEEDED"))}`}
                    </div>
                    <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                      {completedStripeOnboarding ? `Your affiliate links are ready to be shared. You can view your upcoming payouts and track earnings in real time through the Stripe Dashboard below:` : `Your affiliate links are not yet enabled. To activate them, please set up automated payouts through Stripe:`}
                    </div>
                    {(affiliateProfileRequestResult.state === REQUEST_ERROR) ? (
                      <button
                        style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: redirectingToPayoutsOnboarding ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                        onClick={() => {
                          onLoadAffiliateProfile()
                        }}
                      >
                        <span>
                          {"RETRY"}
                        </span>
                      </button>
                    ) : (
                      <div style={{display: "flex", justifyContent: "center", marginTop: "2vh"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "85%", maxWidth: 500, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: (redirectingToPayoutsOnboarding || loadingPayoutsDashboard) ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                          onClick={() => {
                            if (completedStripeOnboarding) {
                              onLoadPayoutsDashboard()
                            } else {
                              onStartPayoutsOnboarding()
                            }
                          }}
                        >
                          <span style={{width: 30, height: 30, marginInlineEnd: "0.2em"}}>
                            {(redirectingToPayoutsOnboarding || loadingPayoutsDashboard) ? (
                              <span style={{padding: "5px 5px"}}>
                                <OvalSpinnerSvg
                                  size={22}
                                  color={"rgba(255, 255, 255, 0.7)"}
                                />
                              </span>
                            ) : (
                              <AffiliateSvg size={"26px"} margin={"-3px 0px 0px 0px"} color={"#FFFFFF"} />
                            )}
                          </span>
                          <span>
                            {completedStripeOnboarding ? (loadingPayoutsDashboard ? "REDIRECTING..." : "OPEN PAYOUTS") : (redirectingToPayoutsOnboarding ? "REDIRECTING..." : "SET UP PAYOUTS")}
                          </span>
                        </button>
                      </div>
                      )}
                    </div>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
        <div
          style={{margin: "30px 0px 100px 0px", display: "flex", width: "100%", justifyContent: "center"}}
        >
          <div style={{padding: "min(5vw, 22px)", maxWidth: 600}}>
            <div>
              <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "2vh"}}>
                <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 600}}>
                  <div style={{fontSize: "min(7vw, 30px)",  textTransform: "uppercase", fontFamily: "Futura"}}>
                    {`Team Referrals`}
                  </div>
                  <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <div style={{display: "inline-block"}}>
                      <span>{"Refer the NEUROFIT App to a team or organization of 30 or more and earn bulk commission. Contact us for details at:"}</span>
                    </div>
                  </div>
                  <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura"}}>
                    <a href="mailto:support@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                      {"support@neurofit.app"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AffiliateProfileTab