import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  dashboardLoggedInPageUpdateFormField,
  dashboardLoggedInPageesetRequestState,
  dashboardLoggedInPageTriggerRequest,
  dashboardLoggedInPageUpdateRequestState,
  dashboardLoggedInPageUpdatePageState,
  resetDashboardLoggedInPage,
} from '../../actions/dashboardLoggedInPage';
import LoggedInPage from '../../components/dashboard/LoggedInPage';

import {
  commonUpdateAndroidDeferredPrompt,
  commonTriggerRequest,
} from '../../actions/common'

import {
  REQ_DASHBOARD_FETCH_APP_CONFIG,
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ_ACTION__CONFIGURE_IN_APP_PURCHASES,
} from '../../constants/requestTypes'

import {
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_BOOKED_SESSIONS,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_AFFILIATE,
  DASHBOARD_SECTION_GIFT_APP_ACCESS,
  DASHBOARD_SECTION_SETTINGS,
  DASHBOARD_SECTION_LOGOUT,
  DASHBOARD_SECTION_FAQ,
  DASHBOARD_SECTION_FEATURE_REQUESTS,
  DASHBOARD_SECTION_SUPPORT,
  DASHBOARD_SECTION_LEGAL_AND_DATA,
} from '../../constants/dashboardSections'

import {
  REQUEST_UNSTARTED,
} from '../../constants/requestStates';

import {
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_SAFE_AREA_INSETS,
  COMMON_ANDROID_DEFERRED_PROMPT,
  COMMON_IS_IOS_SAFARI,
  COMMON_IS_NATIVE,
  COMMON_APP_PLATFORM,
  COMMON_IN_APP_PURCHASE_PRODUCTS,
  COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_PRE_ORDER_PURCHASE_PRODUCTS,
  COMMON_CAMERA_ACCESS_PERMISSION_STATUS,
  COMMON_APP_STATE_IS_ACTIVE,
  COMMON_DEEPLINKED_AFFILIATE_ID,
} from '../../constants/commonState'

import {
  MODAL_VERIFY_CONTACT_INFO,
  MODAL_PRE_ORDER_CONVERSATION_MINUTES,
} from '../../constants/modalTypes'

import {
  currentTabIsValid,
} from '../../constants/dashboardSections'

import {
  DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID,
  DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID,
  DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID,
} from '../../constants/pageState'

import {
  setWindowUrlToBase,
  toggleBodyScroll,
  configureAnalyticsUser,
  recordSessionEventGA,
  recordSignupFunnelEventGA,
  initializeDashboardGA,
  SetUserFieldsUnix,
} from '../../utils'

import RedirectContainer from '../RedirectContainer'

class LoggedInContainer extends Component {

constructor(props){
    super(props)
    this.state = {}

  }

  async componentWillMount() {
    const {isSubscribedUser, loggedInUserData} = this.props

    const pathParams = this.props.location.pathname.split("/")
    const currentTab = pathParams[2]

    const selectedClientUserId = ((currentTab === DASHBOARD_SECTION_CLIENTS) && (pathParams.length >= 3)) ? pathParams[3] : undefined
    const selectedClientSubview = ((currentTab === DASHBOARD_SECTION_CLIENTS) && (pathParams.length >= 4)) ? pathParams[4] : undefined

    const selectedSessionId = ((currentTab === DASHBOARD_SECTION_BOOKED_SESSIONS) && (pathParams.length >= 3)) ? pathParams[3] : undefined
    const selectedSessionSubview = ((currentTab === DASHBOARD_SECTION_BOOKED_SESSIONS) && (pathParams.length >= 4)) ? pathParams[4] : undefined

    const selectedLeadId = ((currentTab === DASHBOARD_SECTION_QUIZ_LEADS) && (pathParams.length >= 3)) ? pathParams[3] : undefined
    const selectedLeadSubview = ((currentTab === DASHBOARD_SECTION_QUIZ_LEADS) && (pathParams.length >= 4)) ? pathParams[4] : undefined

    if (selectedClientUserId !== undefined) {
      this.props.dispatch(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID, parseInt(selectedClientUserId)))
    } else if (selectedSessionId !== undefined) {
      this.props.dispatch(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID, parseInt(selectedSessionId)))
    } else if (selectedLeadId !== undefined) {
      this.props.dispatch(dashboardLoggedInPageUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID, parseInt(selectedLeadId)))
    }

    initializeDashboardGA();
    SetUserFieldsUnix(loggedInUserData.created_at_unix)
    
    configureAnalyticsUser({
      uuid: loggedInUserData.uuid,
      createdAtUnix: loggedInUserData.created_at_unix,
    })
    await recordSessionEventGA("Logged In Page")
    this.props.dispatch(commonTriggerRequest(REQ_FETCH_DEPLOYMENT_CONFIG))
  }
  componentWillUnmount() {
    this.props.dispatch(resetDashboardLoggedInPage());
  }

  render() {
    const pathParams = this.props.location.pathname.split("/")
    const currentTab = pathParams[2]

    const selectedClientUserId = ((currentTab === DASHBOARD_SECTION_CLIENTS) && (pathParams.length >= 3)) ? pathParams[3] : undefined
    const selectedClientSubview = ((currentTab === DASHBOARD_SECTION_CLIENTS) && (pathParams.length >= 4)) ? pathParams[4] : undefined

    const selectedSessionId = ((currentTab === DASHBOARD_SECTION_BOOKED_SESSIONS) && (pathParams.length >= 3)) ? pathParams[3] : undefined
    const selectedSessionSubview = ((currentTab === DASHBOARD_SECTION_BOOKED_SESSIONS) && (pathParams.length >= 4)) ? pathParams[4] : undefined

    const selectedLeadId = ((currentTab === DASHBOARD_SECTION_QUIZ_LEADS) && (pathParams.length >= 3)) ? pathParams[3] : undefined
    const selectedLeadSubview = ((currentTab === DASHBOARD_SECTION_QUIZ_LEADS) && (pathParams.length >= 4)) ? pathParams[4] : undefined

    const isValidTab = currentTabIsValid(currentTab)
    return (
      isValidTab ? (
        <LoggedInPage
          // State
          formFields={this.props.formFields}
          requestResults={this.props.requestResults}
          currentTab={currentTab}
          selectedClientUserId={selectedClientUserId}
          selectedClientSubview={selectedClientSubview}
          selectedSessionId={selectedSessionId}
          selectedSessionSubview={selectedSessionSubview}
          selectedLeadId={selectedLeadId}
          selectedLeadSubview={selectedLeadSubview}
          innerHeight={this.props.innerHeight}
          innerWidth={this.props.innerWidth}
          safeAreaInsets={this.props.safeAreaInsets}
          loggedInUserData={this.props.loggedInUserData}
          pageState={this.props.pageState}
          countryCode={this.props.countryCode}
          // Actions
          onUpdateFormField={(key, value) => this.props.dispatch(dashboardLoggedInPageUpdateFormField(key, value))}
          onUpdateRequestState={(requestType, body) => this.props.dispatch(dashboardLoggedInPageUpdateRequestState(requestType, REQUEST_UNSTARTED, body))}
          onResetRequestState={requestType => this.props.dispatch(dashboardLoggedInPageUpdateRequestState(requestType, REQUEST_UNSTARTED))}
          onTriggerRequest={requestType => this.props.dispatch(dashboardLoggedInPageTriggerRequest(requestType))}
          onUpdatePageState={(key, value) => this.props.dispatch(dashboardLoggedInPageUpdatePageState(key, value))}
        />
      ) : (
        <RedirectContainer
          redirectTo={"/app/clients"}
        />
      )
    );
  }
}

LoggedInContainer.propTypes = {
  formFields: PropTypes.object.isRequired,
  requestResults: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  loggedInUserData: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  innerHeight: PropTypes.number.isRequired,
  innerWidth: PropTypes.number.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  ...state.dashboardLoggedInPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  innerWidth: state.common[COMMON_INNER_WIDTH],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
  isSubscribedUser: !!(state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result && state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.user_data.is_subscribed),
  loggedInUserData: state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result && state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.user_data,
  countryCode: state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.country_code !== "-" ? state.common[REQ_DASHBOARD_FETCH_APP_CONFIG].result.country_code : "US",
});

export default connect(mapStateToProps)(LoggedInContainer);
