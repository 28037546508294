import React from 'react'

import i18next from 'i18next'

import CompletionCheckIcon from './CompletionCheckIcon'
import LockSvg from './LockSvg'

import {
  formatUnitNumber,
} from '../../languageUtils'

const DailyPlanButton = ({
  isComplete,
  isDisabled,
  isCurrent,
  titlePrefix,
  title,
  durationMinutes,
  capitalizeTitle,
  onClick
}) => {
  const backgroundColor = isComplete ? "#ffffff" : "#ffffff"
  const boxShadow = "0px 1px 1.5px 0.5px #dddddd"
  const textColor = (isCurrent || isComplete) ? "#000000" : "#cfd2dd"
  const timingColor = (isCurrent || isComplete) ? "#000000" : "#cfd2dd"
  const capitalizeTitleText = (capitalizeTitle === undefined) ? true : capitalizeTitle
  const buttonIsDisabled = isDisabled !== undefined ? isDisabled : isComplete

  const showMinutes = (durationMinutes > 0)

  return (
    <button
      style={{display: "block", width: "100%", fontFamily: "Futura", padding: "min(4.5vw, 18px)", fontSize: "min(6vw, 25px)", marginTop: "min(4vh, 25px)", color: textColor, backgroundColor, textAlign: "center", boxShadow, border: "none"}}
      disabled={buttonIsDisabled || !isCurrent}
      onClick={() => onClick()}
    >
      <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
        <div style={{display: "flex", alignItems: "center"}}>
          {(isComplete || isCurrent) ? (
            <CompletionCheckIcon
              isComplete={isComplete}
            />
          ) : (
            <LockSvg
              height={"calc(min(4vw, 16px) + 6px)"}
              width={"calc(min(4vw, 16px) + 6px)"}
            />
          )}
          <div style={{marginInlineStart: "0.8em", maxWidth: showMinutes ? "calc(min(85vw, 500px) * 0.65)" : "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "start", color: textColor, textTransform: capitalizeTitleText ? "uppercase": "", fontSize: "min(5.5vw, 26px)", fontFamily: "Futura"}}>
            {title}
          </div>
        </div>
        {showMinutes && (
          <div style={{display: "flex", alignItems: "center"}}>
           
            <div style={{display: "inline-block", textTransform: "uppercase", marginInlineStart: 2, color: timingColor, fontSize: "min(3vw, 12px)", lineHeight: "min(3vw, 12px)", textAlign: "start", fontFamily: "Futura"}}>
              {formatUnitNumber(durationMinutes, "minute")}
            </div>
          </div>
        )}
      </div>
    </button>
  );
}

export default DailyPlanButton;