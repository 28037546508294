import React from 'react'

import i18next from 'i18next'

const TextFeedbackPrompt = ({
  marginTop,
  marginBottom,
  title,
  value,
  onChange,
  onConfirm,
  className,
  disabled,
  inputBackgroundColor,
  color,
  placeholderText
}) => {
  return (
    <div>
      {(!!title) && (
        <div className="lp-header" style={{fontSize: "min(5vw, 22px)", fontFamily : "Futura", marginTop: marginTop, marginBottom: marginBottom || "2vh", color: color || "#ffffff"}}>
          {title}
        </div>
      )}
      <textarea
        type="text"
        style={{backgroundColor: inputBackgroundColor || "#222222", color: color, cursor: disabled ? "not-allowed" : "pointer", caretColor: color}}
        className={className || "textarea-feedback"}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        onPaste={e => onChange(e.target.value)}
        placeholder={placeholderText || i18next.t('share_app_feedback_here')}
      />
    </div>
  );
}

export default TextFeedbackPrompt;