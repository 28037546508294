import React from 'react';

import {reloadApp} from '../utils'

class RestartAppPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {safeAreaInsets} = this.props
    return (
      <div style={{position: "absolute", top: 0, left: 0, bottom: 0, width: "100vw", zIndex: 1000000, backgroundColor: "#EDEEF2"}}>
        <div style={{position: "relative", top: 0, left: 0, height: safeAreaInsets.top, transition: "height 100ms linear 200ms", width: "100vw", backgroundColor: "#000000"}}>
        </div>
        <div style={{position: "absolute", top: 0, left: 0, width: "100vw", bottom: 0, justifyContent: "center", display: "flex", alignItems: "center"}}>
          <div className="lp-subheader" style={{maxWidth: 600, width: "85%"}}>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
              <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                  {`ERROR`}
                </div>
                <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Light"}}>
                  <div style={{display: "inline-block"}}>
                    <span>{"Something went wrong - please "}</span>
                    <div style={{display: "inline-block", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}} onClick={() => reloadApp()}>{"restart the app"}</div>
                    <span>{" to load the latest updates."}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "min(6vh, 30px)"}}>
              <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                  {`CONTACT SUPPORT`}
                </div>
                <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Light"}}>
                  <div style={{display: "inline-block"}}>
                    <span>{"If this problem persists, please contact our team at:"}</span>
                  </div>
                </div>
                <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura"}}>
                  <a href="mailto:support@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                    {"support@neurofit.app"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RestartAppPage;