import React from 'react'

import {
  getAppAffiliateLink,
  recordLinkClickAndRedirect,
} from '../../utils'

import {
  GA_LABEL_CLICKED_APP_AFFILIATE_LINK,
} from '../../constants/gaEventLabels'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


class TrainerDiscountedSessionBundlingExplanationModal extends React.Component {

  constructor(props){
     super(props)
  }

  render() {

    const {
      // State
      show,
      safeAreaInsets,
      innerHeight,
      onClose
    } = this.props

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="redeem-access-code-modal"
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: safeAreaInsets.top,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "all",
            backgroundColor: show ? "rgba(0, 0, 0, 0.2)" : "transparent",
            backdropFilter: show ? "blur(4px)" : "none",
            WebkitBackdropFilter: show ? "blur(4px)" : "none",
            zIndex: 1000000
          }}
          onClick={() => {
            onClose()
          }}
        >
          <div 
            id={"app-offline-modal"}
            style={{
              position: 'absolute',
              background: 'white',
              border: "2px black solid",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              width: "calc(90% - 4px)",
              maxWidth: 600,
              left: 0,
              right: 0,
              margin: "0px auto",
              top: (safeAreaInsets.top) / 2 + (innerHeight / 4),
              maxHeight: Math.min(600, (innerHeight - safeAreaInsets.top) / 1.5),
              pointerEvents: "all",
            }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <div style={{color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", height: "100%", color: "#000", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                <div>
                  <div>
                    <div style={{fontSize: "min(6vw, 24px)", marginTop: "4vh", textTransform: "uppercase", fontFamily: "Futura"}}>
                      {`Bundled Session Discounts`}
                    </div>
                    <div style={{padding: "min(2vw, 10px) 0px", fontSize: "min(5vw, 22px)"}}>
                      <div style={{fontFamily: "Futura Light"}}>
                        {`Recommended: Give clients discounts for purchasing more sessions upfront.`}
                      </div>
                      <div style={{fontFamily: "Futura Light", marginTop: "min(2vw, 10px)"}}>
                        {`Purchasing 6-9 sessions upfront = 15% off, and 10+ sessions upfront = 20% off (maximum 20 sessions).`}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  style={{display: "block", marginTop: "2vh", marginBottom: "4vh", pointerEvents: "all", textTransform: "uppercase", width: "100%", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                  onClick={() => {
                    onClose()
                  }}
                >
                  {"Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default TrainerDiscountedSessionBundlingExplanationModal;