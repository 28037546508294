import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import {
  nervousSystemStates,
} from '../../constants/nervousSystemStates'

import {
  InfoSvg
} from '../../constants/svgs'

import i18next from 'i18next'

const blurredDataStyle = {filter: "blur(4px)", WebkitFilter: "blur(4px)"}

const HabitBarChartInsightSection = ({
  title,
  dataIsAvailable,
  data,
  blurData,
  insightText,
  insightTextFunction,
  memberHabitStatistics,
  firstName,
  marginTop,
  onLearnMoreButtonClick
}) => (
	<div style={{display: "block", backgroundColor: "#FFFFFF", padding: "min(4vw, 20px)", marginTop: marginTop || "max(3vw, 16px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
	  <div style={{display: "inline-block", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(5vw, 22px)", lineHeight: "min(5vw, 22px)"}}>
	    {title}
	  </div>
	  {(dataIsAvailable) ? (
	    <div>
	      <div style={blurData ? blurredDataStyle : {}}>
          {data.map((result, index) => {
            const value = result.is_self_care ? `${result.value}/10}` : (result.value * 100.0).toFixed(0) + "%"
            const resultWidth = result.is_self_care ? `${(result.value * 10.0).toFixed(0) + "%"}` : `${(result.value * 100.0).toFixed(0) + "%"}`
            return (
              <div
                key={index}
                style={{marginTop: "min(4vh, 20px)"}}
              >
                <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(3.5vw, 16px)"}}>
                  {i18next.t(result.key) || result.name}
                </div>
                <div style={{width: "100%", height: "max(0.5vh, 3px)", marginTop: "min(1vh, 6px)", backgroundColor: "#DEE0E7"}}>
                  <div style={{width: resultWidth, height: "100%", backgroundColor: "#000000"}}>
                  </div>
                </div>
              </div>
            )
          })}
         </div>
	      <div style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)", marginTop: "min(4vh, 20px)"}}>
	        {!!(insightText) ? insightText : insightTextFunction(data, firstName, memberHabitStatistics)}
          {(!!(onLearnMoreButtonClick)) && (
            <span onClick={() => onLearnMoreButtonClick()} style={{display: "inline-block", padding: "0px 0.2em", cursor: "pointer"}}>
              <InfoSvg
                color={"#000000"}
                size={"min(3vw, 12px)"}
                backgroundColor={"#FFFFFF"}
              />
            </span>
          )}
	      </div>
	    </div>
	  ) : (
	    <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(7vw, 32px)", marginTop: "max(1vw, 6px)", color: "#000000"}}>
	      {i18next.t('pending')}
	    </div>
	  )}
	</div>
)


export default HabitBarChartInsightSection