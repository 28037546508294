import React from 'react'

import FaqSection from '../FaqSection'

import {
  ExternalLinkSvg,
} from '../../../constants/svgs'


const LegalLinks = [
  {
    name: "Platform Terms",
    url: "https://legal.neurofit.app/#terms"
  },
  {
    name: "Certification Terms",
    url: "https://legal.neurofit.app/certification/"
  },
  {
    name: "Privacy Policy",
    url: "https://legal.neurofit.app/#privacy"
  }
]

const CertifiedFAQItems = [  
  {
    key: "clients",
    is_header: true,
    text: "Client + App Setup"
  },
  {
    key: "onboarding_client",
    question: "How do I onboard a new client to the NEUROFIT app so that they show up in my dashboard?",
    answer: "Send them your Trainer Invite Code from the Clients view. All they need to do is go to Settings > Data > Link Trainer Account in the NEUROFIT app, and paste in your code."
  },
  {
    key: "how_view_insights",
    question: "How do I view a client's insights?",
    answer: "Click on a client's name from the Client List view - this will open the Client's insights profile."
  },
  {
    key: "client_app",
    question: "Do my clients have to use the app while working with me?",
    answer: "It is our strong recommendation that they do. From a responsible and trauma-aware perspective, it's vital that clients are supported in doing the everyday work needed to build nervous system resilience. Strong emotions and sensations can and often do surface during embodiment training. To skip out on using the app is comparable to hiring a personal trainer and then only lifting weights when you're in the gym together."
  },
  {
    key: "pre_purchase_clients",
    is_header: true,
    text: "Providing Client App Access"
  },
  {
    key: "provide_app_access",
    question: "How should I provide app access for clients?",
    answer: "To provide a seamless and streamlined experience, we recommend providing the NEUROFIT app for clients as part of your offering, and factoring it into your pricing accordingly. We recommend purchasing app access for clients via the `Gift App Access` tab in the Dashboard."
  },
  {
    key: "pre_purchasing",
    question: "How does pre-purchasing app access work?",
    answer: "Navigate to the `Gift App Access` tab, enter your new client's name and email address, and purchase app access. They'll receive a personalized email confirmation explaining how to redeem their app access, and how to link their app account to your Dashboard using your Trainer Invite Code."
  },
  {
    key: "practice",
    question: "My client has already created an app account. Which client email should I use when gifting app access?",
    answer: "If they already have signed up, use the email address linked to their app account (they can find this in Settings > Edit Account Details). Access will be automatically applied to their app account by our system."
  },
  {
    key: "save_card_details",
    question: "How can I save my card for easier checkout across multiple purchases?",
    answer: "Most modern desktop browsers such as Google Chrome, Safari or FireFox will allow you to save a credit card for future use when used for checkout in their browser. We recommend using the NEUROFIT Dashboard on desktop to take advantage of this feature."
  },
  {
    key: "cert_affiliates",
    is_header: true,
    text: "Affiliate"
  },
  {
    key: "discounts_commission",
    question: "How do affiliate discounts and commission work?",
    answer: "When new clients purchase the app or certification directly from your unique affiliate links, they'll get 20% off and you'll receive 20% commission to your bank account - this usually lands within 2-3 business days of a purchase. For the app, affiliate payouts are applied to the lifetime of a client's subscription."
  },
  {
    key: "how_affiliate",
    question: "How does someone use my affiliate links?",
    answer: "All they need to do is purchase certification or app access directly from your unique affiliate links. When done correctly, they will see a `20% OFF APPLIED` message at checkout - this means their purchase will be linked to your affiliate profile."
  },
  {
    key: "affiliates_vs_mlm",
    question: "Can others share my affiliate code on my behalf?",
    answer: "No - this is an MLM tactic which would violate our Certification Terms."
  },
  {
    key: "affiliate_payout_time",
    question: "How long do affiliate payouts take to land?",
    answer: "Most often, affiliate payouts take 2-3 business days to land in your bank account."
  }
]

const LimitedFAQItems = [  
  {
    key: "cert_affiliates",
    is_header: true,
    text: "Affiliate"
  },
  {
    key: "discounts_commission",
    question: "How do affiliate discounts and commission work?",
    answer: "When new clients purchase the app or certification directly from your unique affiliate links, they'll get 20% off and you'll receive 20% commission to your bank account - this usually lands within 2-3 business days of a purchase. For the app, affiliate payouts are applied to the lifetime of a client's subscription."
  },
  {
    key: "how_affiliate",
    question: "How does someone use my affiliate links?",
    answer: "All they need to do is purchase certification or app access directly from your unique affiliate links. When done correctly, they will see a `20% OFF APPLIED` message at checkout - this means their purchase will be linked to your affiliate profile."
  },
  {
    key: "affiliates_vs_mlm",
    question: "Can others share my affiliate code on my behalf?",
    answer: "No - this is an MLM tactic which would violate our Certification Terms."
  },
  {
    key: "affiliate_payout_time",
    question: "How long do affiliate payouts take to land?",
    answer: "Most often, affiliate payouts take 2-3 business days to land in your bank account."
  }
]

let defaultShowAnswer = {}
CertifiedFAQItems.map(key => defaultShowAnswer[key] = false)


class FaqTab extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showAnswer: defaultShowAnswer,
    }
  }

  render() { 
    const {showAnswer} = this.state
    const {isActiveTrainer, isDesktop} = this.props
    return (
      <div style={{marginTop: isDesktop ? 50 : 0}}>   
        <div
          style={{display: "flex", width: "100%", justifyContent: "center"}}
        >
          <div style={{width: "85%", maxWidth: 600}}>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
              <div style={{padding: "6vh 0px", width: "100%"}}>
                {(isActiveTrainer ? CertifiedFAQItems : LimitedFAQItems).map((f, idx) => (
                  (f.is_header ? (
                    <div key={f.key} style={{fontSize: "min(7vw, 32px)", textTransform: "uppercase", marginTop: ((idx === 0) && !isDesktop) ? 0 : "min(9vh, 45px)", color: "#000000", textAlign: "start", fontFamily: "Futura"}}>
                      {f.text}
                    </div>
                  ) : (
                    <FaqSection
                      key={f.key}
                      question={f.question}
                      answer={f.answer}
                      showAnswer={showAnswer[f.key]}
                      onToggleShowAnswer={() => this.setState({showAnswer: {...showAnswer, [f.key]: (!showAnswer[f.key])}})}
                      marginTop={"2vh"}
                    />
                  ))
                ))}
              </div>
            </div>
            <div style={{marginTop: 50}}>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura"}}>
                {`CONTACT SUPPORT`}
              </div>
              <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "2vh"}}>
                <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 600}}>
                  <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <div style={{display: "inline-block"}}>
                      <span>{"For Dashboard-related questions, help with affiliate onboarding, or to report a bug, please reach out to:"}</span>
                    </div>
                  </div>
                  <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura"}}>
                    <a href="mailto:support@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                      {"support@neurofit.app"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "space-around", width: "100%", margin: "100px 0px 0.5em 0px"}}>
          {LegalLinks.map(l => (
            <div key={l.name} onClick={() => window.open(l.url, "_blank", "noopener noreferrer")} style={{fontFamily: "Futura", display: "inline-block", fontSize: "min(3.5vw, 16px)", color: "#999", alignItems: "center", justifyContent: "start", cursor: "pointer", textDecoration: "underline", textUnderlineOffset: 2}}>
              <span>{l.name}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }
}


export default FaqTab