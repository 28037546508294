import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

import {
  InfoSvg,
} from '../../../constants/svgs'

import {
  DASHBOARD_SECTION_ONBOARDING,
  DASHBOARD_SECTION_CERTIFICATION,
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_BOOKED_SESSIONS,
  DASHBOARD_SECTION_COACH_COPILOT,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_BALANCE_TOOL,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_OFFER_COPILOT,
  DASHBOARD_SECTION_BUSINESS_SUITE,
  DASHBOARD_SECTION_CONTENT_COPILOT,
  DASHBOARD_SECTION_COACH_TRAINING,
  DASHBOARD_SECTION_AFFILIATE,
  DASHBOARD_SECTION_GIFT_APP_ACCESS,
  DASHBOARD_SECTION_SETTINGS,
  DASHBOARD_SECTION_LOGOUT,
  DASHBOARD_SECTION_FAQ,
  DASHBOARD_SECTION_FEATURE_REQUESTS,
  DASHBOARD_SECTION_SUPPORT,
  DASHBOARD_SECTION_LEGAL_AND_DATA,
  DASHBOARD_SECTION_ADMIN_SEO,
} from '../../../constants/dashboardSections'

const DashboardTopNavigationBar = ({
  isDesktop,
  selectedMenuSection,
}) => {
  const sectionMetadataMap = {
    [DASHBOARD_SECTION_ONBOARDING]: {
      title: "YOUR CHECKLIST",
      description: "Get set up on your NEUROFIT Coach Dashboard."
    },
    [DASHBOARD_SECTION_CERTIFICATION]: {
      title: "CERTIFICATION",
      description: "The NEUROFIT nervous system + somatics certification course."
    },
    [DASHBOARD_SECTION_CLIENTS]: {
      title: "MANAGE CLIENTS",
      description: "Connect, view insights, and monitor progress for your NEUROFIT clients."
    },
    [DASHBOARD_SECTION_BOOKED_SESSIONS]: {
      title: "BOOKED 1-ON-1 CLIENT SESSIONS",
      description: "Manage scheduling for NEUROFIT client sessions booked through your profile link."
    },
    [DASHBOARD_SECTION_COACH_COPILOT]: {
      title: "AI COACHING COPILOT",
      description: "Optimize your coaching results with real-time AI guidance on video client sessions."
    },
    [DASHBOARD_SECTION_COACH_TRAINING]: {
      title: "AI COACH TRAINING",
      description: "Sharpen your skills 6x faster as a coach with AI-guided training sessions + feedback."
    },
    // [DASHBOARD_SECTION_BALANCE_TOOL]: {
    //   title: "BALANCE TOOL",
    //   description: "Recommmend somatic exercises to your clients in real-time."
    // },
    [DASHBOARD_SECTION_EDIT_PROFILE]: {
      title: "COACHING PROFILE LINK",
      description: "Manage your profile details + link."
    },
    [DASHBOARD_SECTION_QUIZ_LEADS]: {
      title: "DISCOVERY QUIZ RESULTS",
      description: "Secure new clients by sending them a nervous system discovery quiz. Their responses and contact info will show up here."
    },
    [DASHBOARD_SECTION_CONTENT_COPILOT]: {
      title: "CONTENT COPILOT",
      description: "Create on-brand written + visual content 10x faster with AI."
    },
    [DASHBOARD_SECTION_OFFER_COPILOT]: {
      title: "AI OFFER COPILOT",
      description: "Chat with our AI Coach to figure out your core coaching offers, how they fit together, and how to launch most effectively."
    },
    [DASHBOARD_SECTION_BUSINESS_SUITE]: {
      title: "BUSINESS SUITE",
      description: "Access pre-vetted product templates, swipe files and more, designed to help you launch your next offer faster."
    },
    [DASHBOARD_SECTION_AFFILIATE]: {
      title: "AFFILIATE PROFILE",
      description: "Manage certification referral code details, view your upcoming payouts, and track earnings in real time."
    },
    [DASHBOARD_SECTION_GIFT_APP_ACCESS]: {
      title: "GIFT APP ACCESS",
      description: "Pre-purchase app access for any client using the checkout form below."
    },
    // [DASHBOARD_SECTION_SETTINGS]: {
    //   title: "SETTINGS",
    //   description: "Manage your Trainer Dashboard account settings."
    // },
    [DASHBOARD_SECTION_LOGOUT]: {
      title: "LOGGING YOU OUT",
      description: "You'll be redirected to the Home Page in a moment..."
    },
    [DASHBOARD_SECTION_FAQ]: {
      title: "FREQUENTLY ASKED",
      description: "Get answers to common questions about the Dashboard and working with clients."
    },
    // [DASHBOARD_SECTION_FEATURE_REQUESTS]: {
    //   title: "DASHBOARD FEATURE REQUESTS",
    //   description: "Have an idea for a new Dashboard Feature? Submit it here."
    // },
    [DASHBOARD_SECTION_SUPPORT]: {
      title: "DASHBOARD SUPPORT",
      description: "Reach out for support with the NeuroFit Dashboard here."
    },
    [DASHBOARD_SECTION_LEGAL_AND_DATA]: {
      title: "LEGAL & DATA",
      description: "Legal documents and data management for the NeuroFit Trainer Dashboard."
    },
    [DASHBOARD_SECTION_ADMIN_SEO]: {
      title: "SEO ADMIN CONSOLE",
      description: "Quickly write SEO-Optimized articles for the NEUROFIT Blog + App."
    }
  }

  const selectedSectionItem = sectionMetadataMap[selectedMenuSection]
  const sectionTitleElementId = `section-title-${selectedMenuSection}`
  
  return (
    <div
     style={{position: "fixed", top: 0, left: 0, right: 0, zIndex: 1, paddingInlineStart: isDesktop ? 240 : 70, backgroundColor: "#FFFFFF", paddingTop: 20, paddingBottom: 20, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}
    >
      <div style={{paddingInlineStart: 20}}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={500}
            classNames={"hide-fade-in"}
            unmountOnExit
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            key={sectionTitleElementId}
          >
            <div>
              <div style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                <span style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura"}}>
                  {selectedSectionItem.title}
                </span>
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  )
}

export default DashboardTopNavigationBar