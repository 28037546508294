import React from 'react'


const LockSvg = ({
	width,
	height
}) => {
  return (
    <svg style={{width: width || "100%", height: height || "100%"}} width="1080px" height="1080px" viewBox="0 0 1080 1080" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-35"><rect id="Rectangle" x="0" y="0" width="1080" height="1080"></rect><g id="noun-lock-747565-FFFFFF" transform="translate(128.000000, 0.000000)" fill="#cfd2dd" fillRule="nonzero"><path d="M723.843289,439.440165 L694.202289,439.440165 L694.081199,291.720165 C693.960109,130.800165 563.041199,-0.119835259 402.121199,8.23141305e-05 C241.201199,0.121254741 110.281199,131.040165 110.401199,291.960165 L110.639479,439.680165 L100.080479,439.680165 C44.7604792,439.801255 -0.119520795,484.680165 0.0002391234,540.000165 L0.0002391234,979.800165 C0.0002391234,1035.12016 45.0004792,1080.00016 100.320479,1080.00016 L724.080479,1079.64078 C779.400479,1079.64078 824.280479,1034.76178 824.160719,979.440785 L824.160719,539.520785 C824.160719,484.200785 779.160479,439.440785 723.840479,439.440785 L723.843289,439.440165 Z M218.163289,439.678445 L217.925009,291.958445 C217.925009,190.318445 300.484009,107.638445 402.125009,107.518445 C503.765009,107.518445 586.445009,190.198445 586.445009,291.718445 L586.683289,439.438445 L218.163289,439.678445 Z" id="Shape"></path></g></g></g></svg>
  )
}


export default LockSvg;