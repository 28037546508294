import React, { Suspense } from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  DASHBOARD_SECTION_ONBOARDING,
  DASHBOARD_SECTION_CERTIFICATION,
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_AFFILIATE,
} from '../../../constants/dashboardSections'

import DailyPlanButton from '../DailyPlanButton'

import {
  sanitizeEmailAddress,
  ProductionCertificationUrl,
  formatReadableUnixTimestampMins,
} from '../../../utils'

import {
  ActivityArrowSvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
} from '../../../constants/dashboardSvgs'


class ClearBusinessTab extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        codeIsCopied: false,
        clientDetails: {
          client_first_name: "",
          client_email_address: "",
          months_app_access: 0
        },
        selectedSubView: "create",
        dropDownIsOpen: false
     }
  }

  componentWillUnmount() {
    
  }

  render() {

    const {
      isActiveCoach,
      remainingOnboardingItemsCount,
      totalOnboardingItemsCount,
      completedOnboardingAffiliate,
      completedOnboardingProfileLink,
      completedOnboardingClientLead,
      completedOnboardingReplyClientLead,
      completedOnboardingClientConnected,
      completedOnboardingCertifiedCoach,
      onUpdateSelectedMenuSection,
      isDesktop
    } = this.props

    return (
      <div
        style={{marginTop: isDesktop ? 100 : 0, display: "flex", width: "100%", justifyContent: "center"}}
      >
        {isActiveCoach ? (
          <div style={{width: "min(85%, 1000px)"}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`YOUR CHECKLIST`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>

              <DailyPlanButton
                isComplete={completedOnboardingAffiliate}
                isCurrent
                title={completedOnboardingAffiliate ? "Payouts Activated 💎"  : "Set Up Payouts 💰"}
                onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_AFFILIATE)}
              />
              <DailyPlanButton
                isComplete={completedOnboardingProfileLink}
                isCurrent
                title={completedOnboardingProfileLink ? "Coach Profile Ready 🚀"  : "Make Coach Profile 🎨"}
                onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_EDIT_PROFILE)}
              />
              <DailyPlanButton
                isComplete={completedOnboardingClientLead}
                isCurrent
                title={completedOnboardingClientLead ? "Got A Client Lead 📨"  : "Get A Client Lead 📥"}
                onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_QUIZ_LEADS)}
              />
              <DailyPlanButton
                isComplete={completedOnboardingReplyClientLead}
                isCurrent
                title={completedOnboardingReplyClientLead ? "Replied To A Lead ✉️" : "Reply To A Lead 📤"}
                onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_QUIZ_LEADS)}
              />
              <DailyPlanButton
                isComplete={completedOnboardingClientConnected}
                isCurrent
                title={completedOnboardingClientConnected ? "Connected A Client 🤝"  : "Connect A Client 📲"}
                onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_CLIENTS)}
              />
              <DailyPlanButton
                isComplete={completedOnboardingCertifiedCoach}
                isCurrent
                title={completedOnboardingCertifiedCoach ? "You Got Certified 🏆"  : "Get Certified 🎓"}
                onClick={() => onUpdateSelectedMenuSection(DASHBOARD_SECTION_CERTIFICATION)}
              />
            </div>
          </div>
        ) : (
          <div style={{width: "85%", maxWidth: 1000}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`JOIN THE CERTIFICATION`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
              <span>{`Join the `}</span>
              <span style={{fontFamily: "Futura"}}>{`NEUROFIT Coach Certification`}</span>
              <span>{` to master nervous system + somatics and attract new clients 10x faster with our all-in-one coach training + tech + business platform:`}</span>
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  window.open(ProductionCertificationUrl, "_blank", "noopener noreferrer")
                }}
              >
                <span>
                  {"VIEW CERTIFICATION"}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ClearBusinessTab