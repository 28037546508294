import React from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import TopErrorMessage from '../common/TopErrorMessage'

import {
  REQ_DASHBOARD_SAVE_NEW_PASSWORD,
  REQ_ACTION__FLASH_TOP_ERROR_MESSAGE,
} from '../../constants/requestTypes'

import {
  FORM_FIELD_PASSWORD,
  FORM_FIELD_SHOW_TOP_ERROR_MESSAGE,
  FORM_FIELD_TOP_ERROR_MESSAGE_VALUE,
  FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE,
  FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE,
} from '../../constants/formFields'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR
} from '../../constants/requestStates';

import {
  NeuroFitNamedLogoSvg,
} from '../../constants/svgs'

import {
  MinPasswordLength,
} from '../../appUtils'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const BusinessConfirmResetPasswordPage = ({

  safeAreaInsets,
  innerHeight,
  formFields,
  requestResults,
  countryCode,
  passwordResetTokenIsValid,

  onUpdateFormField,
  onUpdateRequestState,
  onTriggerRequest

}) => {
  const newPassword = formFields[FORM_FIELD_PASSWORD] 
  const topMessageValue = formFields[FORM_FIELD_TOP_ERROR_MESSAGE_VALUE]
  const showTopMessage = formFields[FORM_FIELD_SHOW_TOP_ERROR_MESSAGE]

  const topSuccessMessageValue = formFields[FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE]
  const showTopSuccessMessage = formFields[FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE]

  const saveNewPasswordResult = requestResults[REQ_DASHBOARD_SAVE_NEW_PASSWORD]

  const formFieldsDisabled = saveNewPasswordResult.state === REQUEST_FETCHING
  const saveNewPasswordButtonDisabled = ((newPassword.length < MinPasswordLength) || (saveNewPasswordResult.state == REQUEST_FETCHING) || (saveNewPasswordResult.state == REQUEST_SUCCESS))

  const confirmNewPasswordButtonText = (saveNewPasswordResult.state == REQUEST_FETCHING) ? "SAVING PASSWORD..." : ((saveNewPasswordResult.state == REQUEST_SUCCESS) ? "SAVED. LOGGING IN..." : "SAVE AND LOG IN")

  return (
    <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
      <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
      <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
        <NeuroFitNamedLogoSvg
          logoSize={"min(4.25vw, 18px)"}
          color={"#000000"}
          onClick={() => window.location = "/"}
        />
        <button
          onClick={() => window.location = "/login"}
          className={"header-button"}
        >
          {"LOG IN"}
        </button>
      </div>
      <div 
        className={"disable-scrollbars"}
        style={containerStyle}
      >
        <div style={{textAlign: "start", width: "100vw"}}>
          <div style={{backgroundColor: "#EDEEF2", padding: "10vh 0px 10vh 0px", borderTop: "solid black 2px"}}>
            <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 600, color: "#000"}}>
                <div style={{backgroundColor: "#EDEEF2", padding: "min(24px, max(3vw, 16px))", marginTop: "max(6vw, 32px)"}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"SAVE NEW PASSWORD"}
                  </div>
                  <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    {"Set a new password for your Dashboard Account. After you save it, you'll be logged in."}
                  </div>
                  <div style={{marginTop: 16}}>
                    <input
                      type="password"
                      value={newPassword}
                      disabled={formFieldsDisabled}
                      autoCapitalize="none"
                      autoCorrect="false"
                      autoComplete="new-password"
                      spellCheck={false}
                      onChange={(e) => {
                        onUpdateFormField(FORM_FIELD_PASSWORD, e.target.value)
                      }}
                      onPaste={(e) => {
                        onUpdateFormField(FORM_FIELD_PASSWORD, e.target.value)
                      }}
                      placeholder={"New Password"}
                      className={"application-input"}
                    />
                  </div>
                  <button
                    style={{display: "block", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 32, color: saveNewPasswordButtonDisabled ? "rgba(255, 255, 255, 0.4)" : "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "12px 15px", border: "none"}}
                    disabled={saveNewPasswordButtonDisabled}
                    onClick={() => {
                      onTriggerRequest(REQ_DASHBOARD_SAVE_NEW_PASSWORD)
                    }}
                  >
                    {confirmNewPasswordButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopErrorMessage
        show={showTopMessage}
        message={topMessageValue}
        safeAreaInsets={safeAreaInsets}
      />
    </div>
  )
}

export default BusinessConfirmResetPasswordPage;