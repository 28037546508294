import React from 'react';

import { 
  subdomainHosts,
  subdomainUrls,
  resizeInnerHeight,
  resizeInnerWidth,
  asyncCallWithTimeout,
  recordFunnelEventGA,
  delay,
  initializeSentry,
  captureSentryException,
} from '../utils'

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// import { polyfill } from "seamless-scroll-polyfill";
import smoothscroll from 'smoothscroll-polyfill';

import DashboardAppContainer from '../containers/dashboard/DashboardAppContainer';
import RedirectContainer from '../containers/RedirectContainer'
import DashboardErrorBoundary from '../components/common/dashboard/DashboardErrorBoundary'

import {
  commonUpdateNativeAppOpenCount,
  commonTriggerRequest,
  commonUpdatePushNotificationToken,
  commonUpdateDeepLinkedArticleKey,
  commonUpdateShowMembershipModalIfNeeded,
  commonUpdateShortcutItemType,
  commonUpdateInnerHeight,
  commonUpdateInnerWidth,
  commonUpdateSafeAreaInsets,
  commonUpdateCameraPermissions,
  commonUpdateAppActionItemType,
  commonUpdateAppStateIsActive,
  commonUpdateCurrentAppVersion,
  commonUpdateNativeCompletionSoundPreloaded,
  commonUpdateIsDeeplinkedPaywallAction,
  commonUpdateIsDeeplinkedDiscoveryQuizAction,
  commonUpdateDeepLinkedCertificationAffiliateId,
  resetCommon,
} from '../actions/common';



import {
  COMMON_APP_OPEN_STORY_SHORT_CODE,
  COMMON_NATIVE_APP_OPEN_COUNT,
} from '../constants/commonState'

import {
  triggerRequest as loggedInPageTriggerRequest,
  loggedInPageUpdateHrvCaptureState,
  loggedInPageResetHrvCaptureState,
} from '../actions/loggedInPage'

import {
  HRV_CAPTURE_STATE_STATUS,
  HRV_CAPTURE_STATE_PROGRESS,
  HRV_CAPTURE_STATE_COMPLIANCE,
  HRV_CAPTURE_STATE_DATA,
  HRV_CAPTURE_STATE_FRAME_DROP_COMPLIANCE_ERROR_COUNT,
  HRV_CAPTURE_STATE_IS_RECONFIGURING_CAMERA,
} from '../constants/hrvCaptureState'

import {
  APP_ACTION_TYPE_LEARN,
  APP_ACTION_TYPE_BALANCE,
} from '../constants/appActionTypes'

import {
  REQ_LOGIN_USER,
  REQ_FETCH_APP_CONFIG,
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ__ACTION_AUTO_REFRESH_APP,
  REQ_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_TRIGGER_HRV_ANALYSIS_TASK,
  REQ__ACTION_UPDATE_APP_BADGE_COUNT,
  REQ__ACTION_HANDLE_APP_ACTION,
  REQ__ACTION_PRESENT_DEEPLINKED_ARTICLE,
  REQ__ACTION_HANDLE_NOTIFICATION_ACTION_TYPE,
  REQ__ACTION_PRESENT_MEMBERSHIP_MODAL,
  REQ__ACTION_PRESENT_DISCOVERY_QUIZ_MODAL,
  REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT,
  REQ__ACTION_OPEN_CREATORS_URL,
  REQ__ACTION_OPEN_CERTIFICATION_URL,
  REQ__ACTION_OPEN_TRAINING_REGISTRATION_URL,
} from '../constants/requestTypes'

import {
  GA_LABEL_HRV_MEASUREMENT_TRIGGERED_HRV_MEASUREMENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_25_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_50_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_75_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_100_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_COMPLETED,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_ERROR,
} from '../constants/gaEventLabels'


import RestartAppPage from '../components/RestartAppPage'

const safeAreaInsets = require('safe-area-insets')

// polyfill();

const mapStateToProps = state => ({state});

class DashboardApp extends React.Component {

  resizeAppContainer() {
    const innerHeight = resizeInnerHeight()
    this.props.dispatch(commonUpdateInnerHeight(innerHeight))

    const innerWidth = resizeInnerWidth()
    this.props.dispatch(commonUpdateInnerWidth(innerWidth))
  }

  configureGlobalJavascriptEventListeners() {
    smoothscroll.polyfill();

    // Safe Area Insets
    safeAreaInsets.onChange(updatedValue => this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets)))
    this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets))

    window.addEventListener('resize', () => {
      this.resizeAppContainer()
    })
    
    window.addEventListener("pageshow", async () => {
      this.resizeAppContainer()
      this.props.dispatch(commonTriggerRequest(REQ_FETCH_DEPLOYMENT_CONFIG))
      this.props.dispatch(commonTriggerRequest(REQ__ACTION_AUTO_REFRESH_APP))
    })

    // Check for frontend updates whenever app is shown.
    document.addEventListener("visibilitychange", async (e) => {
      if (!document.hidden) {
        this.props.dispatch(commonTriggerRequest(REQ_FETCH_DEPLOYMENT_CONFIG))
        this.props.dispatch(commonTriggerRequest(REQ__ACTION_AUTO_REFRESH_APP))
      }
      this.resizeAppContainer()
    })
  }

  async componentWillMount() {
    try {
      // Resize the app container to fit the available size.
      this.resizeAppContainer()

      // Configure Global Window Event Listeners.
      this.configureGlobalJavascriptEventListeners()
      await initializeSentry()
    } catch (err) {
      console.error(`[INITIALIZE]: Initial Configuration Error: ${err}`)
      await initializeSentry()
      await captureSentryException(err)
    }

  }

  render() {
    console.log(`DASHBOARD APP:`)
    console.log(this.props)
    return (
      <DashboardErrorBoundary>
        <Switch>
          <Route exact path="/" component={DashboardAppContainer} />
          <Route exact path="/signup" component={DashboardAppContainer} />
          <Route exact path="/login" component={DashboardAppContainer} />
          <Route exact path="/reset_password" component={DashboardAppContainer} />
          <Route path="/reset_password/:resetPasswordToken" component={DashboardAppContainer} />
          <Route path="/write_testimonial/:clientTestimonialToken" component={DashboardAppContainer} />
          <Route path="/app/clients/:clientUuid/insights" component={DashboardAppContainer} />
          <Route path="/app/clients/:clientUuid/discovery_quiz" component={DashboardAppContainer} />
          <Route path="/app/clients/:clientUuid/testimonial" component={DashboardAppContainer} />
          <Route path="/app/clients/:clientUuid/settings" component={DashboardAppContainer} />
          <Route path="/app/:currentTab" component={DashboardAppContainer} />
          <Route render={() => <RedirectContainer redirectTo="/app/clients" />} />
        </Switch>
      </DashboardErrorBoundary>
    );
  }
}

export default connect(mapStateToProps)(DashboardApp);
