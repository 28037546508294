import {
  LOGIN_PAGE_UPDATE_FORM_FIELD,
  LOGIN_PAGE_UPDATE_REQUEST_STATE,
  LOGIN_PAGE_TOGGLE_MODAL,
  LOGIN_PAGE_RESET_STATE,
} from '../constants/actionTypes'

import {
  REQ_CREATE_ACCOUNT,
  REQ_CREATE_ACCOUNT_APPLE,
  REQ_CREATE_ACCOUNT_GOOGLE,
  REQ_VALIDATE_USER_FOR_LOGIN,
  REQ_LOGIN_USER,
  REQ_LOGIN_USER_APPLE,
  REQ_LOGIN_USER_GOOGLE,
  REQ_SEND_RESET_PASSWORD_LINK,
  REQ_SAVE_NEW_PASSWORD,

  REQ_DASHBOARD_CREATE_ACCOUNT,
  REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL,
  REQ_DASHBOARD_LOGIN_USER,
  REQ_DASHBOARD_SEND_RESET_PASSWORD_LINK,
  REQ_DASHBOARD_SAVE_NEW_PASSWORD,
} from '../constants/requestTypes'

import {
  FORM_FIELD_SHOW_TOP_ERROR_MESSAGE,
  FORM_FIELD_TOP_ERROR_MESSAGE_VALUE,
  FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE,
  FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE,

  FORM_FIELD_FIRST_NAME,
  FORM_FIELD_LAST_NAME,
  FORM_FIELD_OCCUPATION,
  FORM_FIELD_PROFESSIONAL_URL,
  FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY,
  FORM_FIELD_EMAIL_ADDRESS,
  FORM_FIELD_PASSWORD,
  FORM_FIELD_AUTH_REDIRECT_PATH,

  FORM_FIELD_CLIENT_TESTIMONIAL_TEXT,
  FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME,
} from '../constants/formFields'

import {
  REQUEST_UNSTARTED,
  // REQUEST_SUCCESS
} from '../constants/requestStates'

import {
  MODAL_PRODUCT_ONBOARDING,
} from '../constants/modalTypes'

import { combineReducers } from 'redux';

const defaultFormFields = {
  [FORM_FIELD_FIRST_NAME]: "",
  [FORM_FIELD_LAST_NAME]: "",
  [FORM_FIELD_OCCUPATION]: "",
  [FORM_FIELD_PROFESSIONAL_URL]: "",
  [FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY]: "",
  [FORM_FIELD_EMAIL_ADDRESS]: "",
  [FORM_FIELD_PASSWORD]: "",
  [FORM_FIELD_AUTH_REDIRECT_PATH]: "",
  [FORM_FIELD_CLIENT_TESTIMONIAL_TEXT]: "",
  [FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME]: true
}
function formFields(
  state = defaultFormFields, action) {
  switch (action.type) {
    case LOGIN_PAGE_UPDATE_FORM_FIELD:
      return {...state, ...action.payload};
    case LOGIN_PAGE_RESET_STATE:
      return defaultFormFields;
    default:
      return state;
  }
}

const defaultRequestResults = {
  [REQ_CREATE_ACCOUNT]: {state: REQUEST_UNSTARTED},
  [REQ_CREATE_ACCOUNT_APPLE]: {state: REQUEST_UNSTARTED},
  [REQ_CREATE_ACCOUNT_GOOGLE]: {state: REQUEST_UNSTARTED},
  [REQ_VALIDATE_USER_FOR_LOGIN]: {state: REQUEST_UNSTARTED},
  [REQ_LOGIN_USER]: {state: REQUEST_UNSTARTED},
  [REQ_LOGIN_USER_APPLE]: {state: REQUEST_UNSTARTED},
  [REQ_LOGIN_USER_GOOGLE]: {state: REQUEST_UNSTARTED},
  [REQ_SEND_RESET_PASSWORD_LINK]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_NEW_PASSWORD]: {state: REQUEST_UNSTARTED},

  [REQ_DASHBOARD_CREATE_ACCOUNT]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_LOGIN_USER]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_SEND_RESET_PASSWORD_LINK]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_SAVE_NEW_PASSWORD]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_SAVE_CLIENT_TESTIMONIAL]: {state: REQUEST_UNSTARTED}
}
function requestResults(
  state = defaultRequestResults, action) {
  switch (action.type) {
    case LOGIN_PAGE_UPDATE_REQUEST_STATE:
      return {...state, [action.key]: {...action.payload}};
    case LOGIN_PAGE_RESET_STATE:
      return defaultRequestResults;
    default:
      return state;
  }
}

const defaultModalState = {

}
function showModals(
    state = defaultModalState, action) {
  switch (action.type) {
    case LOGIN_PAGE_TOGGLE_MODAL:
      return {...state, ...action.payload};
    case LOGIN_PAGE_RESET_STATE:
      return defaultModalState
    default:
      return state
  }
}

export default combineReducers({
  formFields,
  requestResults,
  showModals,
});
