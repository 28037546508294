import {
  LOGGED_IN_PAGE_UPDATE_FORM_FIELD,
  LOGGED_IN_PAGE_UPDATE_REQUEST_STATE,
  LOGGED_IN_PAGE_UPDATE_PAGE_STATE,
  LOGGED_IN_PAGE_TOGGLE_MODAL,
  LOGGED_IN_PAGE_UPDATE_HRV_CAPTURE_STATE,
  LOGGED_IN_PAGE_RESET_HRV_CAPTURE_STATE,
  LOGGED_IN_PAGE_UPDATE_CLEAR_JOURNAL_STATE,
  LOGGED_IN_PAGE_RESET_CLEAR_JOURNAL_STATE,
  LOGGED_IN_PAGE_RESET_STATE,
  LOGGED_IN_PAGE_RESET_MODALS_EXCEPT_FORCE_UPDATE,
} from '../constants/actionTypes'

import {
  REQ_LOGOUT_USER,
  REQ_ENABLE_FREE_PREMIUM_ACCESS,
  REQ_COMPLETE_ONBOARDING,
  REQ_SAVE_SELF_CHECK_IN,
  REQ_GET_PERSONALIZED_NEUROFIT_EXERCISE,
  REQ_SAVE_NEUROFIT_EXERCISE_COMPLETION,
  REQ_TRIGGER_HRV_ANALYSIS_TASK,
  REQ_CREATE_HRV_ANALYSIS_SIGNED_UPLOAD_URL,
  REQ_UPLOAD_HRV_READING_DATA,
  REQ_CREATE_HRV_ANALYSIS_TASK,
  REQ_RETRIEVE_HRV_ANALYSIS_RESULTS,
  REQ_GET_PERSONALIZED_LEARN_ARTICLES,
  REQ_GET_ARTICLE_METADATA,
  REQ_SAVE_ARTICLE_START,
  REQ_SAVE_ARTICLE_COMPLETION,
  REQ_SAVE_ARTICLE_RATING,
  REQ_SAVE_APP_FEEDBACK,
  REQ_INCREMENT_APP_REVIEW_REQUEST_COUNT,
  REQ_SAVE_USER_DISCOVERY_QUIZ_RESULT,
  REQ_SAVE_CONFIGURE_NOTIFICATIONS_SETTINGS,
  REQ_SAVE_RESET_PROGRAM,
  REQ_SAVE_UPDATED_ACCOUNT_DETAILS,
  REQ_SAVE_APP_EXPERIENCE_RATING,
  REQ_SAVE_PERSONALIZATION_ONBOARDING_RESULT,
  REQ_GET_CLEAR_JOURNAL_CONFIG,
  REQ_SAVE_CLEAR_JOURNAL_PREFERENCES,
  REQ_CREATE_CLEAR_JOURNAL_THREAD,
  REQ_FETCH_CLEAR_JOURNAL_THREAD_MESSAGE_HISTORY,
  REQ_SEND_CLEAR_JOURNAL_CHAT_MESSAGE,
  REQ_SAVE_CLEAR_JOURNAL_CHAT_REACTION,
  REQ_ADMIN_GRANT_APP_ACCESS,
  REQ_ADMIN_CREATE_TRAINER_DASHBOARD_INVITE,
  REQ_GET_TRAINER_LINKED_STATUS,
  REQ_UPDATE_TRAINER_LINKED_STATUS,
  REQ_RESTORE_NATIVE_PURCHASES,
  REQ_DELETE_ACCOUNT,
} from '../constants/requestTypes'


import {
  MODAL_PROFILE_SETTINGS,
  MODAL_REDEEM_ACCESS_CODE,
  MODAL_NEUROFIT_INTRO,
  MODAL_NEUROFIT_SELF_CHECK_IN,
  MODAL_NEUROFIT_HRV_MEASUREMENT,
  MODAL_NEUROFIT_TRAINING,
  MODAL_NEUROFIT_TRAINING_SCIENCE_EXPLANATION,
  MODAL_NEUROFIT_TRAINING_OPEN_CLEAR,
  MODAL_NEUROFIT_EXERCISE_MUSIC_LINKS,
  MODAL_NEUROFIT_EXERCISE_ONBOARDING,
  MODAL_NEUROFIT_EXERCISE_RECOMMENDATION_EXPLANATION,
  MODAL_HRV_SCIENCE_EXPLANATION,
  MODAL_HEART_RATE_SCIENCE_EXPLANATION,
  MODAL_RESPIRATION_RATE_SCIENCE_EXPLANATION,
  MODAL_NEUROFIT_RECOVERY_SCIENCE_EXPLANATION,
  MODAL_LEARN_ARTICLE,
  MODAL_LEARN_ARTICLE_GLOSSARY,
  MODAL_LEARN_ARTICLE_CONDENSED_EXPLANATION_MODAL,
  MODAL_CLEAR_JOURNAL,
  MODAL_APP_FEEDBACK,
  MODAL_FORCE_APP_UPDATE,
  MODAL_MEMBERSHIP_PAYWALL,
  MODAL_PRODUCT_ONBOARDING,
  MODAL_DELETE_ACCOUNT,
  MODAL_COMPLETED_SELF_CHECK_IN,
  MODAL_APP_MAINTENANCE_WALL,
  MODAL_CONFIGURE_NOTIFICATIONS,
  MODAL_CUSTOMIZE_APP_ICON,
  MODAL_ANDROID_SELECT_APP_LANGUAGE,
  MODAL_RESET_PROGRAM,
  MODAL_FORCE_REFRESH_APP,
  MODAL_DYSREGULATION_INFO,
  MODAL_CLEAR_BREAKTHROUGH_EXPLANATION,
  MODAL_CLEAR_BREAKTHROUGH_ONBOARDING,
  MODAL_NEUROFIT_SIGNAL_QUALITY_EXPLANATION,
  MODAL_NEUROFIT_READING_RESULTS_EXPLANATION,
  MODAL_HRV_ONBOARDING,
  MODAL_ANDROID_OS_CAMERA_NOT_SUPPORTED,
  MODAL_TRAINER_CERTIFICATION,
  MODAL_TEAMS_OPT_IN,
  MODAL_MASTER_COACH_CERTIFICATION,
  MODAL_NERVOUS_SYSTEM_COURSE,
  MODAL_N_PLUS,
  MODAL_PURCHASE_NERVOUS_SYSTEM_COURSE,
  MODAL_PURCHASE_CERTIFICATION,
  MODAL_CONTACT_SUPPORT,
  MODAL_FAQ,
  MODAL_EMBODIMENT_DATA,
  MODAL_SCIENTIFIC_SOURCES,
  MODAL_TERMS_PRIVACY,
  MODAL_PAID_CREATOR_APPLICATION,
  MODAL_UPDATE_ACCOUNT_DETAILS,
  MODAL_MANAGE_BILLING,
  MODAL_LINK_TRAINER_ACCOUNT,
  MODAL_PERSONALIZATION_ONBOARDING,
  MODAL_NEUROFIT_DISCOVERY_QUIZ,
  MODAL_ADMIN_GRANT_APP_ACCESS,
  MODAL_ADMIN_CREATE_TRAINER_DASHBOARD_INVITE,
  MODAL_NETWORK_OFFLINE,
} from '../constants/modalTypes'

import {
  FORM_FIELD_SELECTED_PRODUCT_ID,
  FORM_FIELD_IS_ORDERING_PRODUCT,
  FORM_FIELD_ACCESS_CODE,
} from '../constants/formFields'

import {
  PAGE_STATE_EXCLUDED_EXERCISE_IDS,
  PAGE_STATE_SELECTED_NEUROFIT_EXERCISE_INDEX,
  PAGE_STATE_SELECTED_NEUROFIT_EXERCISE_ARRAY,
  PAGE_STATE_SELECTED_ARTICLE_KEY,
  PAGE_STATE_SELECTED_ARTICLE_CATEGORY,
  PAGE_STATE_SELECTED_EXERCISE_CATEGORY,
  PAGE_STATE_HIDE_SELECT_APP_ICON_NOTIFICATION,
  PAGE_STATE_ARTICLE_SEARCH_QUERY_TEXT,
  PAGE_STATE_HIDE_LEARN_MODAL_BACK_BUTTON,
  PAGE_STATE_SELECTED_ARTICLE_RATING,
  PAGE_STATE_SELECTED_ARTICLE_IS_COPIED,
  PAGE_STATE_SELECTED_BALANCE_EXERCISE_IS_COPIED,
  PAGE_STATE_SHOW_CAMERA_VIEW,
  PAGE_STATE_SELECTED_AVERAGE_CHECK_IN_INDEX,
  PAGE_STATE_AVERAGE_CHECK_IN_DROPDOWN_OPEN,
  PAGE_STATE_LIFE_ASPECT_INSIGHT_TEXT,
  PAGE_STATE_HABIT_INSIGHT_TEXT,
  PAGE_STATE_SELECTED_GLOSSARY_TERM_INDEX,
  PAGE_STATE_EXERCISE_COMPLETION_TIMESTAMP,
  PAGE_STATE_APP_LINK_IS_COPIED,
  PAGE_STATE_TOP_HABIT,
  PAGE_STATE_TOP_LIFE_ASPECT,
  PAGE_STATE_SELECTED_DYSREGULATION_INFO_STATE,
  PAGE_STATE_SHOULD_CLEAR_PROGRAM_DATA_ON_RESET,
  PAGE_STATE_CONFIRM_RESET_PROGRAM,
  PAGE_STATE_SHOULD_LOG_OUT_ON_SAVE_ACCOUNT_DETAILS,
  PAGE_STATE_SHOW_RATE_EXPERIENCE_PANEL,
  PAGE_STATE_RATE_EXPERIENCE_PANEL_STARS,
  PAGE_STATE_LINK_TRAINER_INVITE_CODE,
  PAGE_STATE_PREMIUM_SUBSCRIPTION_PAYWALL_HAS_ENTERED,
  PAGE_STATE_HIDE_INSIGHT_NOTIFICATION_SECTION,
} from '../constants/pageState'

import {
  HRV_CAPTURE_STATE_STATUS,
  HRV_CAPTURE_STATE_PROGRESS,
  HRV_CAPTURE_STATE_COMPLIANCE,
  HRV_CAPTURE_STATE_DATA,
  HRV_CAPTURE_STATE_FRAME_DROP_COMPLIANCE_ERROR_COUNT,
  HRV_CAPTURE_STATE_TASK_PROGRESS,
  HRV_CAPTURE_STATE_TASK_MAX_PROGRESS,
  HRV_CAPTURE_STATE_IS_RECONFIGURING_CAMERA,
  HRV_CAPTURE_STATE_IS_LOW_POWER_MODE_ENABLED,
} from '../constants/hrvCaptureState'

import {
  CLEAR_JOURNAL_STATE_SELECTED_LIFE_ASPECT,
  CLEAR_JOURNAL_STATE_SELECTED_LIFE_ASPECT_CURRENT_STATE,
  CLEAR_JOURNAL_STATE_CHAT_MESSAGE_TEXT,
  CLEAR_JOURNAL_STATE_CHAT_MESSAGE_REACTION
} from '../constants/clearJournalState'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS
} from '../constants/requestStates'

import { combineReducers } from 'redux';

const defaultFormFields = {
  [FORM_FIELD_SELECTED_PRODUCT_ID]: "",
  [FORM_FIELD_IS_ORDERING_PRODUCT]: false,
  [FORM_FIELD_ACCESS_CODE]: "",
}
function formFields(
  state = defaultFormFields, action) {
  switch (action.type) {
    case LOGGED_IN_PAGE_UPDATE_FORM_FIELD:
      return {...state, ...action.payload};
    case LOGGED_IN_PAGE_RESET_STATE:
      return defaultFormFields;
    default:
      return state;
  }
}

const defaultPageState = {
  [PAGE_STATE_EXCLUDED_EXERCISE_IDS]: [],
  [PAGE_STATE_SELECTED_NEUROFIT_EXERCISE_INDEX]: 0,
  [PAGE_STATE_SELECTED_NEUROFIT_EXERCISE_ARRAY]: "",
  [PAGE_STATE_SELECTED_ARTICLE_KEY]: "",
  [PAGE_STATE_SELECTED_ARTICLE_CATEGORY]: {key: "", value: ""},
  [PAGE_STATE_SELECTED_EXERCISE_CATEGORY]: {key: "", value: ""},
  [PAGE_STATE_HIDE_SELECT_APP_ICON_NOTIFICATION]: false,
  [PAGE_STATE_ARTICLE_SEARCH_QUERY_TEXT]: "",
  [PAGE_STATE_HIDE_LEARN_MODAL_BACK_BUTTON]: true,
  [PAGE_STATE_SELECTED_ARTICLE_RATING]: -1,
  [PAGE_STATE_SELECTED_ARTICLE_IS_COPIED]: false,
  [PAGE_STATE_SELECTED_BALANCE_EXERCISE_IS_COPIED]: false,
  [PAGE_STATE_SHOW_CAMERA_VIEW]: false,
  [PAGE_STATE_SELECTED_AVERAGE_CHECK_IN_INDEX]: 0,
  [PAGE_STATE_AVERAGE_CHECK_IN_DROPDOWN_OPEN]: false,
  [PAGE_STATE_LIFE_ASPECT_INSIGHT_TEXT]: "",
  [PAGE_STATE_HABIT_INSIGHT_TEXT]: "",
  [PAGE_STATE_SELECTED_GLOSSARY_TERM_INDEX]: 0,
  [PAGE_STATE_EXERCISE_COMPLETION_TIMESTAMP]: null,
  [PAGE_STATE_APP_LINK_IS_COPIED]: false,
  [PAGE_STATE_TOP_HABIT]: undefined,
  [PAGE_STATE_TOP_LIFE_ASPECT]: undefined,
  [PAGE_STATE_SELECTED_DYSREGULATION_INFO_STATE]: undefined,
  [PAGE_STATE_SHOULD_CLEAR_PROGRAM_DATA_ON_RESET]: undefined,
  [PAGE_STATE_CONFIRM_RESET_PROGRAM]: undefined,
  [PAGE_STATE_SHOULD_LOG_OUT_ON_SAVE_ACCOUNT_DETAILS]: false,
  [PAGE_STATE_SHOW_RATE_EXPERIENCE_PANEL]: false,
  [PAGE_STATE_RATE_EXPERIENCE_PANEL_STARS]: undefined,
  [PAGE_STATE_LINK_TRAINER_INVITE_CODE]: "",
  [PAGE_STATE_PREMIUM_SUBSCRIPTION_PAYWALL_HAS_ENTERED]: false,
  [PAGE_STATE_HIDE_INSIGHT_NOTIFICATION_SECTION]: false
}
function pageState(
  state = defaultPageState, action) {
  switch (action.type) {
    case LOGGED_IN_PAGE_UPDATE_PAGE_STATE:
      return {...state, ...action.payload}
    case LOGGED_IN_PAGE_RESET_STATE:
      return defaultPageState;
    default:
      return state;
  }
}

const defaultRequestResults = {
  [REQ_LOGOUT_USER]: {state: REQUEST_UNSTARTED},
  [REQ_ENABLE_FREE_PREMIUM_ACCESS]: {state: REQUEST_UNSTARTED},
  [REQ_COMPLETE_ONBOARDING]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_SELF_CHECK_IN]: {state: REQUEST_UNSTARTED},
  [REQ_GET_PERSONALIZED_NEUROFIT_EXERCISE]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_NEUROFIT_EXERCISE_COMPLETION]: {state: REQUEST_UNSTARTED},
  [REQ_TRIGGER_HRV_ANALYSIS_TASK]: {state: REQUEST_UNSTARTED},
  [REQ_CREATE_HRV_ANALYSIS_SIGNED_UPLOAD_URL]: {state: REQUEST_UNSTARTED},
  [REQ_UPLOAD_HRV_READING_DATA]: {state: REQUEST_UNSTARTED},
  [REQ_CREATE_HRV_ANALYSIS_TASK]: {state: REQUEST_UNSTARTED},
  [REQ_RETRIEVE_HRV_ANALYSIS_RESULTS]: {state: REQUEST_UNSTARTED},
  // [REQ_RETRIEVE_HRV_ANALYSIS_RESULTS]: {state: REQUEST_SUCCESS, result: {status: "complete", result: {heart_rate: 57, hrv_rmssd: 52, respiration_rate: 11.66, neurofit_recovery_score: 1, signal_quality: 1}}},
  [REQ_GET_PERSONALIZED_LEARN_ARTICLES]: {state: REQUEST_UNSTARTED},
  [REQ_GET_ARTICLE_METADATA]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_ARTICLE_START]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_ARTICLE_COMPLETION]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_ARTICLE_RATING]: {state: REQUEST_UNSTARTED},
  [REQ_GET_CLEAR_JOURNAL_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_APP_FEEDBACK]: {state: REQUEST_UNSTARTED},
  [REQ_INCREMENT_APP_REVIEW_REQUEST_COUNT]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_USER_DISCOVERY_QUIZ_RESULT]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_CONFIGURE_NOTIFICATIONS_SETTINGS]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_RESET_PROGRAM]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_UPDATED_ACCOUNT_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_APP_EXPERIENCE_RATING]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_PERSONALIZATION_ONBOARDING_RESULT]: {state: REQUEST_UNSTARTED},
  [REQ_GET_CLEAR_JOURNAL_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_CLEAR_JOURNAL_PREFERENCES]: {state: REQUEST_UNSTARTED},
  [REQ_CREATE_CLEAR_JOURNAL_THREAD]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_CLEAR_JOURNAL_THREAD_MESSAGE_HISTORY]: {state: REQUEST_UNSTARTED},
  [REQ_SEND_CLEAR_JOURNAL_CHAT_MESSAGE]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_CLEAR_JOURNAL_CHAT_REACTION]: {state: REQUEST_UNSTARTED},
  [REQ_ADMIN_GRANT_APP_ACCESS]: {state: REQUEST_UNSTARTED},
  [REQ_ADMIN_CREATE_TRAINER_DASHBOARD_INVITE]: {state: REQUEST_UNSTARTED},
  [REQ_GET_TRAINER_LINKED_STATUS]: {state: REQUEST_UNSTARTED},
  [REQ_UPDATE_TRAINER_LINKED_STATUS]: {state: REQUEST_UNSTARTED},
  [REQ_RESTORE_NATIVE_PURCHASES]: {state: REQUEST_UNSTARTED},
  [REQ_DELETE_ACCOUNT]: {state: REQUEST_UNSTARTED},
}
function requestResults(
  state = defaultRequestResults, action) {
  switch (action.type) {
    case LOGGED_IN_PAGE_UPDATE_REQUEST_STATE: {
      return {...state, [action.key]: {...action.payload}};
    }
    case LOGGED_IN_PAGE_RESET_STATE:
      return defaultRequestResults;
    default:
      return state;
  }
}

const defaultModalState = {
  [MODAL_PROFILE_SETTINGS]: false,
  [MODAL_REDEEM_ACCESS_CODE]: false,
  [MODAL_NEUROFIT_INTRO]: false,
  [MODAL_NEUROFIT_SELF_CHECK_IN]: false,
  [MODAL_NEUROFIT_HRV_MEASUREMENT]: false,
  [MODAL_NEUROFIT_TRAINING]: false,
  [MODAL_NEUROFIT_TRAINING_SCIENCE_EXPLANATION]: false,
  [MODAL_NEUROFIT_EXERCISE_MUSIC_LINKS]: false,
  [MODAL_NEUROFIT_EXERCISE_ONBOARDING]: false,
  [MODAL_NEUROFIT_EXERCISE_RECOMMENDATION_EXPLANATION]: false,
  [MODAL_HRV_SCIENCE_EXPLANATION]: false,
  [MODAL_HEART_RATE_SCIENCE_EXPLANATION]: false,
  [MODAL_RESPIRATION_RATE_SCIENCE_EXPLANATION]: false,
  [MODAL_NEUROFIT_RECOVERY_SCIENCE_EXPLANATION]: false,
  [MODAL_LEARN_ARTICLE]: false,
  [MODAL_LEARN_ARTICLE_GLOSSARY]: false,
  [MODAL_LEARN_ARTICLE_CONDENSED_EXPLANATION_MODAL]: false,
  [MODAL_CLEAR_JOURNAL]: false,
  [MODAL_APP_FEEDBACK]: false,
  [MODAL_FORCE_APP_UPDATE]: false,
  [MODAL_MEMBERSHIP_PAYWALL]: false,
  [MODAL_PRODUCT_ONBOARDING]: false,
  [MODAL_DELETE_ACCOUNT]: false,
  [MODAL_COMPLETED_SELF_CHECK_IN]: false,
  [MODAL_APP_MAINTENANCE_WALL]: false,
  [MODAL_CONFIGURE_NOTIFICATIONS]: false,
  [MODAL_CUSTOMIZE_APP_ICON]: false,
  [MODAL_ANDROID_SELECT_APP_LANGUAGE]: false,
  [MODAL_RESET_PROGRAM]: false,
  [MODAL_FORCE_REFRESH_APP]: false,
  [MODAL_DYSREGULATION_INFO]: false,
  [MODAL_CLEAR_BREAKTHROUGH_EXPLANATION]: false,
  [MODAL_CLEAR_BREAKTHROUGH_ONBOARDING]: false,
  [MODAL_NEUROFIT_SIGNAL_QUALITY_EXPLANATION]: false,
  [MODAL_NEUROFIT_READING_RESULTS_EXPLANATION]: false,
  [MODAL_HRV_ONBOARDING]: false,
  [MODAL_ANDROID_OS_CAMERA_NOT_SUPPORTED]: false,
  [MODAL_TRAINER_CERTIFICATION]: false,
  [MODAL_TEAMS_OPT_IN]: false,
  [MODAL_MASTER_COACH_CERTIFICATION]: false,
  [MODAL_NERVOUS_SYSTEM_COURSE]: false,
  [MODAL_N_PLUS]: false,
  [MODAL_PURCHASE_NERVOUS_SYSTEM_COURSE]: false,
  [MODAL_PURCHASE_CERTIFICATION]: false,
  [MODAL_CONTACT_SUPPORT]: false,
  [MODAL_FAQ]: false,
  [MODAL_EMBODIMENT_DATA]: false,
  [MODAL_SCIENTIFIC_SOURCES]: false,
  [MODAL_TERMS_PRIVACY]: false,
  [MODAL_PAID_CREATOR_APPLICATION]: false,
  [MODAL_UPDATE_ACCOUNT_DETAILS]: false,
  [MODAL_MANAGE_BILLING]: false,
  [MODAL_LINK_TRAINER_ACCOUNT]: false,
  [MODAL_PERSONALIZATION_ONBOARDING]: false,
  [MODAL_NEUROFIT_DISCOVERY_QUIZ]: false,
  [MODAL_NETWORK_OFFLINE]: false,
  [MODAL_ADMIN_GRANT_APP_ACCESS]: false,
  [MODAL_ADMIN_CREATE_TRAINER_DASHBOARD_INVITE]: false,
}
function showModals(
    state = defaultModalState, action) {
  switch (action.type) {
    case LOGGED_IN_PAGE_TOGGLE_MODAL:
      return {...state, ...action.payload};
    case LOGGED_IN_PAGE_RESET_STATE:
      return defaultModalState
    case LOGGED_IN_PAGE_RESET_MODALS_EXCEPT_FORCE_UPDATE:
      return {...defaultModalState, [MODAL_FORCE_APP_UPDATE]: state[MODAL_FORCE_APP_UPDATE]}
    default:
      return state
  }
}

const defaultHrvCaptureState = {
  [HRV_CAPTURE_STATE_STATUS]: {state: "pre-reading", error: ""},
  [HRV_CAPTURE_STATE_PROGRESS]: 0.0,
  [HRV_CAPTURE_STATE_COMPLIANCE]: undefined,
  [HRV_CAPTURE_STATE_DATA]: undefined,
  [HRV_CAPTURE_STATE_FRAME_DROP_COMPLIANCE_ERROR_COUNT]: 0,
  [HRV_CAPTURE_STATE_TASK_PROGRESS]: 0.0,
  [HRV_CAPTURE_STATE_TASK_MAX_PROGRESS]: 0.0,
  [HRV_CAPTURE_STATE_IS_RECONFIGURING_CAMERA]: false,
  [HRV_CAPTURE_STATE_IS_LOW_POWER_MODE_ENABLED]: false
}
function hrvCaptureState(
  state = defaultHrvCaptureState, action) {
  switch (action.type) {
    case LOGGED_IN_PAGE_UPDATE_HRV_CAPTURE_STATE:
      return {...state, ...action.payload}
    case LOGGED_IN_PAGE_RESET_STATE:
    case LOGGED_IN_PAGE_RESET_HRV_CAPTURE_STATE:
      return defaultHrvCaptureState;
    default:
      return state;
  }
}

const defaultClearJournalState = {
  [CLEAR_JOURNAL_STATE_SELECTED_LIFE_ASPECT]: {},
  [CLEAR_JOURNAL_STATE_SELECTED_LIFE_ASPECT_CURRENT_STATE]: "",
  [CLEAR_JOURNAL_STATE_CHAT_MESSAGE_TEXT]: "",
  [CLEAR_JOURNAL_STATE_CHAT_MESSAGE_REACTION]: ""
}
function clearJournalState(
  state = defaultClearJournalState, action) {
  switch (action.type) {
    case LOGGED_IN_PAGE_UPDATE_CLEAR_JOURNAL_STATE:
      return {...state, ...action.payload}
    case LOGGED_IN_PAGE_RESET_STATE:
    case LOGGED_IN_PAGE_RESET_CLEAR_JOURNAL_STATE:
      return defaultClearJournalState;
    default:
      return state;
  }
}


export default combineReducers({
  formFields,
  pageState,
  requestResults,
  showModals,
  hrvCaptureState,
  clearJournalState,
});
