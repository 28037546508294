export const PAGE_STATE_EXCLUDED_EXERCISE_IDS = 'excludedExerciseIds'
export const PAGE_STATE_SELECTED_NEUROFIT_EXERCISE_INDEX = 'selectedExerciseIndex'
export const PAGE_STATE_SELECTED_NEUROFIT_EXERCISE_ARRAY = 'selectedExerciseArray'
export const PAGE_STATE_SELECTED_ARTICLE_KEY = 'selectedArticleKey'
export const PAGE_STATE_SELECTED_ARTICLE_RATING = 'selectedArticleRating'
export const PAGE_STATE_SELECTED_ARTICLE_IS_COPIED = 'selectedArticleIsCopied'
export const PAGE_STATE_SELECTED_BALANCE_EXERCISE_IS_COPIED = 'selectedBalanceExerciseIsCopied'
export const PAGE_STATE_SHOW_CAMERA_VIEW = 'showCameraView'
export const PAGE_STATE_SELECTED_AVERAGE_CHECK_IN_INDEX = 'selectedAverageCheckInIndex'
export const PAGE_STATE_AVERAGE_CHECK_IN_DROPDOWN_OPEN = 'averageCheckInDropDownOpen'
export const PAGE_STATE_LIFE_ASPECT_INSIGHT_TEXT = 'lifeAspectInsightText'
export const PAGE_STATE_HABIT_INSIGHT_TEXT = 'habitInsightText'
export const PAGE_STATE_SELECTED_GLOSSARY_TERM_INDEX = 'selectedGlossaryTermIndex'
export const PAGE_STATE_EXERCISE_COMPLETION_TIMESTAMP = 'exerciseCompletionTimestamp'
export const PAGE_STATE_APP_LINK_IS_COPIED = 'appLinkIsCopied'
export const PAGE_STATE_TOP_HABIT = 'keyTakeawaysTopHabit'
export const PAGE_STATE_TOP_LIFE_ASPECT = 'keyTakeawaysTopLifeAspect'
export const PAGE_STATE_SELECTED_DYSREGULATION_INFO_STATE = 'selectedDysregulationInfo'
export const PAGE_STATE_SELECTED_ARTICLE_CATEGORY = 'selectedArticleCategory'
export const PAGE_STATE_SELECTED_EXERCISE_CATEGORY = 'selectedExerciseCategory'
export const PAGE_STATE_SELECTED_APP_ICON = 'selectedAppIcon'
export const PAGE_STATE_HIDE_SELECT_APP_ICON_NOTIFICATION = 'hideSelectAppIconNotification'
export const PAGE_STATE_ARTICLE_SEARCH_QUERY_TEXT = 'articleSearchQueryText'
export const PAGE_STATE_HIDE_LEARN_MODAL_BACK_BUTTON = 'hideLearnModalBackButton'
export const PAGE_STATE_SHOULD_CLEAR_PROGRAM_DATA_ON_RESET = 'shouldClearProgramDataOnReset'
export const PAGE_STATE_CONFIRM_RESET_PROGRAM = 'confirmResetProgram'
export const PAGE_STATE_SHOULD_LOG_OUT_ON_SAVE_ACCOUNT_DETAILS = 'shouldLogOutOnSaveAccountDetails'
export const PAGE_STATE_SHOW_RATE_EXPERIENCE_PANEL = 'showRateExperiencePanel'
export const PAGE_STATE_RATE_EXPERIENCE_PANEL_STARS = 'rateExperiencePanelStars'
export const PAGE_STATE_PERSONALIZATION_TASK_PROGRESS = 'personalizationTaskProgress'
export const PAGE_STATE_PERSONALIZATION_TASK_MAX_PROGRESS = 'personalizationTaskMaxProgress'
export const PAGE_STATE_LINK_TRAINER_INVITE_CODE = 'linkTrainerInviteCode'
export const PAGE_STATE_PREMIUM_SUBSCRIPTION_PAYWALL_HAS_ENTERED = 'premiumSubscrptionPaywallHasEntered'
export const PAGE_STATE_HIDE_INSIGHT_NOTIFICATION_SECTION = 'hideInsightNotificationSection'

// Dashboard
export const DASHBOARD_PAGE_STATE_SELECTED_MENU_SECTION = 'dashboardSelectedMenuSection'
export const DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID = 'selectedClientId'
export const DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID = 'selectedSessionId'
export const DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID = 'selectedLeadId'

export const DASHBOARD_PAGE_STATE_TOP_HABIT = 'topHabit'
export const DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT = 'topHabitText'
export const DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT = 'topLifeAspect'
export const DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT = 'topLifeAspectText'