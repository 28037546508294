import i18next from 'i18next'

export const getMetaForRecoveryScore = (score, timeOfDay, firstName) => {
  if ((!(score)) || (score < 0)) {
    return {name: "Creating Recovery Baseline...", color: "#FFFFFF", description: i18next.t('insights_hrv_personalized_creating_baseline', {name: firstName})}
  } else if (score < 50) {
    return {name: "Depleted", color: "#FFC8E3", description: i18next.t(`insights_recovery_fatigued_${(timeOfDay === "evening") ? "tonight" : "today"}`, {name: firstName})}
  } else if (score < 67) {
    return {name: "Recovering", color: "#FAE4C7", description: i18next.t(`insights_recovery_recovering_${timeOfDay}`, {name: firstName})}
  } else if (score < 80) {
    return {name: "Balanced", color: "#CFF2D2", description: i18next.t(`insights_recovery_refreshed_${timeOfDay}`, {name: firstName})}
  } else if (score <= 100) {
    return {name: "Restored", color: "#BBEDBF", description: i18next.t(`insights_recovery_highly_refreshed_${timeOfDay}`, {name: firstName})}
  }
}

export const getMetaForRecoveryScoreHomePage = (score, dataTimeOfDay, timeOfDay, firstName) => {
  if ((!(score)) || (score < 0)) {
    return {name: "Creating Recovery Baseline...", color: "#FFFFFF", description: i18next.t('insights_hrv_personalized_creating_baseline', {name: firstName})}
  } else if (score < 50) {
    return {name: "Depleted", color: "#FFC8E3", description: i18next.t(`insights_fatigued_${dataTimeOfDay}`, {name: firstName})}
  } else if (score < 67) {
    return {name: "Recovering", color: "#FAE4C7", description: i18next.t(`insights_recovering_${dataTimeOfDay}`, {name: firstName})}
  } else if (score < 80) {
    return {name: "Balanced", color: "#CFF2D2", description: i18next.t(`insights_refreshed_${dataTimeOfDay}`, {name: firstName})}
  } else if (score <= 100) {
    return {name: "Restored", color: "#BBEDBF", description: i18next.t(`insights_highly_refreshed_${dataTimeOfDay}`, {name: firstName})}
  }
}

export const getMetaForHeartRateVariability = (value, isBaselineAvailable, valueBaseline, valueBaselineStdDev) => {
  if (!isBaselineAvailable) {
    return {name: "Balanced", color: "#000000", description: i18next.t('insights_hrv_creating_baseline')}
  } else {
    const baselineDeviation = (value - valueBaseline)
    if (Math.abs(baselineDeviation) < (1.5 * valueBaselineStdDev)) {
      return {name: "Balanced", color: "#000000", description: i18next.t('insights_hrv_balanced')}
    } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
      return {name: "Increased", color: "#93E29A", description: i18next.t('insights_hrv_above_baseline')}
    } else {
      return {name: "Reduced", color: "#FF7CBC", description: i18next.t('insights_hrv_below_baseline')}
    }
  }
}

export const getMetaForHeartRate = (value, isBaselineAvailable, valueBaseline, valueBaselineStdDev) => {
  if (!isBaselineAvailable) {
    if (value < 40) {
      return {name: "Reduced", color: "#FF7CBC", description: i18next.t('insights_hr_reduced')}
    } else if (value < 75) {
      return {name: "Balanced", color: "#000000", description: i18next.t('insights_hr_balanced')}
    } else if (value < 85) {
      return {name: "Slightly Elevated", color: "#000000", description: i18next.t('insights_hr_slightly_elevated')}
    } else {
      return {name: "Elevated", color: "#FF7CBC", description: i18next.t('insights_hr_elevated')}
    }
  } else {
    const baselineDeviation = (value - valueBaseline)
    if (baselineDeviation > (2.5 * valueBaselineStdDev)) {
      return {name: "Elevated", color: "#FF7CBC", description: i18next.t('insights_hr_elevated')}
    } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
      return {name: "Slightly Elevated", color: "#000000", description: i18next.t('insights_hr_slightly_elevated')}
    } else if (baselineDeviation < (-2.5 * valueBaselineStdDev)) {
      return {name: "Reduced", color: "#FF7CBC", description: i18next.t('insights_hr_reduced')}
    } else {
      return {name: "Balanced", color: "#000000", description: i18next.t('insights_hr_balanced')}
    }
  }
}

export const getMetaForRespirationRate = (value, isBaselineAvailable, valueBaseline, valueBaselineStdDev) => {
  if (!isBaselineAvailable) {
    if (value < 9) {
      return {name: "Reduced", color: "#FF7CBC", description: i18next.t('insights_rr_reduced')}
    } else if (value < 18) {
      return {name: "Balanced", color: "#FFFFFF", description: i18next.t('insights_rr_balanced')}
    } else if (value < 20) {
      return {name: "Slightly Elevated", color: "#000000", description: i18next.t('insights_rr_slightly_elevated')}
    } else {
      return {name: "Elevated", color: "#FF7CBC", description: i18next.t('insights_rr_elevated')}
    }
  } else {
    const baselineDeviation = (value - valueBaseline)
    if (baselineDeviation > (2.5 * valueBaselineStdDev)) {
      return {name: "Elevated", color: "#FF7CBC", description: i18next.t('insights_rr_elevated')}
    } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
      return {name: "Slightly Elevated", color: "#000000", description: i18next.t('insights_rr_slightly_elevated')}
    } else if (baselineDeviation < (-2.5 * valueBaselineStdDev)) {
      return {name: "Reduced", color: "#FF7CBC", description: i18next.t('insights_rr_reduced')}
    } else {
      return {name: "Balanced", color: "#000000", description: i18next.t('insights_rr_balanced')}
    }
  }
}

export const getInsightForTodayStatePercentages = (data, firstName) => {
  const positivePercentages = (data.parasympathetic || 0.0) + (data.stillness || 0.0) + (data.play || 0.0)
  const stillPercentages = (data.stillness || 0.0) + (data.dorsal_vagal || 0.0)
  const energizedPercentages = (data.play || 0.0) + (data.sympathetic || 0.0)
  let result = ""
  if (positivePercentages >= 0.875) {
    return i18next.t('insight_check_in_today_highly_balanced', {name: firstName})
  } else if (positivePercentages >= 0.75) {
    result = i18next.t('insight_check_in_today_balanced', {name: firstName})
    if (stillPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_movement')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_stillness')
    }
  } else if (positivePercentages >= 0.625) {
    result = i18next.t('insight_check_in_today_fairly_balanced', {name: firstName})
    if (stillPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_movement')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_stillness')
    }
  } else if (positivePercentages >= 0.5) {
    result = i18next.t('insight_check_in_today_use_balancing', {name: firstName})
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_make_sure_embodiment')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_make_sure_stillness')
    } else if (stillPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_make_sure_movement')
    }
  } else {
    result = i18next.t('insight_check_in_today_needs_balancing', {name: firstName})
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_important_embodiment')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_important_stillness')
    } else if (stillPercentages >= 0.25) {
      result +=" " + i18next.t('insight_check_in_important_movement')
    }
  }

  return result
}


export const getInsightForOverallAverageStatePercentage = (data, firstName) => {
  const positivePercentages = (data.parasympathetic || 0.0) + (data.stillness || 0.0) + (data.play || 0.0)
  const stillPercentages = (data.stillness || 0.0) + (data.dorsal_vagal || 0.0)
  const energizedPercentages = (data.play || 0.0) + (data.sympathetic || 0.0)
  let result = ""

  if (positivePercentages >= 0.875) {
    return i18next.t('insight_check_in_recent_highly_balanced', {name: firstName})
  } else if (positivePercentages >= 0.75) {
    result = i18next.t('insight_check_in_recent_balanced', {name: firstName})
  } else if (positivePercentages >= 0.625) {
    result = i18next.t('insight_check_in_recent_fairly_balanced', {name: firstName})
    if (stillPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_ensure_movement')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_ensure_stillness')
    }
  } else if (positivePercentages >= 0.5) {
    result = i18next.t('insight_check_in_recent_use_balancing', {name: firstName})
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_make_sure_embodiment')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_make_sure_stillness')
    } else if (stillPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_make_sure_movement')
    }
  } else {
    result = i18next.t('insight_check_in_recent_needs_balancing', {name: firstName})
    if (stillPercentages >= 0.25 && energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_take_time_embodiment')
    } else if (energizedPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_take_time_stillness')
    } else if (stillPercentages >= 0.25) {
      result += " " + i18next.t('insight_check_in_greater_balance_take_time_movement')
    }
  }

  return result
}

export const getInsightForLifeAspectAverageStatePercentage = (data, firstName) => {
  const positivePercentages = (data.parasympathetic || 0.0) + (data.stillness || 0.0) + (data.play || 0.0)
  const stillPercentages = (data.stillness || 0.0) + (data.dorsal_vagal || 0.0)
  const energizedPercentages = (data.play || 0.0) + (data.sympathetic || 0.0)
  let result = ""

  if (positivePercentages >= 0.875) {
    return i18next.t('insight_life_aspect_recent_highly_balanced', {name: firstName})
  } else if (positivePercentages >= 0.75) {
    return i18next.t('insight_life_aspect_recent_balanced', {name: firstName})
  } else if (positivePercentages >= 0.625) {
    return i18next.t('insight_life_aspect_recent_fairly_balanced', {name: firstName})
  } else if (positivePercentages >= 0.5) {
    return  i18next.t('insight_life_aspect_recent_use_balancing', {name: firstName})
  } else {
    return i18next.t('insight_life_aspect_recent_needs_balancing', {name: firstName})
  }
}

export const MIN_HABIT_SOLID_THRESHOLD = 0.75
export const MIN_HABIT_SATISFACTORY_THRESHOLD = 0.6
export const MIN_HABIT_ACCEPTABLE_THRESHOLD = 0.5

export const MIN_SELF_CARE_HABIT_SOLID_THRESHOLD = 7.5
export const MIN_SELF_CARE_HABIT_SATISFACTORY_THRESHOLD = 6
export const MIN_SELF_CARE_HABIT_ACCEPTABLE_THRESHOLD = 5

export const getInsightForEmbodiment = (data, firstName) => {
  const solidAreas = data.filter(d => (d.value >= MIN_HABIT_SOLID_THRESHOLD)).length
  const satisfactoryAreas = data.filter(d => (d.value >= MIN_HABIT_SATISFACTORY_THRESHOLD)).length
  const acceptableAreas = data.filter(d => (d.value >= MIN_HABIT_ACCEPTABLE_THRESHOLD)).length
  if (solidAreas === data.length) {
    return i18next.t('insights_embodiment_success', {name: firstName})
  } else if (satisfactoryAreas === data.length) {
    return i18next.t('insights_embodiment_good', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_HABIT_SOLID_THRESHOLD)
  } else if (acceptableAreas === data.length) {
    return i18next.t('insights_embodiment_use_some_attention', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_HABIT_SATISFACTORY_THRESHOLD)
  } else {
    return i18next.t('insights_embodiment_needs_attention', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_HABIT_ACCEPTABLE_THRESHOLD)
  }
}
export const getInsightForAvoidance = (data, firstName) => {
  const solidAreas = data.filter(d => (d.value >= MIN_HABIT_SOLID_THRESHOLD)).length
  const satisfactoryAreas = data.filter(d => (d.value >= MIN_HABIT_SATISFACTORY_THRESHOLD)).length
  const acceptableAreas = data.filter(d => (d.value >= MIN_HABIT_ACCEPTABLE_THRESHOLD)).length
  if (solidAreas === data.length) {
    return i18next.t('insights_avoidance_success', {name: firstName})
  } else if (satisfactoryAreas === data.length) {
    return i18next.t('insights_avoidance_good', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_HABIT_SOLID_THRESHOLD)
  } else if (acceptableAreas === data.length) {
    return i18next.t('insights_avoidance_use_some_attention', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_HABIT_SATISFACTORY_THRESHOLD)
  } else {
    return i18next.t('insights_avoidance_needs_attention', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_HABIT_ACCEPTABLE_THRESHOLD)
  }
}
export const getInsightForSelfCare = (data, firstName) => {
  const solidAreas = data.filter(d => (d.value >= MIN_SELF_CARE_HABIT_SOLID_THRESHOLD)).length
  const satisfactoryAreas = data.filter(d => (d.value >= MIN_SELF_CARE_HABIT_SATISFACTORY_THRESHOLD)).length
  const acceptableAreas = data.filter(d => (d.value >= MIN_SELF_CARE_HABIT_ACCEPTABLE_THRESHOLD)).length
  if (solidAreas === data.length) {
    return i18next.t('insights_nourish_success', {name: firstName})
  } else if (satisfactoryAreas === data.length) {
    return i18next.t('insights_self_care_good', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_SELF_CARE_HABIT_SOLID_THRESHOLD)
  } else if (acceptableAreas === data.length) {
    return i18next.t('insights_self_care_use_some_attention', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_SELF_CARE_HABIT_SATISFACTORY_THRESHOLD)
  } else {
    return i18next.t('insights_self_care_needs_attention', {name: firstName}) + getLocalizedMinHabitStringBelowThreshold(data, MIN_SELF_CARE_HABIT_ACCEPTABLE_THRESHOLD)
  }
}

export const MIN_BALANCE_TRAINING_EFFECTIVENESS = 0.75
export const SATISFACTORY_BALANCE_TRAINING_EFFECTIVENESS = 0.85
export const MIN_BALANCE_TRAINING_PERSONAL_COUNT = 10


const getLocalizedMinHabitStringBelowThreshold = (data, threshold, trimPrefix="", upperCase=false) => {
  const habits = data.sort((a, b) => a.value - b.value).filter(d => (d.value < threshold))
  if (habits.length === 0) {
    return ""
  } else {
    const selectedHabit = habits[0]
    return (" " + i18next.t(`momentum_insight_${selectedHabit.key}`, ""))
  }
}



export const joinWithAnd = values => {
  if (values.length === 1) {
    return values[0]
  } else if (values.length === 2) {
    return `${values[0]} and ${values[1]}`
  } else {
    return `${values.slice(0, values.length - 1).join(", ")}, and ${values[values.length - 1]}`
  }
}


export const replaceParasympathetic = (mood) => {
  return mood === "parasympathetic" ? "ventral vagal" : mood
}

export const scoreCheckInState = state => {
  const checkInStateMap = {
    "parasympathetic": 1.0,
    "stillness": 0.7,
    "play": 0.7,
    "dorsal_vagal": 0.3,
    "sympathetic": 0.3,
    "overwhelm": 0
  }
  return checkInStateMap[state] || 0.0
}

export const isRegulatedState = state => (scoreCheckInState(state) > 0.5)
export const isStillnessExercise = exercise => (exercise.score_result_play < exercise.score_result_stillness)

export const IOSAppRatingUrl = "https://apps.apple.com/us/app/id1630278170?action=write-review"
export const AndroidAppRatingUrl = "https://play.google.com/store/apps/details?id=com.neurofit.app"

export const IOSAppStoreSubscriptionUrl = "https://apps.apple.com/account/subscriptions"
export const AndroidPlayStoreSubscriptionUrl = "https://play.google.com/store/account/subscriptions"
export const StripeBillingPortalBaseUrl = "https://billing.stripe.com/p/login/7sIdSm35s1KbbyU000"

export const createBillingPortalUrl = stripe_email_address => `${StripeBillingPortalBaseUrl}?prefilled_email=${encodeURIComponent(stripe_email_address)}`

export const MIN_HRV_READING_SUCCESS_RATE = 0.75
export const MinSignalQuality = 2
export const MinPasswordLength = 8

export const NeuroFitCompletionSoundAsset = "neurofit-completion"
export const NeuroFitCompletionSoundFilepath = "neurofit_completion.wav"
export const MinCompletionSoundVolumeLevel = 1.0
export const ExerciseCompletionNotificationId = 90019001
export const ExerciseCompletionNotificationChannelId = "neurofit_exercise_channel"
export const DailyInsightNotificationChannelId = "neurofit_insights_channel"

export const HrvReadingDurationAndroid = 90
export const HrvReadingDurationDefault = 60

export const HrvProgressPercentageHideInstructions = 10

export const HRV_MEASUREMENT_PROGRESS_25_PERCENT = 25
export const HRV_MEASUREMENT_PROGRESS_50_PERCENT = 50
export const HRV_MEASUREMENT_PROGRESS_75_PERCENT = 75
export const HRV_MEASUREMENT_PROGRESS_100_PERCENT = 100

export const DEFAULT_SESSION_ID_LENGTH = 20

export const PROGRAM_DURATION_WEEKS = 6

export const getRandomlyDistributedWindDownNotificationTimeMinutes = () => {
  const defaultWindDownNotificationTimeMinutes = 1200
  const windDownVariationMinutes = 30
  const windDownIncrementMinutes = 5
  const windowSizeMinutes = windDownVariationMinutes / windDownIncrementMinutes * 1.0
  const jitterMinutes = (Math.round(Math.random() * windowSizeMinutes * 2) - windowSizeMinutes) * windDownIncrementMinutes;
  return defaultWindDownNotificationTimeMinutes + jitterMinutes;
}
