import React from 'react'

const PercentageRing = ({
  sizeVw,
  maxSizePixels,
  progressPercentage,
  ringColor,
  backgroundRingColor,
  isInProgress,
  fillLogoOnPause,
  innerWidth,
  animationDurationMs
}) => {

  let progressRingColor = ringColor || "#000000"
  let bgRingColor = backgroundRingColor || "#BBBBBB"

  const radiusWidthPercentage = 47

  const componentWidthPixels = Math.min(innerWidth * sizeVw / 100.0, maxSizePixels)
  const radius = componentWidthPixels * radiusWidthPercentage / 100.0
  const circumference = 2 * Math.PI * radius
  const progressArcLength = circumference * progressPercentage / 100.0

  return (
    <div style={{width: `min(${sizeVw}vw, ${maxSizePixels}px)`, height: `min(${sizeVw}vw, ${maxSizePixels}px)`, display: "grid", placeItems: "center", gridTemplateAreas: "inner-div", padding: "2px", boxShadow: `0px 0px ${Math.min(maxSizePixels / 40, 2)}px ${Math.min(maxSizePixels / 80, 1)}px rgba(237, 238, 242, 0.4)`, backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)", borderRadius: "50%", overflow: "visible"}}>
      <svg style={{gridArea: "inner-div", overflow: "visible", width: `min(${sizeVw}vw, ${maxSizePixels}px)`, height: `min(${sizeVw}vw, ${maxSizePixels}px)`, transform: "rotate(-90deg)"}} strokeWidth={`min(${sizeVw/16.0}vw, ${maxSizePixels/16.0}px)`} width="200" height="200" viewport="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle style={{overflow: "visible", width:  "100%", height: "100%"}} stroke={bgRingColor} className="svg-progress-circle-bar" r={`${radiusWidthPercentage}%`} cx="50%" cy="50%" fill="transparent" strokeDasharray={circumference} strokeDashoffset="0"></circle>
        <circle style={{overflow: "visible", width: "100%", height: "100%", transition: `stroke-dashoffset ${animationDurationMs !== undefined ? animationDurationMs : 200}ms linear`}} stroke={progressRingColor} r={`${radiusWidthPercentage}%`} cx="50%" cy="50%" fill="transparent" strokeDasharray={circumference} strokeDashoffset={circumference - progressArcLength}></circle>
      </svg>
      <span style={{gridArea: "inner-div", fontFamily: "Futura Medium", color: ringColor, borderRadius: "50%", fontSize: `min(${sizeVw*0.3}vw, ${maxSizePixels*0.3}px)`, overflow: "visible"}}>
        {progressPercentage.toFixed(0) + "%"}
      </span>
    </div>
  )
}

export default PercentageRing;