import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect, withRouter } from 'react-redux'
import { Redirect } from 'react-router-dom';

import {
  REQUEST_SUCCESS,
  REQUEST_ERROR,
  REQUEST_FETCHING,
  REQUEST_UNSTARTED,
} from '../../constants/requestStates'

import LoginPageContainer from './LoginPageContainer';
import SignupPageContainer from './SignupPageContainer';
import LandingPageContainer from './LandingPageContainer';
import LoggedInContainer from './LoggedInContainer';
import AppLoadingPageContainer from './AppLoadingPageContainer';
import SendResetPasswordLinkPageContainer from './SendResetPasswordLinkPageContainer';
import ConfirmResetPasswordPageContainer from './ConfirmResetPasswordPageContainer';
import WriteClientTestimonialPageContainer from './WriteClientTestimonialPageContainer'

import Helmet from '../../components/common/Helmet'

import smoothscroll from 'smoothscroll-polyfill';

import {
  commonTriggerRequest,
  commonUpdateResetPasswordToken,
  commonUpdateClientTestimonialToken,
  commonUpdateQuizResultKey,
  resetCommon,
} from '../../actions/common';

import {
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ_DASHBOARD_FETCH_APP_CONFIG,
  REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN,
} from '../../constants/requestTypes'

class DashboardAppContainer extends Component {
  async componentDidMount() {
    // Kick off requests to load the app.
    this.props.dispatch(commonTriggerRequest(REQ_FETCH_DEPLOYMENT_CONFIG))

    // Run initial requests here.
    if (this.props.location.pathname.startsWith("/app") || this.props.location.pathname === "/login" || this.props.location.pathname === "/signup") {
      this.props.dispatch(commonTriggerRequest(REQ_DASHBOARD_FETCH_APP_CONFIG))
    } else if (this.props.location.pathname.startsWith("/reset_password/") && (!!(this.props.match.params.resetPasswordToken))) {
      this.props.dispatch(commonUpdateResetPasswordToken(this.props.match.params.resetPasswordToken))
      this.props.dispatch(commonTriggerRequest(REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN))
    } else if (this.props.location.pathname.startsWith("/write_testimonial/") && (!!(this.props.match.params.clientTestimonialToken))) {
      // TODO: Implement Actions + Requests
      this.props.dispatch(commonUpdateClientTestimonialToken(this.props.match.params.clientTestimonialToken))
      this.props.dispatch(commonTriggerRequest(REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCommon());
  }

  render() {
    console.log("DASHBOARD APP CONTAINER:")
    console.log(this.props)
    const pathname = this.props.location.pathname
    const appLoadingResults = this.props[REQ_DASHBOARD_FETCH_APP_CONFIG]
    const isSignupPage = (pathname === "/signup")
    const isLoginPage = (pathname === "/login")
    const isLoggedInPage = pathname.startsWith("/app")
    const isLandingPage = (pathname === "/" || pathname === "")
    const isResetPasswordPage = (pathname === "/reset_password")
    const isResetPasswordFlow = (pathname.startsWith("/reset_password"))
    const isConfirmResetPasswordPage = (isResetPasswordFlow && (!!(this.props.match.params.resetPasswordToken)))

    const isWriteClientTestimonialPage = (this.props.location.pathname.startsWith("/write_testimonial/") && (!!(this.props.match.params.clientTestimonialToken)))

    const validateResetPasswordResult = this.props[REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN]
    const passwordResetTokenValidationComplete = (validateResetPasswordResult.state === REQUEST_SUCCESS)

    const validateClientTestimonialTokenResult = this.props[REQ_DASHBOARD_VALIDATE_CLIENT_TESTIMONIAL_TOKEN]
    const clientTestimonialTokenValidationComplete = (validateClientTestimonialTokenResult.state === REQUEST_SUCCESS)
    
    const isAppLoaded = (appLoadingResults.state === REQUEST_SUCCESS) || passwordResetTokenValidationComplete || clientTestimonialTokenValidationComplete || isWriteClientTestimonialPage || isResetPasswordFlow || isLandingPage
    const isLoggedIn = (appLoadingResults.state === REQUEST_SUCCESS) && (!!(appLoadingResults.result.has_valid_session))

    return (
      <div>
        <Helmet
          title={isWriteClientTestimonialPage ? "Write Your Testimonial | NEUROFIT" : "NEUROFIT Dashboard"}
          description={"Dashboard for NEUROFIT Affiliate payouts, and for Certified Trainers to monitor and optimize client progress."}
        />
        {(isLandingPage) ? (
          (
            <LandingPageContainer
              props={this.props}
            />
          )
        ) : (
          (!(isAppLoaded)) ? (
            <AppLoadingPageContainer
              appLoadResultState={appLoadingResults.state}
              onReloadApp={() => this.props.dispatch(commonTriggerRequest(REQ_DASHBOARD_FETCH_APP_CONFIG))}
              props={this.props}
            />
          ) : (
            isSignupPage ? (
              (isLoggedIn) ? (
                <Redirect to="/app/affiliate" />
              ) : (
                <SignupPageContainer
                  props={this.props}
                />
              )
            ) : (
              isResetPasswordFlow ? (
                isConfirmResetPasswordPage ? (
                  passwordResetTokenValidationComplete ? (
                    <ConfirmResetPasswordPageContainer
                      props={this.props}
                    />
                  ) : (
                    <AppLoadingPageContainer
                      props={this.props}
                      appLoadResultState={validateResetPasswordResult.state}
                      appLoadErrorMessage={"Error: This password reset link has expired or is invalid."}
                      onReloadApp={() => window.location = "/reset_password"}
                      appReloadButtonText={"Get another link"}
                    />
                  )
                ) : (
                  <SendResetPasswordLinkPageContainer
                    props={this.props}
                  />
                )
              ) : (
                isLoginPage ? (
                  (isLoggedIn) ? (
                    <Redirect to="/app/affiliate" />
                  ) : (
                    <LoginPageContainer
                      props={this.props}
                    />
                  )
                ) : (
                  (isLoggedIn) ? (
                    <LoggedInContainer
                      {...this.props}
                    />
                  ) : (
                    isWriteClientTestimonialPage ? (
                      clientTestimonialTokenValidationComplete ? (
                        <WriteClientTestimonialPageContainer
                          props={this.props}
                        />
                      ) : (
                        <AppLoadingPageContainer
                          props={this.props}
                          appLoadResultState={validateClientTestimonialTokenResult.state}
                          appLoadErrorMessage={"Error: This testimonial link has expired or is invalid. Please contact your coach to request another link."}
                          onReloadApp={() => window.location = "/"}
                          appReloadButtonText={"Go to Homepage"}
                        />
                      )
                    ) : (
                      <Redirect to="/signup" />
                    )
                  )
                )
              )
            )
          )
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({...state.common});

export default connect(mapStateToProps)(DashboardAppContainer);
