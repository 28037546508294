import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  getAbbreviatedDurationSinceTimestamp,
  getReadableDurationSinceTimestamp,
  formatReadableUnixTimestampMins,
  formatReadableUnixTimestamp,
  getDaysSinceTimestamp,
  getTimeOfDay,
  readableDate,
  MinLargeDesktopWidthPixels,
  ProductionCoachOSUrl,
} from '../../../utils'

import {
  getInsightForCheckInInsight,
  getInsightForSelfCare,
  getInsightForEmbodiment,
  getInsightForAvoidance,
  getInsightForOverallAverageStatePercentage,
  getInsightForLifeAspectAverageStatePercentage,
  getMetaForRecoveryScoreHomePage,
  getMetaForHeartRateVariability,

  getMetaForDysregulationQuizScore,
  getMetaForQuizSymptomResultsData,
  getMetaForQuizHabitResultsData,
  getMetaForQuizSelfCheckInResultsData,
} from '../../../dashboardUtils'

import PendingResultsPanel from '../PendingResultsPanel'
import HabitBarChartInsightSection from '../HabitBarChartInsightSection'
import PieChartInsightSection from '../PieChartInsightSection'
import HrvResultsPanel from '../HrvResultsPanel'
import LineGraph from '../LineGraph'
import InlineClientTestimonial from '../InlineClientTestimonial'
import NumberStepper from '../NumberStepper'
import ConfirmSection from '../ConfirmSection'
import TextFeedbackPrompt from '../TextFeedbackPrompt'
import SendRequestButton from '../SendRequestButton'

import {flag} from 'country-emoji'


import {
  ActivityArrowSvg,
  BackArrowSvg,
  SendArrowSvg,
  CopySvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
  TimerSvg,
} from '../../../constants/dashboardSvgs'

import CompletionCheckIcon from '../CompletionCheckIcon'

import { toPng, toBlob } from 'html-to-image';

import { saveAs } from 'file-saver';

const MaxSessionTrainerNotesLength = 10000

const USDollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const ClientTestimonialDomId = "client-testimonial"

const defaultState = {
  codeIsCopied: false,
  selectedSessionListView: "current",
}
const SessionViews = [
  {
    key: "current",
    value: "Current"
  },
  {
    key: "Archived",
    value: "Archived"
  }
]

const SessionSubViews = [
  {
    key: "details",
    value: "Details"
  },
  {
    key: "settings",
    value: "Settings"
  }
]
class SessionsTab extends React.Component {
  constructor(props){
     super(props)
     this.state = defaultState
  }

  componentWillMount() {
    const {
      onLoadSessionList,
      onLoadSessionDetails,
      isBookingFeatureSetEnabled,
      selectedSessionId,
    } = this.props

    if (isBookingFeatureSetEnabled) {
      if (selectedSessionId !== undefined) {
        onLoadSessionDetails()
      } else {
        onLoadSessionList()
      }
    }
  }

  componentWillUnmount() {
    const {
      onResetSessionList,
      onResetSessionDetails,
      onResetUpdateSessionRequest,
      onSelectSession,
    } = this.props
    onResetSessionList()
    onResetSessionDetails()
    onResetUpdateSessionRequest()
  }

  render() {

    const {
      appInviteCode,
      timeOfDay,
      firstName,
      isDesktop,
      isBookingFeatureSetEnabled,
      sessionListRequestResult,
      onLoadSessionList,
      onSelectSession,
      selectedSessionId,
      selectedSessionSubview,
      onLoadSessionDetails,
      onNavigateToSessionSubview,
      onNavigateToProfileLinkTab,
      selectedSessionDetailsRequestResult,
      onUpdateSession,
      onResetUpdateSessionRequest,
      updateSessionRequestResult,
      refundClientSessionPlanRequestResult,
      onRefundClientSessionPlan,
      onNavigateToSessionsView,
      innerWidth
    } = this.props

    const {
      codeIsCopied,
      selectedSessionListView,
    } = this.state

    const disableCopyButton = codeIsCopied

    const isDesktopWidth = innerWidth > MinLargeDesktopWidthPixels

    return (
      <div
        style={{marginTop: isDesktop ? 100 : 20, display: "flex", width: "100%", justifyContent: "center"}}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={500}
            classNames={"hide-fade-in"}
            unmountOnExit
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            key={`${(sessionListRequestResult.state !== REQUEST_SUCCESS && sessionListRequestResult !== REQUEST_ERROR)}-${selectedSessionId}-${(selectedSessionDetailsRequestResult.state !== REQUEST_SUCCESS && selectedSessionDetailsRequestResult.state !== REQUEST_ERROR)}`}
          >
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              {!(selectedSessionId) ? (
                <div style={{width: "min(85%, 1000px)"}}>
                  {(sessionListRequestResult.state === REQUEST_SUCCESS) && (
                    <div>
                      <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh"}}>
                        <div style={{fontSize: "min(7vw, 30px)", textTransform: "uppercase", fontFamily: "Futura"}}>
                          {`Client Sessions`}
                        </div>
                        <div
                          style={{display: "flex", cursor: "pointer", alignItems: "center"}}
                          onClick={() => onLoadSessionList()}
                        >
                          <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{isDesktop ? `UPDATED ${formatReadableUnixTimestampMins(sessionListRequestResult.result.created_at)}` : "REFRESH"}</span>
                          <RefreshSvg
                            size={"min(4vw, 18px)"}
                            color={"#777777"}
                            onClick={() => {}}
                          />
                        </div>                   
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "start", marginBottom: "2vh"}}>
                        <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", overflowY: "visible", whiteSpace: "nowrap"}}>
                          {SessionViews.map(view => {
                            const isSelected = (view.key === selectedSessionListView)
                            return (
                              <div
                                key={view.key}
                                style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                                onClick={() => this.setState({selectedSessionListView: view.key})}
                              >
                                {view.value}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div>
                        <SessionList  
                          isDesktop={isDesktopWidth}
                          sessionPreviews={(selectedSessionListView === "current") ? sessionListRequestResult.result.current_session_previews : sessionListRequestResult.result.archived_session_previews}
                          onSelectSession={sessionId => {
                            onSelectSession(sessionId)
                            onLoadSessionDetails()
                          }}
                          isCurrentTab={(selectedSessionListView === "current")}
                          onNavigateToProfileLinkTab={() => onNavigateToProfileLinkTab()}
                        />
                      </div>
                    </div>
                  )}
                  {(sessionListRequestResult.state === REQUEST_FETCHING) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`LOADING BOOKED SESSIONS...`}
                      </div>
                    </div>
                  )}
                  {(sessionListRequestResult.state === REQUEST_ERROR) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`ERROR LOADING SESSIONS`}
                      </div>
                      <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                        {`There was an error loading your booked session list - please retry.`}
                      </div>
                      <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                          onClick={() => {
                            onLoadSessionList()
                          }}
                        >
                          <span>
                            {"RETRY"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  {(sessionListRequestResult.state === REQUEST_UNSTARTED) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`CLIENT SESSIONS DASHBOARD`}
                      </div>
                      <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
                        <span>{`Coming Soon: Access built-in client session booking + management.`}</span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{width: "min(100%, 1500px)", display: "flex", justifyContent: "center"}}>
                  {(selectedSessionDetailsRequestResult.state === REQUEST_SUCCESS) && (
                    <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{minWidth: "85%"}}>
                          <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh", marginBottom: "2vh"}}>
                            <div style={{fontSize: "min(5vw, 30px)", fontFamily: "Futura", textTransform: "uppercase", display: "flex", alignItems: "center"}}>
                              <div
                                onClick={() => {
                                  onLoadSessionList()
                                  onSelectSession(undefined)
                                  onResetUpdateSessionRequest()
                                }}
                                style={{cursor: "pointer", display: "inline-block", color: "#777777"}}
                              >
                                {`Sessions`}
                              </div>
                              <BackArrowSvg size={"min(9vw, 40px)"} color={"#777777"} rotation={180} />
                              <div style={{display: "inline-block", maxWidth: "50vw", overflow: "hidden", textTransform: "uppercase", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                                {"Plan Details"}
                              </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                              {isDesktopWidth && (
                                <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{`UPDATED ${formatReadableUnixTimestampMins(selectedSessionDetailsRequestResult.result.created_at)}`}</span>
                              )}
                              <RefreshSvg
                                size={"min(4vw, 18px)"}
                                color={"#777777"}
                                onClick={() => onLoadSessionDetails()}
                              />
                            </div>                   
                          </div>
                        </div>
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "start"}}>
                        <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", paddingInlineEnd: "7.5%", paddingInlineStart: "7.5%", overflowY: "visible", whiteSpace: "nowrap"}}>
                          {SessionSubViews.map(view => {
                            const isSelected = (view.key === selectedSessionSubview)
                            return (
                              <div
                                key={view.key}
                                style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                                onClick={() => onNavigateToSessionSubview(view.key)}
                              >
                                {view.value}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <SwitchTransition mode="out-in">
                          <CSSTransition
                            timeout={500}
                            classNames={"hide-fade-in"}
                            unmountOnExit
                            addEndListener={(node, done) => {
                              node.addEventListener("transitionend", done, false);
                            }}
                            key={`${selectedSessionSubview}`}
                          >
                            <div style={{width: "85%", maxWidth: 500, fontFamily: "Futura"}}>
                              {(selectedSessionSubview === "details") && (
                                <ClientSessionPlanDetails
                                  updateSessionRequestResult={updateSessionRequestResult}
                                  sessionPlanDetailsObject={selectedSessionDetailsRequestResult.result && selectedSessionDetailsRequestResult.result.client_session_plan_details}
                                  onUpdateSession={requestObject => onUpdateSession(requestObject)}
                                />
                              )}
                              {(selectedSessionSubview === "settings") && (
                                <RefundClientSessionPlanPanel
                                  sessionPlanDetailsObject={selectedSessionDetailsRequestResult.result && selectedSessionDetailsRequestResult.result.client_session_plan_details}
                                  refundClientSessionPlanRequestResult={refundClientSessionPlanRequestResult}
                                  onRefundClientSessionPlan={() => onRefundClientSessionPlan()}
                                  onNavigateToSessionsView={() => {
                                    onLoadSessionList()
                                    onSelectSession(undefined)
                                  }}
                                />
                              )}
                            </div>
                          </CSSTransition>
                        </SwitchTransition>
                      </div>
                    </div>
                  )}
                  {(selectedSessionDetailsRequestResult.state === REQUEST_FETCHING) && (
                  <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{maxWidth: "85%"}}>
                          <div>
                            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                              {`LOADING SESSION DETAILS...`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(selectedSessionDetailsRequestResult.state === REQUEST_ERROR) && (
                    <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{maxWidth: "85%"}}>
                          <div>
                            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                              {`ERROR LOADING SESSION DETAILS`}
                            </div>
                            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                              {`There was an error loading this session's details - please retry.`}
                            </div>
                            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                              <button
                                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                                onClick={() => {
                                  onLoadSessionDetails()
                                }}
                              >
                                <span>
                                  {"RETRY"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    )
  }
}

const SessionList = ({sessionPreviews, isDesktop, onSelectSession, onNavigateToProfileLinkTab, isCurrentTab}) => (
  <div>
    <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
      <div style={{backgroundColor: "#fbfbfc", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(3vw, 14px)", padding: "min(3.5vw, 16px)"}}>
        <div style={{width: "40%", display: "inline-block"}}>
          {"PLAN DETAILS"}
        </div>
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"PAYMENT (USD)"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"# SESSIONS"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"# COMPLETED"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"COACH NOTES"}
          </div>
        )}
      </div>
      {sessionPreviews.map(preview => (
        <div key={preview.id} style={{width: "100%", borderTop: "1px #dddddd solid", fontSize: "min(4vw, 18px)", fontFamily: "Futura"}}>
          <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
            <div style={{width: isDesktop ? "40%" : "100%", display: "inline-block"}}>
              <span onClick={() => onSelectSession(preview.id)} style={{textDecoration: "underline", fontSize: "min(4.5vw, 20px)", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {preview.client_full_name}
              </span>
              <div style={{color: "#777777", fontSize:" min(3vw, 15px)", overflow: "hidden", margin: "min(1.75vw, 8px) 0px", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {preview.client_email_address}
              </div>
              <div style={{color: "#777777", fontSize:" min(3vw, 15px)", overflow: "hidden", marginBottom: "min(1.75vw, 8px)", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {`${preview.client_location_city_name}${!!flag(preview.client_location_country_code) ? ` ${flag(preview.client_location_country_code)}` : ""}`}
              </div>
              <div style={{color: "#000000", fontSize:" min(2.5vw, 12px)", fontFamily: "Futura Medium", textTransform: "uppercase", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", alignItems: "center"}}>
                <span>{`Booked ${getAbbreviatedDurationSinceTimestamp(preview.created_at_unix)} Ago`}</span>
              </div>
            </div>
            {isDesktop && (
              <div style={{width: "15%", display: "inline-block", height: 22, textAlign: "end"}}>
                {USDollarFormatter.format(preview.local_gross_revenue_amount_cents / 100)}
              </div>
            )}
            {isDesktop && (
              <div style={{width: "15%", display: "inline-block", height: 22, textAlign: "end"}}>
                {preview.session_purchase_quantity}
              </div>
            )}
            {isDesktop && (
              <div style={{width: "15%", display: "inline-block", height: 22, textAlign: "end"}}>
                {preview.completed_session_count}
              </div>
            )}
            {isDesktop && (
              <div style={{width: "15%", display: "inline-block", height: 22, textAlign: "end"}}>
                <CompletionCheckIcon
                  isComplete={!!(preview.trainer_notes)}
                  marginTop={"0px"}
                  checkboxBaseClassName={"checkbox-plan-button"}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      <div style={{width: "100%", backgroundColor: "#fbfbfc", color: (sessionPreviews.length > 0) ? "#999999" : "#777777", borderTop: "1px #dddddd solid", fontSize: "min(3.75vw, 16px)", fontFamily: "Futura Book"}}>
        <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
          {(sessionPreviews.length > 0) ?
            "Click on a client's name to view session details." : (
              (isCurrentTab ? (
                <span>
                  {"Book your first session by sharing your "}
                  <span style={{fontFamily: "Futura", textDecoration: "underline", cursor: "pointer"}} onClick={() => onNavigateToProfileLinkTab()}>
                    {"coach profile link"}
                  </span>
                  {"."}
                </span>
              ) : (
                <span>
                  {"Archive session plans via the session details view."}
                </span>
              ))
          )}
        </div>
      </div>
    </div>
  </div>
)


const defaultSessionPlanDetailsState = {
  is_archived: false,
  trainer_notes: "",
  completed_session_count: 0,
  client_session_plan_id: null
}

class ClientSessionPlanDetails extends React.Component {
  constructor(props){
     super(props)
     this.state = defaultSessionPlanDetailsState
  }

  componentWillMount() {
    const {
      sessionPlanDetailsObject,
    } = this.props

    this.setState({
      is_archived: sessionPlanDetailsObject.is_archived,
      trainer_notes: sessionPlanDetailsObject.trainer_notes,
      completed_session_count: sessionPlanDetailsObject.completed_session_count,
      client_session_plan_id: sessionPlanDetailsObject.id
    })
  }

  render() {
    const {
      sessionPlanDetailsObject,
      updateSessionRequestResult,
      onUpdateSession
    } = this.props
    const {
      is_archived,
      trainer_notes,
      completed_session_count
    } = this.state

    const isSessionPlanRefunded = sessionPlanDetailsObject.is_refunded

    const updateFormDisabled = (updateSessionRequestResult.state === REQUEST_FETCHING) || isSessionPlanRefunded

    return (
      <div>
        <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
          {`I. SESSION PLAN DETAILS`}
        </div>
        <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", marginTop: "2vh"}}>
          <div style={{backgroundColor: "#fbfbfc", borderTop: "1px #dddddd solid", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
            <div>
              {"CLIENT NAME"}
            </div>
          </div>
          <div style={{fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium"}}>
            {`${sessionPlanDetailsObject.client_full_name}`}
          </div>
          <div style={{backgroundColor: "#fbfbfc", borderTop: "1px #dddddd solid", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
            <div>
              {"CLIENT EMAIL ADDRESS"}
            </div>
          </div>
          <div style={{fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium"}}>
            {`${sessionPlanDetailsObject.client_email_address}`}
          </div>
          <div style={{backgroundColor: "#fbfbfc", color: "#999999", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
            <div>
              {"# SESSIONS"}
            </div>
          </div>
          <div style={{fontSize: "min(4vw, 18px)", color: "#000000", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", padding: "min(3.5vw, 16px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
            <span>{`${sessionPlanDetailsObject.session_purchase_quantity}`}</span>
          </div>
          <div style={{backgroundColor: "#fbfbfc", color: "#999999", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
            <div>
              {"PAYMENT (USD)"}
            </div>
          </div>
          <div style={{fontSize: "min(4vw, 18px)", color: "#000000", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", padding: "min(3.5vw, 16px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
            {`💸 ${USDollarFormatter.format(sessionPlanDetailsObject.local_gross_revenue_amount_cents / 100)}`}
          </div>
          <div style={{backgroundColor: "#fbfbfc", color: "#999999", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
            <div>
              {"PURCHASE LOCATION"}
            </div>
          </div>
          <div style={{fontSize: "min(4vw, 18px)", color: "#000000", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", padding: "min(3.5vw, 16px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
            {`📍 ${sessionPlanDetailsObject.client_location_city_name}${!!flag(sessionPlanDetailsObject.client_location_country_code) ? ` ${flag(sessionPlanDetailsObject.client_location_country_code)}` : ""}`}
          </div>
          <div style={{backgroundColor: "#fbfbfc", color: "#999999", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
            <div>
              {"PURCHASE TIME"}
            </div>
          </div>
          <div style={{fontSize: "min(4vw, 18px)", color: "#000000", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", padding: "min(3.5vw, 16px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
            <span>{`🕒 ${getReadableDurationSinceTimestamp(sessionPlanDetailsObject.created_at_unix)}`}</span>
          </div>
        </div>
        <div style={{marginTop: "8vh"}}>
          <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
            {`II. COACHING NOTES`}
          </div>
          {isSessionPlanRefunded && (
            <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", fontStyle: "italic", marginBottom: "2vh"}}>
              {`NOTE: You've issued a refund for this session plan - it cannot be edited any further as a result.`}
            </div>
          )}
          <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
            {`Client Session Notes`}
          </div>
          <TextFeedbackPrompt
            disabled={updateFormDisabled}
            color="#000000"
            inputBackgroundColor="#ffffff"
            marginTop={"0px"}
            marginBottom={"2vh"}
            placeholderText={`Add any notes about scheduling or completed sessions...`}
            value={trainer_notes}
            onChange={val => {
              this.setState({...this.state, trainer_notes: val})
            }}
          />
          <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingInlineEnd: 10, fontFamily: "Futura", marginBottom: "2vh", fontSize: "min(4vw, 18px)", color: (trainer_notes.length > MaxSessionTrainerNotesLength) ? "#ff9999" : ((trainer_notes.length > MaxSessionTrainerNotesLength * 0.9) ? "#c1c4d2" : "transparent"), transition: "color 250ms linear"}}>
            {`${MaxSessionTrainerNotesLength - trainer_notes.length} Characters Remaining`}
          </div>
        </div>
        <NumberStepper
          title={`Sessions Completed (Out of ${sessionPlanDetailsObject.session_purchase_quantity})`}
          minValue={0}
          isDisabled={updateFormDisabled}
          maxValue={sessionPlanDetailsObject.session_purchase_quantity}
          value={completed_session_count}
          onUpdateValue={(val) => {
            this.setState({...this.state, completed_session_count: val, is_archived: (val === sessionPlanDetailsObject.session_purchase_quantity) ? true : is_archived})
          }}
        />
        <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "4vh"}}>
          <ConfirmSection
            label={"Archive this session plan"}
            isConfirmed={is_archived}
            onToggleConfirm={(val) => {
              this.setState({...this.state, is_archived: val})
            }}
            isDisabled={updateFormDisabled}
            fontFamily={"Futura Medium"}
            marginTop={"0px"}
            color={"#000000"}
          />
        </div>
        <div style={{display: "flex", justifyContent: "start", alignItems: "center", margin: "4vh 0vh 6vh 0vh"}}>
          <SendRequestButton
            defaultContent={"Save Updates"}
            loadingText={"Saving Updates..."}
            successText={"Updates Saved"}
            requestState={updateSessionRequestResult.state}
            isDisabled={updateFormDisabled}
            onClick={() => {
              const requestBody = {...this.state}
              console.log(requestBody)
              onUpdateSession(requestBody)
            }}
          />
        </div>
      </div>
    )
  }
}


class RefundClientSessionPlanPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      refundConfirmationValue: ""
    }
  }

  render() {

    const {
      sessionPlanDetailsObject,
      refundClientSessionPlanRequestResult,
      onRefundClientSessionPlan,
      onNavigateToSessionsView,
    } = this.props

    const {refundConfirmationValue} = this.state

    const refundClientSessionPlanButtonDisabled = (refundConfirmationValue !== "refund") || (refundClientSessionPlanRequestResult.state === REQUEST_FETCHING) || (refundClientSessionPlanRequestResult.state === REQUEST_SUCCESS)

    return (
      (!sessionPlanDetailsObject.is_refunded) ? (
        <div style={{backgroundColor: "#FFFFFF", margin: "6vh 0px", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
          <div style={{padding: "min(5vw, 22px)"}}>
            <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", marginBottom: "2vh"}}>
              {"REFUND SESSION PLAN"}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura",  marginTop: "2vh"}}>
              {`Not the right client/coach fit? Issue a full refund for ${sessionPlanDetailsObject.client_full_name}’s session plan here.`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura", fontStyle: "italic", marginTop: "2vh"}}>
              {`Please Note:`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh", paddingInlineStart: "0.5em", textIndent: "-0.5em"}}>
              {`- Refunds cannot be undone once issued`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh", paddingInlineStart: "0.5em", textIndent: "-0.5em"}}>
              {`- The full booking amount ($${(sessionPlanDetailsObject.local_gross_revenue_amount_cents / 100).toFixed(2)} USD) will be covered by your Payouts bank account`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh", paddingInlineStart: "0.5em", textIndent: "-0.5em"}}>
              {`- Platform transaction fees for this booking ($${(sessionPlanDetailsObject.local_application_fee_amount_cents / 100).toFixed(2)} USD) are non-refundable`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh", paddingInlineStart: "0.5em", textIndent: "-0.5em"}}>
              {`- This session plan will automatically be archived, and cannot be edited further.`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura",  marginTop: "2vh"}}>
              {`To confirm, please enter the word "refund" in the input below:`}
            </div>
            <div style={{marginTop: "2vh"}}>
              <input
                type="text"
                value={refundConfirmationValue}
                autoCapitalize="none"
                autoCorrect="false"
                spellCheck={false}
                onChange={(e) => {
                  this.setState({...this.state, refundConfirmationValue: e.target.value.toLowerCase()})
                }}
                onPaste={(e) => {
                  this.setState({...this.state, refundConfirmationValue: e.target.value.toLowerCase()})
                }}
                style={{backgroundColor: "#fbfbfc"}}
                placeholder={`Type "refund" to confirm...`}
                className={"application-input"}
              />
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: refundClientSessionPlanButtonDisabled ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  onRefundClientSessionPlan()
                }}
                disabled={refundClientSessionPlanButtonDisabled}
              >
                <span>
                  {(refundClientSessionPlanRequestResult.state === REQUEST_SUCCESS) ? "REFUND ISSUED." : ((refundClientSessionPlanRequestResult.state === REQUEST_FETCHING) ? "ISSUING REFUND..." : "ISSUE FULL REFUND")}
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div style={{backgroundColor: "#FFFFFF", margin: "6vh 0px", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
          <div style={{padding: "min(5vw, 22px)"}}>
            <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", marginBottom: "2vh"}}>
              {"SESSION PLAN REFUNDED"}
            </div>
            <div style={{fontSize: "min(4vw, 18px)", textTransform: "uppercase", fontFamily: "Futura Medium"}}>
              {`Refunded $${(sessionPlanDetailsObject.local_gross_revenue_amount_cents / 100).toFixed(2)} USD`}
            </div>
            <div style={{fontSize: "min(4vw, 18px)", textTransform: "uppercase", fontFamily: "Futura Medium", marginBottom: "2vh"}}>
              {`🕒 ${getReadableDurationSinceTimestamp(sessionPlanDetailsObject.refund_issued_at_unix)}`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book",  marginTop: "2vh"}}>
              {`You've issued a full refund for ${sessionPlanDetailsObject.client_full_name}’s session plan - it will land within 5-10 business days.`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book",  marginTop: "2vh"}}>
              {`This session plan has been archived as a result, and cannot be edited any further.`}
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  onNavigateToSessionsView()
                }}
              >
                <span>
                  {"BACK TO SESSIONS"}
                </span>
              </button>
            </div>
          </div>
        </div>
      )
    )
  }
}


export default SessionsTab