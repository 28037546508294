import React from 'react'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import {
  sanitizeEmailAddress,
} from '../../utils'

import {
  OvalSpinnerSvg,
} from '../../constants/svgs'

import CompletionCheckIcon from './CompletionCheckIcon'

import {
  ErrorSvg,
} from '../../constants/svgs'

class EditableEmailTextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      emailAddress: props.existingEmailAddress || "",
      isInputFocused: false,
      hasInputFocused: false
    };
  }

  toggleInputFocus(isInputFocused) {
    this.setState({isInputFocused, hasInputFocused: this.state.hasInputFocused || isInputFocused})
    if (isInputFocused) {
      this.props.onFocus()
    }
  }

  handleUpdateInputText(updatedInputTextValue) {
    const {
      onUpdateEmailAddress,
    } = this.props
    const sanitizedEmailAddress = sanitizeEmailAddress(updatedInputTextValue)
    this.setState({emailAddress: sanitizedEmailAddress})
    onUpdateEmailAddress(sanitizedEmailAddress)
  }

  render() {
    const {
      disableInput,
      existingEmailAddress,
      hideSubmitButton,
      emailAddressRequestState,
      onFocus,
      onSubmitEmailAddress,
      submitButtonText,
      successMessage,
      inputFontFamily,
      inputPlaceholderText,
      marginBottom,
    } = this.props

    const {
      emailAddress,
      isInputFocused,
      hasInputFocused
    } = this.state

    const inputIsDisabled = (emailAddressRequestState === REQUEST_FETCHING) || disableInput

    const showSubmitButton = !hideSubmitButton && !!(emailAddress) && ((emailAddress !== existingEmailAddress) || (emailAddressRequestState === REQUEST_SUCCESS))

    return (
      <div style={{marginBottom: marginBottom || "10px", border: (emailAddressRequestState === REQUEST_ERROR) ? "2px red solid" : "none", transition: "border 200ms linear"}} className={(isInputFocused || !!emailAddress) ? "promotion-code-checkout-container-filled" : "promotion-code-checkout-container"}>
        <input
          type="text"
          ref={elem => this.promoCodeInputRef = elem}
          disabled={inputIsDisabled}
          style={{display: "inline-block !important", cursor: inputIsDisabled ? "not-allowed" : "auto", fontFamily: (inputFontFamily || "Futura")}}
          className={`promotion-code-checkout${(isInputFocused || !!emailAddress) ? " promotion-code-checkout-filled" : ""} email-address-checkout`}
          onFocus={() => this.toggleInputFocus(true)}
          onBlur={() => this.toggleInputFocus(false)}
          placeholder={inputPlaceholderText || (isInputFocused ? "Enter Referral Code..." : "Have a Referral Code?")}
          autoCapitalize="words"
          autoCorrect="false"
          autoComplete="none"
          spellCheck={false}
          value={(emailAddressRequestState === REQUEST_SUCCESS) ? (successMessage || emailAddress) : emailAddress}
          onChange={(e) => this.handleUpdateInputText(e.target.value)}
          onPaste={(e) => this.handleUpdateInputText(e.target.value)}
        />
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={200}
            classNames={"hide-fade-in-100"}
            unmountOnExit
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            key={`${showSubmitButton}-${emailAddressRequestState}`}
          >
            <span style={{display: "flex", alignItems: "center", paddingInlineEnd: 12}}>
              {showSubmitButton ? (
                <span style={{display: "flex", alignItems: "center"}}>
                  {(emailAddressRequestState === REQUEST_UNSTARTED) && (
                    <button
                      style={{fontFamily: "Futura Medium", cursor: "pointer", fontSize: 15, color: "#000000", backgroundColor: "transparent", padding: "0px", border: "none"}}
                      disabled={(emailAddressRequestState !== REQUEST_UNSTARTED) || disableInput}
                      onClick={() => {
                        onSubmitEmailAddress(emailAddress)
                      }}
                    > 
                      {submitButtonText}
                    </button>
                  )}
                  {(emailAddressRequestState === REQUEST_FETCHING) && (
                    <OvalSpinnerSvg
                      size={20}
                      color={"#777777"}
                    />
                  )}
                  {(emailAddressRequestState === REQUEST_SUCCESS) && (
                    <CompletionCheckIcon
                      isComplete
                      animateCheck
                      checkboxBaseClassName={"checkbox-request-button-black"}
                      checkColorComplete={"#FFFFFF"}
                    />
                  )}
                  {(emailAddressRequestState === REQUEST_ERROR) && (
                    <ErrorSvg
                      size={25}
                      color={"red"}
                      onClick={() => {}}
                    />
                  )}
                </span>
              ) : undefined}
            </span>
          </CSSTransition>
        </SwitchTransition>
      </div>
    )
  }
}

export default EditableEmailTextInput