export const FORM_FIELD_FIRST_NAME = 'firstName'
export const FORM_FIELD_LAST_NAME = 'lastName'
export const FORM_FIELD_OCCUPATION = 'occupation'
export const FORM_FIELD_AFFILIATE_CODE = 'affiliateCode'
export const FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY = 'payoutsDestinationCountry'
export const FORM_FIELD_EMAIL_ADDRESS = 'emailAddress'
export const FORM_FIELD_PASSWORD = 'password'
export const FORM_FIELD_PROFESSIONAL_URL = 'professionalUrl'
export const FORM_FIELD_AUTH_REDIRECT_PATH = 'authRedirectPath'

export const FORM_FIELD_SELECTED_PRODUCT_ID = 'selectedIapProductId'
export const FORM_FIELD_IS_ORDERING_PRODUCT = 'isOrderingProduct'
export const FORM_FIELD_ACCESS_CODE = 'subscriptionAccessCode'

export const FORM_FIELD_SHOW_TOP_ERROR_MESSAGE = 'showTopErrorMessage'
export const FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE = 'showBottomSuccessMessage'
export const FORM_FIELD_TOP_ERROR_MESSAGE_VALUE = 'topErrorMessageValue'
export const FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE = 'bottomSuccessMessageValue'

export const FORM_FIELD_CLIENT_TESTIMONIAL_TEXT = 'clientTestimonialText'
export const FORM_FIELD_CLIENT_TESTIMONIAL_INCLUDE_CLIENT_NAME = 'clientTestimonialIncludeClientName'

export const FORM_FIELD_USER_PROFILE_IMAGE_FILE = 'userProfileImageFile'