import React from 'react';

import {
  loadingScreenIsVisible,
} from '../../utils'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_ERROR
} from '../../constants/requestStates';

import {
  NeuroFitNamedLogoSvg,
} from '../../constants/svgs'

const AppLoadingPage = ({
  height,
  safeAreaInsets,
  appLoadResultState,
  appLoadErrorMessage,
  appReloadButtonText,
  // Actions
  onReloadApp,
}) => {
  const loadingScreenVisible = loadingScreenIsVisible()
  return (
    <div style={{position: "absolute", top: 0, left: 0, bottom: 0, width: "100vw", zIndex: 100, backgroundColor: "#FFFFFF"}}>
      <div style={{position: "relative", top: 0, left: 0, height: loadingScreenVisible ? 0 : safeAreaInsets.top, transition: "height 100ms linear 200ms", width: "100vw", backgroundColor: "#000000"}}>
      </div>
      <div style={{position: "absolute", top: 0, left: 0, width: "100vw", bottom: 0, justifyContent: "center", display: "flex", alignItems: "center"}}>
        {((appLoadResultState === REQUEST_FETCHING) || (appLoadResultState === REQUEST_UNSTARTED)) && (
          <NeuroFitNamedLogoSvg
            logoSize={"29.6px"}
            color={"#000000"}
          />
        )}
        {(appLoadResultState === REQUEST_ERROR) && (
          <div style={{display: "flex", margin: "0% 5%"}}>
            <div className="lp-subheader">
              <div style={{marginBottom: "3vh"}}>{appLoadErrorMessage || "Error: Loading Dashboard Failed."}</div>
              <button
                style={{backgroundColor: "black", color: "white", padding: "12px 15px", border: "none", fontFamily: "Futura", fontSize: "min(6vw, 24px)"}}
                onClick={onReloadApp}
              >
                {(appReloadButtonText || "Retry").toUpperCase()}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AppLoadingPage;