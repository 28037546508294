import {
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD,
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE,
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE,
  DASHBOARD_LOGGED_IN_PAGE_RESET_STATE,
} from '../constants/actionTypes'

import {
  REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE,
  REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL,
  REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL,
  REQ_DASHBOARD_UPDATE_AFFILIATE_CODE,
  REQ_DASHBOARD_FETCH_CLIENT_LIST,
  REQ_DASHBOARD_FETCH_CLIENT_DETAILS,
  REQ_DASHBOARD_REMOVE_CLIENT,
  REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL,
  REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES,
  REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE,
  REQ_DASHBOARD_GET_SIGNED_UPLOAD_PROFILE_IMAGE_URL,
  REQ_DASHBOARD_UPLOAD_USER_PROFILE_IMAGE,
  REQ_DASHBOARD_SEND_CLIENT_PUSH_NOTIFICATION,
  REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT,
  REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY,
  REQ_DASHBOARD_FETCH_GIFT_APP_DETAILS,
  REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS,
  REQ_DASHBOARD_FETCH_SESSION_LIST,
  REQ_DASHBOARD_FETCH_SESSION_DETAILS,
  REQ_DASHBOARD_UPDATE_SESSION_DETAILS,
  REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN,
  REQ_DASHBOARD_FETCH_LEAD_LIST,
  REQ_DASHBOARD_FETCH_LEAD_DETAILS,
  REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY,
  REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT,
  REQ_DASHBOARD_REMOVE_CLIENT_LEAD,
  REQ_DASHBOARD_LOGOUT_USER,
} from '../constants/requestTypes'

import {
  FORM_FIELD_AFFILIATE_CODE,
} from '../constants/formFields'

import {
  DASHBOARD_PAGE_STATE_SELECTED_MENU_SECTION,
  DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID,
  DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID,
  DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID,
  DASHBOARD_PAGE_STATE_TOP_HABIT,
  DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT,
  DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT,
  DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT,
} from '../constants/pageState'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS
} from '../constants/requestStates'

import {
  DASHBOARD_SECTION_CLIENTS,
} from '../constants/dashboardSections'

import { combineReducers } from 'redux';

const defaultFormFields = {
  [FORM_FIELD_AFFILIATE_CODE]: "",
}
function formFields(
  state = defaultFormFields, action) {
  switch (action.type) {
    case DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD:
      return {...state, ...action.payload};
    case DASHBOARD_LOGGED_IN_PAGE_RESET_STATE:
      return defaultFormFields;
    default:
      return state;
  }
}


const defaultPageState = {
  [DASHBOARD_PAGE_STATE_SELECTED_MENU_SECTION]: DASHBOARD_SECTION_CLIENTS,
  [DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID]: undefined,
  [DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID]: undefined,
  [DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID]: undefined,
  [DASHBOARD_PAGE_STATE_TOP_HABIT]: undefined,
  [DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT]: undefined,
  [DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT]: undefined,
  [DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT]: undefined,
}
function pageState(
  state = defaultPageState, action) {
  switch (action.type) {
    case DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE:
      return {...state, ...action.payload}
    case DASHBOARD_LOGGED_IN_PAGE_RESET_STATE:
      return defaultPageState;
    default:
      return state;
  }
}

const defaultRequestResults = {
  [REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_UPDATE_AFFILIATE_CODE]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_CLIENT_LIST]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_CLIENT_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_REMOVE_CLIENT]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_GET_SIGNED_UPLOAD_PROFILE_IMAGE_URL]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_UPLOAD_USER_PROFILE_IMAGE]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_SEND_CLIENT_PUSH_NOTIFICATION]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_GIFT_APP_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_SESSION_LIST]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_SESSION_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_UPDATE_SESSION_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_LEAD_LIST]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_LEAD_DETAILS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_REMOVE_CLIENT_LEAD]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_LOGOUT_USER]: {state: REQUEST_UNSTARTED},
}
function requestResults(
  state = defaultRequestResults, action) {
  switch (action.type) {
    case DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE: {
      return {...state, [action.key]: {...action.payload}};
    }
    case DASHBOARD_LOGGED_IN_PAGE_RESET_STATE:
      return defaultRequestResults;
    default:
      return state;
  }
}

export default combineReducers({
  formFields,
  pageState,
  requestResults,
});
