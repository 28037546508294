import React, { Suspense } from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  sanitizeEmailAddress,
  KajabiBusinessSuiteCourseUrl,
  KajabiCertificationCourseUrl,
  ProductionCertificationUrl,
  formatReadableUnixTimestampMins,
} from '../../../utils'

import {
  ActivityArrowSvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
} from '../../../constants/dashboardSvgs'


class BusinessSuiteTab extends React.Component {
  constructor(props){
     super(props)
     this.state = {

     }
  }

  componentWillUnmount() {
    
  }

  render() {

    const {
      timeOfDay,
      firstName,
      isTrainerInProgress,
      isCertifiedCoach,
      isDesktop
    } = this.props

    return (
      <div
        style={{marginTop: isDesktop ? 100 : 0, display: "flex", width: "100%", justifyContent: "center"}}
      >
        {(isCertifiedCoach) ? (
          <div style={{width: "min(85%, 1000px)"}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`BUSINESS SUITE`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
              {`Click below to access the Business Suite, Pre-Vetted Offer Templates, Swipe Files and more:`}
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  window.open(KajabiBusinessSuiteCourseUrl, "_blank", "noopener noreferrer")
                }}
              >
                <span>
                  {"GO TO BUSINESS SUITE"}
                </span>
              </button>
            </div>
          </div>
        ) : (isTrainerInProgress ? (
          <div style={{width: "min(85%, 1000px)"}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`UNLOCK BUSINESS SUITE`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
              {`Once you've become a Certified Coach, you'll gain access the Business Suite - including Pre-Vetted Offer Templates, Swipe Files, and more.`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
              {`Click below to access the Certification:`}
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  window.open(KajabiCertificationCourseUrl, "_blank", "noopener noreferrer")
                }}
              >
                <span>
                  {"GO TO CERTIFICATION"}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div style={{width: "85%", maxWidth: 1000}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`UNLOCK BUSINESS SUITE`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
              <span>{`Join the `}</span>
              <span style={{fontFamily: "Futura"}}>{`NEUROFIT Coach Certification`}</span>
              <span>{` to acccess our Business Suite - containing pre-vetted offer templates, swipe files and more:`}</span>
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  window.open(ProductionCertificationUrl, "_blank", "noopener noreferrer")
                }}
              >
                <span>
                  {"VIEW CERTIFICATION"}
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default BusinessSuiteTab