import React from 'react'

import { CSSTransition } from 'react-transition-group';

const errorMessageStyle = {color: "red", textAlign: "center", marginTop: 5}

const TopErrorMessage = ({
  // State
  show,
  message,
  style,
  safeAreaInsets,
}) => {
  style = style || {}
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="slide-from-top"
      unmountOnExit
      appear
    >
      <div style={{left: 0, top: safeAreaInsets.top || 0, position: "fixed", width: "100%", paddingTop: 5, paddingBottom: 5, zIndex: 10000000000, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#777777", ...style}}>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
          <div style={{width: "90%", maxWidth: 600, color: "white", textAlign: "center", fontFamily: "Futura", fontSize: "min(4vw, 18px)", padding: "0px 10px"}}>
            {message}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default TopErrorMessage